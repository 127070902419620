<template>
  <div class="fixed inset-0 bg-[#f1f1f1] z-[99991] text-black">
    <!-- Header -->
    <div class="flex items-center justify-between p-4 bg-[#fc7a78]">
      <div
        class="flex items-center justify-center gap-4 w-full relative text-white"
      >
        <button class="p-2 absolute left-0" @click="$emit('close')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#fff"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <h1 class="text-[20px]">{{ t("notification.title") }}</h1>
      </div>
    </div>

    <!-- Tabs -->
    <div class="border-b border-[#797979]/80">
      <div
        class="flex gap-4 px-4 overflow-x-auto custom-scrollbar-none text-nowrap flex-nowrap text-[14px] bg-white"
      >
        <button
          v-for="tab in tabs"
          :key="tab.id"
          @click="activeTab = tab.id"
          class="py-2 relative"
          :class="{ 'text-[#fc7a78]': activeTab === tab.id }"
        >
          {{ t(`notification.tabs.${tab.id}`) }}
        </button>
      </div>
    </div>

    <!-- Notifications List -->
    <div class="h-[calc(100vh-120px)] overflow-y-auto custom-scrollbar">
      <template v-if="filteredNotificationsByTab.length">
        <NotificationItem
          v-for="notification in filteredNotificationsByTab"
          :key="notification.id"
          :notification="notification"
          :isPopup="false"
          class="border-b border-[#797979]/80"
        />
      </template>
      <div v-else class="p-4 text-center text-gray-500">
        {{ t("notification.noNotifications") }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import NotificationItem from "./NotificationItem.vue";

const { t } = useI18n();

const props = defineProps({
  notifications: {
    type: Array,
    required: true,
    default: () => [],
  },
});

defineEmits(["close"]);

const activeTab = ref("all");
const tabs = [
  { id: "all", name: "all" },
  { id: "system", name: "system" },
  { id: "recent", name: "recent" },
];

const filteredNotificationsByTab = computed(() => {
  if (activeTab.value === "all") {
    return props.notifications;
  }
  return props.notifications.filter((notification) => {
    switch (activeTab.value) {
      case "system":
        return notification.type === "system";
      case "recent":
        return notification.type === "recent";
      default:
        return true;
    }
  });
});
</script>
