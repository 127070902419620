<template>
  <div
    class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[9999] flex justify-center items-center overflow-y-auto"
  >
    <div
      class="flex flex-col bg-white rounded-lg w-4/5 max-w-[414px] mobile:w-[100%] mobile:h-full mobile:max-w-full mobile:rounded-none absolute shadow-md text-[#797979] mobile:items-center mobile:top-1 tablet:top-1"
    >
      <ModalHeader @close="close" class="mobile:rounded-none w-full" />

      <div
        class="p-6 px-[55px] mobile:px-6 flex flex-col items-start mobile:max-w-[360px]"
      >
        <h2
          class="text-[16px] text-[#000] text-center w-full font-[500] mb-[12px] leading-[100%] text-[#797979]"
        >
          {{ $t("authUser.resetPass.reset") }}
        </h2>

        <div v-if="step === 1" class="w-full">
          <p class="text-[12px] font-[500] max-[767px]:text-[9px] mb-1">
            {{ $t("authUser.resetPass.yourEmail") }}
          </p>
          <div class="relative w-full mb-4">
            <input
              v-model="email"
              class="w-full p-3 shadow-in rounded-lg text-sm"
              :placeholder="$t('authUser.resetPass.email')"
            />
          </div>
          <div class="w-full mobile:fixed bottom-0 right-0">
            <button
              @click="requestResetCode"
              class="bg-[#4ADE80] mb-[14px] mobile:mb-0 w-full hover:bg-[#4ADE80]/40 text-white font-[500] text-[16px] h-[40px] px-4 rounded-lg"
            >
              {{ $t("authUser.resetPass.sendCode") }}
            </button>
          </div>
        </div>

        <div v-if="step === 2" class="w-full">
          <p class="text-[10px] font-[500] mb-[3px]">
            {{ $t("authUser.resetPass.codeGo") }}
            <span class="text-[#3C92C4]">{{ maskedEmail }}</span>
          </p>
          <div class="relative w-full mb-4">
            <input
              v-model="resetCode"
              class="w-full p-3 shadow-in rounded-lg text-sm"
              :placeholder="$t('authUser.resetPass.code')"
            />
          </div>
          <div class="relative w-full mb-4">
            <input
              v-model="newPassword"
              type="password"
              class="w-full p-3 shadow-in rounded-lg text-sm"
              :placeholder="$t('authUser.resetPass.newPas')"
            />
          </div>
          <div class="relative w-full mb-4">
            <input
              v-model="confirmPassword"
              type="password"
              class="w-full p-3 shadow-in rounded-lg text-sm"
              :placeholder="$t('authUser.resetPass.repeatPas')"
            />
          </div>
          <p
            v-if="successMessage"
            class="text-[#4ADE80] hidden mobile:block text-[10px] mb-2"
          >
            {{ successMessage }}
          </p>
          <div class="w-full mobile:fixed bottom-0 right-0">
            <button
              @click="resetPassword"
              class="bg-[#4ADE80] mb-[14px] mobile:mb-1 w-full hover:bg-[#4ADE80]/40 text-white font-[500] text-[16px] h-[40px] px-4 rounded-lg"
            >
              {{ $t("authUser.resetPass.resetPas") }}
            </button>

            <p
              v-if="successMessage"
              class="text-[#4ADE80] mobile:hidden text-[10px] mb-2"
            >
              {{ successMessage }}
            </p>

            <button
              @click="goBack"
              class="bg-transparent text-nowrap h-[38px] text-[#ff9387] h-[40px] px-5 rounded border-none cursor-pointer text-base w-full shadow hover: mb-[11px] mobile:mb-0"
            >
              {{ $t("authUser.resetPass.back") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import ModalHeader from "@/components/ModalDeal/ModalBlocks/ModalHeader.vue";
import axios from "axios";

const { t } = useI18n();
const emit = defineEmits(["close", "open-login"]);

const props = defineProps({
  initialEmail: {
    type: String,
    default: "",
  },
  codeAlreadySent: {
    type: Boolean,
    default: false,
  },
  skipFirstStep: {
    type: Boolean,
    default: false,
  },
});

const step = ref(1);
const email = ref(props.initialEmail);
const resetCode = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
const errorMessage = ref("");
const successMessage = ref("");

const maskedEmail = computed(() => {
  if (!email.value) return "";
  const [username, domain] = email.value.split("@");
  return `${username[0]}${"*".repeat(username.length - 1)}@${domain}`;
});

onMounted(() => {
  if (props.skipFirstStep || props.codeAlreadySent) {
    step.value = 2;
    successMessage.value = t("authUser.resetPass.success.alreadySent");
  }
});

const goToStep2 = () => {
  step.value = 2;
  successMessage.value = t("authUser.resetPass.success.alreadySent");
};

const goBack = () => {
  emit("open-login");
};

const close = () => {
  emit("close");
};

const requestResetCode = () => {
  axios
    .post(
      "https://backend.umbrellax.io/api/auth/forgot-password",
      { email: email.value },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      if (response.status === 200 || response.status === 401) {
        step.value = 2;
        successMessage.value = t("authUser.resetPass.success.codeSent");
        errorMessage.value = "";
      } else {
        throw new Error(t("authUser.resetPass.errors.resetFailed"));
      }
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        step.value = 2;
        successMessage.value = t("authUser.resetPass.success.codeSent");
        return;
      }

      if (error.message === "Network Error") {
        errorMessage.value = t("authUser.resetPass.errors.networkError");
      } else {
        errorMessage.value =
          error.response?.data?.message ||
          t("authUser.resetPass.errors.sendCode");
      }
    });
};

const resetPassword = () => {
  if (newPassword.value !== confirmPassword.value) {
    errorMessage.value = t("authUser.resetPass.errors.passwordsNotMatch");
    return;
  }

  const params = new URLSearchParams({
    email: email.value,
    code: resetCode.value,
    password: newPassword.value,
    password_confirmation: confirmPassword.value,
  });

  axios
    .post(
      `https://backend.umbrellax.io/api/auth/reset-password?${params.toString()}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        successMessage.value = t("authUser.resetPass.success.resetSuccess");
        errorMessage.value = "";
        setTimeout(() => {
          close();
        }, 3000);
      } else {
        throw new Error(t("authUser.resetPass.errors.resetFailed"));
      }
    })
    .catch((error) => {
      errorMessage.value =
        error.response?.status === 401
          ? t("authUser.resetPass.errors.authFailed")
          : error.response?.data?.message ||
            t("authUser.resetPass.errors.generalError");
    });
};

defineExpose({ close, goToStep2 });
</script>
