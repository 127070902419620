
<template>
  <button @click="showMain">{{ $t("articleroute.p2p") }}</button>
</template>

<script setup>
import { useNavigationStore } from "../stores/marketStore";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const navigationStore = useNavigationStore();

function showMain() {
  navigationStore.setView('main');
}

</script>
