const messages = {
  ru: {
    tablehead: {
      level: "Уровень",
      p2pCashback: "Кешбэк P2P",
      upgradeRate: "Повысить до 0.4%",
      buyerCashback: "Кешбек за P2P покупки",
      yourBuyer: "Ваш покупатель",
      yourSeller: "Ваш продавец",
      rate: "Курс",
      youGive: "Вы отдаете ему",
      youReceive: "Вы получите от него",
      limitsAvailable: "Доступно | Лимиты",
      youWillReceive: "Вы получите",
      youWillGive: "Вы отдаете",
    },
  },
  en: {
    tablehead: {
      level: "Level",
      p2pCashback: "P2P Cashback",
      upgradeRate: "Upgrade to 0.4%",
      buyerCashback: "P2P Purchase Cashback",
      yourBuyer: "Your Buyer",
      yourSeller: "Your Seller",
      rate: "Rate",
      youGive: "You Give",
      youReceive: "You Receive",
      limitsAvailable: "Available | Limits",
      youWillReceive: "You Will Receive",
      youWillGive: "You Will Give",
    },
  },
  zh: {
    tablehead: {
      level: "等级",
      p2pCashback: "P2P返现",
      upgradeRate: "升级至0.4%",
      buyerCashback: "P2P购买返现",
      yourBuyer: "您的买家",
      yourSeller: "您的卖家",
      rate: "汇率",
      youGive: "您支付",
      youReceive: "您收到",
      limitsAvailable: "可用额度 | 限额",
      youWillReceive: "您将收到",
      youWillGive: "您将支付",
    },
  },
  ja: {
    tablehead: {
      level: "レベル",
      p2pCashback: "P2Pキャッシュバック",
      upgradeRate: "0.4%にアップグレード",
      buyerCashback: "P2P購入キャッシュバック",
      yourBuyer: "買い手",
      yourSeller: "売り手",
      rate: "レート",
      youGive: "支払額",
      youReceive: "受取額",
      limitsAvailable: "利用可能額 | 制限",
      youWillReceive: "受取予定額",
      youWillGive: "支払予定額",
    },
  },
};

export default messages;
