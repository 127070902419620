<template>
  <div class="flex flex-col items-center justify-start">
    <ProfileIcon class="w-[30px] h-[30px]" />
    <span class="text-[9px] custom-shadow mobile:hidden tablet:hidden">
      {{ $t("buttonheader.profile") }}</span
    >
  </div>
</template>

<script setup>
import ProfileIcon from "../components/icons/ProfileIcon.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>
