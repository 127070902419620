<template>
  <button
    class="flex justify-start gap-x-[10px] items-center px-1.5 py-1 rounded w-[80px] h-6 mobile:h-[22px] mobile:text-[10px] mobile:w-[70px] text-xs leading-tight text-[#797979] bg-white hover:bg-[#e1e1e1] shadow mobile:!justify-center"
    :class="{ 'w-[194px] h-[30px]': showSlot || showSlotReg }"
  >
    <GoogleIcon class="mobile:w-[12px] mobile:h-[12px]" />
    <p class="">
      <slot v-if="showSlot"
        ><span>{{ $t("login.entertg") }}</span></slot
      >
      <slot v-if="showSlotReg"
        ><span>{{ $t("login.registrationtg") }}</span></slot
      >
      Google
    </p>
  </button>
</template>

<script setup>
import GoogleIcon from "@/components/icons/GoogleIcon.vue";
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
defineProps({
  showSlot: {
    type: Boolean,
    default: false,
  },
  showSlotReg: {
    type: Boolean,
    default: false,
  },
});
</script>
