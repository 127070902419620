import { defineStore } from "pinia";
import { ref } from "vue";
import { nextTick } from "vue";

export const useAuthFormsStore = defineStore("authForms", () => {
  const isVisible = ref(false);
  const currentForm = ref(null);
  const initialEmail = ref("");
  const registrationType = ref(null);
  const verificationData = ref(null);
  const formData = ref({
    email: "",
    password: "",
    code: "",
    registrationStep: 1,
  });

  const resetState = () => {
    isVisible.value = false;
    currentForm.value = null;
    initialEmail.value = "";
    registrationType.value = null;
    verificationData.value = null;

    if (!currentForm.value) {
      formData.value = {
        email: "",
        password: "",
        code: "",
        registrationStep: 1,
      };
    }
  };

  const updateFormState = async (newState) => {
    console.log("Updating form state with:", newState);

    if (newState.currentForm !== currentForm.value) {
      isVisible.value = false;
      await nextTick();
    }

    currentForm.value = newState.currentForm;
    isVisible.value = newState.isVisible ?? true;

    if (newState.formData) {
      formData.value = {
        ...formData.value,
        ...newState.formData,
        email: newState.formData.email || formData.value.email,
        password: newState.formData.password || formData.value.password,
      };
    }

    console.log("Form state after update:", {
      currentForm: currentForm.value,
      formData: formData.value,
      isVisible: isVisible.value,
    });
  };

  const switchForm = async (formType, data = {}) => {
    try {
      await close();
      await nextTick();

      switch (formType) {
        case "login":
          await updateFormState({
            isVisible: true,
            currentForm: "login",
            formData: { email: data.email },
            initialEmail: data.email,
          });
          break;
        case "register":
          await updateFormState({
            isVisible: true,
            currentForm: "register",
            formData: { email: data.email, registrationStep: 1 },
            initialEmail: data.email,
          });
          break;
        case "reset-password":
          await updateFormState({
            isVisible: true,
            currentForm: "reset-password",
            formData: { registrationStep: 1 },
          });
          break;
        case "login-verification":
          await updateFormState({
            isVisible: true,
            currentForm: "login-verification",
            formData: {
              email: data.email,
              password: data.password,
            },
          });
          break;
        case "email-verification":
          await updateFormState({
            isVisible: true,
            currentForm: "email-verification",
            verificationData: data,
            formData: { email: data.email },
          });
          break;
        case "google-telegram":
          await updateFormState({
            isVisible: true,
            currentForm: "google-telegram",
            registrationType: data.type,
          });
          break;
        default:
          throw new Error(`Unknown form type: ${formType}`);
      }
    } catch (error) {
      console.error("Failed to switch form:", error);
      throw error;
    }
  };

  const close = async () => {
    isVisible.value = false;
    await nextTick();
    resetState();
  };

  const setFormData = (data) => {
    formData.value = { ...formData.value, ...data };
  };

  const resetFormData = () => {
    formData.value = {
      email: "",
      password: "",
      code: "",
      registrationStep: 1,
    };
  };

  return {
    isVisible,
    currentForm,
    initialEmail,
    registrationType,
    verificationData,
    formData,

    switchForm,
    close,
    setFormData,
    resetFormData,

    updateFormState,
  };
});
