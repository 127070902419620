const messages = {
  ru: {
    notification: {
      title: "Уведомления",
      tabs: {
        all: "Все",
        system: "Уведомления системы",
        recent: "Последние события",
        close: "Закрыть",
      },
      noNotifications: "Нет уведомлений",
      newMessage: "Новое сообщение в сделке",
      deal: "Сделка",
      youBuy: "Вы покупаете",
      youSell: "Вы продаете",
      for: "за",
      paymentMethod: "Метод оплаты",
      accept: "Принять",
      reject: "Отклонить",
      open: "Открыть",
      support: "Поддержка",
      reply: "Ответить",
      status: {
        accepted: "Ожидается оплата",
        paid: "Ожидается подтверждение оплаты",
        dispute: "Открыт спор",
        aborted: "отменена",
        finished: "завершена",
        declined: "не принята",
        pending: "была создана",
      },
    },
  },
  en: {
    notification: {
      title: "Notifications",
      tabs: {
        all: "All",
        system: "System Notifications",
        recent: "Recent Events",
        close: "Close",
      },
      noNotifications: "No notifications",
      newMessage: "New message in deal",
      deal: "Deal",
      youBuy: "You buy",
      youSell: "You sell",
      for: "for",
      paymentMethod: "Payment method",
      accept: "Accept",
      reject: "Reject",
      open: "Open",
      support: "Support",
      reply: "Reply",
      status: {
        accepted: "Awaiting payment",
        paid: "Awaiting payment confirmation",
        dispute: "Dispute opened",
        aborted: "cancelled",
        finished: "completed",
        declined: "not accepted",
        pending: "was created",
        new: "new",
      },
    },
  },
  zh: {
    notification: {
      title: "通知",
      tabs: {
        all: "全部",
        system: "系统通知",
        recent: "最近事件",
        close: "关闭",
      },
      noNotifications: "没有通知",
      newMessage: "交易中的新消息",
      deal: "交易",
      youBuy: "您购买",
      youSell: "您出售",
      for: "价格",
      paymentMethod: "支付方式",
      accept: "接受",
      reject: "拒绝",
      open: "打开",
      support: "客服",
      reply: "回复",
      status: {
        accepted: "等待付款",
        paid: "等待确认付款",
        dispute: "已开启争议",
        aborted: "已取消",
        finished: "已完成",
        declined: "未接受",
        pending: "已创建",
      },
    },
  },
  ja: {
    notification: {
      title: "通知",
      tabs: {
        all: "すべて",
        system: "システム通知",
        recent: "最近のイベント",
        close: "閉じる",
      },
      noNotifications: "通知はありません",
      newMessage: "取引の新しいメッセージ",
      deal: "取引",
      youBuy: "購入",
      youSell: "販売",
      for: "価格",
      paymentMethod: "支払方法",
      accept: "承認",
      reject: "拒否",
      open: "開く",
      support: "サポート",
      reply: "返信",
      status: {
        accepted: "支払い待ち",
        paid: "支払い確認待ち",
        dispute: "紛争開始",
        aborted: "キャンセル済み",
        finished: "完了",
        declined: "未承認",
        pending: "作成済み",
      },
    },
  },
};

export default messages;
