const messages = {
  ru: {
    payment: {
      noMethodsTitle: "У вас пока нет методов оплаты",
      addMethodTitle: "Добавить способ оплаты",
      editMethodTitle: "Редактирование способа оплаты",
      addNewMethodTitle: "Добавление нового способа оплаты",
      addedMethodsTitle: "Добавленные способы оплаты",

      currency: "Валюта",
      paymentMethod: "Способ оплаты",
      paymentMethodSimple: "Способ оплаты",
      details: "Детали",
      paymentNumber: "Номер для оплаты",
      paymentNumberRequired: "Номер для оплаты",
      recipientName: "ФИО получателя",
      recipientNameRequired: "ФИО получателя",
      cardNumber: "Номер карты",
      recipientBank: "Банк получателя*",
      bik: "БИК",
      correspondentAccount: "Корреспон. счет",
      inn: "ИНН",
      kpp: "КПП",

      placeholders: {
        bankName: "Банк получателя",
        paymentNumber: "Номер карты, аккаунт или расч. счёт",
        recipientName: "ФИО получателя",
        bik: "БИК",
        correspondentAccount: "Корреспондентский счет",
        inn: "ИНН",
        kpp: "КПП",
      },

      validationMessages: {
        paymentNumberRequired: "Пожалуйста, заполните номер для оплаты",
        recipientNameRequired: "Пожалуйста, заполните ФИО получателя",
        bankNameRequired: "Пожалуйста, заполните название банка получателя",
        selectPaymentMethod: "Выберите способ оплаты",
      },

      methodTypes: {
        cash: "Наличные",
        bankAccount: "Расчет. счет ({bank})",
        sbp: "СБП ({bank})",
        unknown: "Неизвестный метод {id}",
      },

      cityRequired: "Город",
      cityPlaceholder: "Выберите город",
      cityNotSpecified: "Город не указан",
      cityAddress: "Адрес",

      sbpRequired: "СБП*",
      bankPlaceholder: "Выберите банк",
      unknownBank: "Неизвестный банк",

      edit: "Редактировать",
      delete: "Удалить",
      cancel: "Отмена",
      save: "Сохранить",
      confirm: "Подтвердить",
      sell: "Продать",
      action: "Действие",

      validationErrors: "Пожалуйста, исправьте следующие ошибки:",
      methodsNotFound: "Методы оплаты не найдены",
      unknownMethod: "Неизвестный метод",

      exampleName: "Алекс Вескер",
    },
  },
  en: {
    payment: {
      noMethodsTitle: "You don't have any payment methods yet",
      addMethodTitle: "Add Payment Method",
      editMethodTitle: "Edit Payment Method",
      addNewMethodTitle: "Add New Payment Method",
      addedMethodsTitle: "Added Payment Methods",

      currency: "Currency*",
      paymentMethod: "Payment Method*",
      paymentMethodSimple: "Payment Method",
      details: "Details",
      paymentNumber: "Payment Number",
      paymentNumberRequired: "Payment Number*",
      recipientName: "Recipient's Full Name",
      recipientNameRequired: "Recipient's Full Name*",
      cardNumber: "Card Number",
      recipientBank: "Recipient's Bank*",
      bik: "BIC",
      correspondentAccount: "Correspondent Account",
      inn: "TIN",
      kpp: "KPP",

      placeholders: {
        bankName: "Recipient's Bank",
        paymentNumber: "Card number, account or settlement account",
        recipientName: "Recipient's Full Name",
        bik: "BIC",
        correspondentAccount: "Correspondent Account",
        inn: "TIN",
        kpp: "KPP",
      },

      validationMessages: {
        paymentNumberRequired: "Please enter the payment number",
        recipientNameRequired: "Please enter the recipient's name",
        bankNameRequired: "Please enter the recipient's bank name",
        selectPaymentMethod: "Select a payment method",
      },

      methodTypes: {
        cash: "Cash",
        bankAccount: "Bank Account ({bank})",
        sbp: "FPS ({bank})",
        unknown: "Unknown method {id}",
      },

      cityRequired: "City*",
      cityPlaceholder: "Select City",
      cityNotSpecified: "City not specified",
      cityAddress: "Address*",

      sbpRequired: "FPS*",
      bankPlaceholder: "Select Bank",
      unknownBank: "Unknown Bank",

      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      save: "Save",
      confirm: "Confirm",
      sell: "Sell",
      action: "Action",

      validationErrors: "Please fix the following errors:",
      methodsNotFound: "No payment methods found",
      unknownMethod: "Unknown method",

      exampleName: "Alex Wesker",
    },
  },
  zh: {
    payment: {
      noMethodsTitle: "您还没有支付方式",
      addMethodTitle: "添加支付方式",
      editMethodTitle: "编辑支付方式",
      addNewMethodTitle: "添加新支付方式",
      addedMethodsTitle: "已添加的支付方式",

      currency: "货币*",
      paymentMethod: "支付方式*",
      paymentMethodSimple: "支付方式",
      details: "详情",
      paymentNumber: "支付号码",
      paymentNumberRequired: "支付号码*",
      recipientName: "收款人全名",
      recipientNameRequired: "收款人全名*",
      cardNumber: "卡号",
      recipientBank: "收款银行*",
      bik: "银行识别码",
      correspondentAccount: "往来账户",
      inn: "纳税人识别号",
      kpp: "登记原因代码",

      placeholders: {
        bankName: "收款银行",
        paymentNumber: "卡号、账户或结算账户",
        recipientName: "收款人全名",
        bik: "银行识别码",
        correspondentAccount: "往来账户",
        inn: "纳税人识别号",
        kpp: "登记原因代码",
      },

      validationMessages: {
        paymentNumberRequired: "请输入支付号码",
        recipientNameRequired: "请输入收款人姓名",
        bankNameRequired: "请输入收款银行名称",
        selectPaymentMethod: "请选择支付方式",
      },

      methodTypes: {
        cash: "现金",
        bankAccount: "银行账户 ({bank})",
        sbp: "快速支付 ({bank})",
        unknown: "未知方式 {id}",
      },

      cityRequired: "城市*",
      cityPlaceholder: "选择城市",
      cityNotSpecified: "未指定城市",
      cityAddress: "地址*",

      sbpRequired: "快速支付系统*",
      bankPlaceholder: "选择银行",
      unknownBank: "未知银行",

      edit: "编辑",
      delete: "删除",
      cancel: "取消",
      save: "保存",
      confirm: "确认",
      sell: "出售",
      action: "操作",

      validationErrors: "请修正以下错误：",
      methodsNotFound: "未找到支付方式",
      unknownMethod: "未知方式",

      exampleName: "亚历克斯·威斯克",
    },
  },
  ja: {
    payment: {
      noMethodsTitle: "支払い方法がまだありません",
      addMethodTitle: "支払い方法を追加",
      editMethodTitle: "支払い方法を編集",
      addNewMethodTitle: "新しい支払い方法を追加",
      addedMethodsTitle: "追加された支払い方法",

      currency: "通貨*",
      paymentMethod: "支払い方法*",
      paymentMethodSimple: "支払い方法",
      details: "詳細",
      paymentNumber: "支払い番号",
      paymentNumberRequired: "支払い番号*",
      recipientName: "受取人氏名",
      recipientNameRequired: "受取人氏名*",
      cardNumber: "カード番号",
      recipientBank: "受取人銀行*",
      bik: "BICコード",
      correspondentAccount: "コルレス口座",
      inn: "納税者番号",
      kpp: "KPPコード",

      placeholders: {
        bankName: "受取人銀行",
        paymentNumber: "カード番号、アカウントまたは決済口座",
        recipientName: "受取人氏名",
        bik: "BICコード",
        correspondentAccount: "コルレス口座",
        inn: "納税者番号",
        kpp: "KPPコード",
      },

      validationMessages: {
        paymentNumberRequired: "支払い番号を入力してください",
        recipientNameRequired: "受取人名を入力してください",
        bankNameRequired: "受取人銀行名を入力してください",
        selectPaymentMethod: "方法を選択する",
      },

      methodTypes: {
        cash: "現金",
        bankAccount: "銀行口座 ({bank})",
        sbp: "即時支払い ({bank})",
        unknown: "不明な方法 {id}",
      },

      cityRequired: "都市*",
      cityPlaceholder: "都市を選択",
      cityNotSpecified: "都市が指定されていません",
      cityAddress: "住所*",

      sbpRequired: "即時支払いシステム*",
      bankPlaceholder: "銀行を選択",
      unknownBank: "不明な銀行",

      edit: "編集",
      delete: "削除",
      cancel: "キャンセル",
      save: "保存",
      confirm: "確認",
      sell: "売却",
      action: "アクション",

      validationErrors: "以下のエラーを修正してください：",
      methodsNotFound: "支払い方法が見つかりません",
      unknownMethod: "不明な方法",

      exampleName: "アレックス・ウェスカー",
    },
  },
};

export default messages;
