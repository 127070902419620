const messages = {
  ru: {
    login: {
      enter: "Войти",
      registration: "Регистрация",
      regclick: "или зарегистрируйтесь в 1 клик",
      entertg: "Войти через",
      registrationtg: "Регистрация через",
    },
  },
  en: {
    login: {
      enter: "Log In",
      registration: "Sign Up",
      regclick: "or register in 1 click",
      entertg: "Log in via",
      registrationtg: "Sign up via",
    },
  },
  zh: {
    login: {
      enter: "进入",
      registration: "登记注册",
      regclick: "或注册在1点击",
      entertg: "透过",
      registrationtg: "经",
    },
  },
  ja: {
    login: {
      enter: "入る",
      registration: "登録",
      regclick: "または1クリックで登録",
      entertg: "経由でログイン",
      registrationtg: "経由での登録",
    },
  },
};

export default messages;
