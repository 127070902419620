<template>
  <div class="relative">
    <span class="absolute top-[-2px] right-[-22px] text-[10px] text-[#FF6663]">
      {{ $t("articleroute.skoro") }}
    </span>
    <div
      class="flex items-center gap-x-[5px] justify-start text-[#797979] cursor-not-allowed"
    >
      <span>{{ $t("articleroute.staking") }}</span>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
