const messages = {
  ru: {
    footer: {
      agreement: "Регистрируясь и используя наш сайт, вы соглашаетесь с",
      links: {
        userAgreement: "Пользовательским соглашением",
        amlKyc: "Политикой AML/KYC",
        cookies: "Правилами использования Cookies",
        privacy: "Политикой конфиденциальности",
      },
      copyright: "2025 ©",
    },
  },
  en: {
    footer: {
      agreement: "By registering and using our site, you agree to the",
      links: {
        userAgreement: "User Agreement",
        amlKyc: "AML/KYC Policy",
        cookies: "Cookie Policy",
        privacy: "Privacy Policy",
      },
      copyright: "2025 ©",
    },
  },
  zh: {
    footer: {
      agreement: "注册并使用我们的网站即表示您同意",
      links: {
        userAgreement: "用户协议",
        amlKyc: "AML/KYC政策",
        cookies: "Cookie政策",
        privacy: "隐私政策",
      },
      copyright: "2025 ©",
    },
  },
  ja: {
    footer: {
      agreement:
        "登録して本サイトを使用することで、以下に同意したことになります：",
      links: {
        userAgreement: "利用規約",
        amlKyc: "AML/KYCポリシー",
        cookies: "Cookieポリシー",
        privacy: "プライバシーポリシー",
      },
      copyright: "2025 ©",
    },
  },
};

export default messages;
