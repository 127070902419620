import { createI18n } from "vue-i18n";
import investor from "./locales/investor";
import article from "./locales/article";
import login from "./locales/login";
import header from "./locales/header";
import cap from "./locales/cap";
import tablehead from "./locales/tablehead";
import tableorder from "./locales/tableorder";
import myads from "./locales/myads";
import mydeals from "./locales/mydeals";
import landing from "./locales/landing";
import faq from "./locales/faq";
import footer from "./locales/footer";
import auth from "./locales/auth";
import createAd from "./locales/createAd";
import settingPay from "./locales/settingPay";
import userProfile from "./locales/userProfile";
import transactions from "./locales/transactions";
import notifications from "./locales/notifications";
import rulesArticle from "./locales/rulesArticle";
import aml from "./locales/aml";
import p2pDeal from "./locales/p2pDeal";

const messages = {
  ru: {
    ...investor.ru,
    ...article.ru,
    ...login.ru,
    ...header.ru,
    ...cap.ru,
    ...tablehead.ru,
    ...tableorder.ru,
    ...myads.ru,
    ...mydeals.ru,
    ...landing.ru,
    ...faq.ru,
    ...footer.ru,
    ...auth.ru,
    ...createAd.ru,
    ...settingPay.ru,
    ...userProfile.ru,
    ...transactions.ru,
    ...notifications.ru,
    ...rulesArticle.ru,
    ...aml.ru,
    ...p2pDeal.ru,
  },
  en: {
    ...investor.en,
    ...article.en,
    ...login.en,
    ...header.en,
    ...cap.en,
    ...tablehead.en,
    ...tableorder.en,
    ...myads.en,
    ...mydeals.en,
    ...landing.en,
    ...faq.en,
    ...footer.en,
    ...auth.en,
    ...createAd.en,
    ...settingPay.en,
    ...userProfile.en,
    ...transactions.en,
    ...notifications.en,
    ...rulesArticle.en,
    ...aml.en,
    ...p2pDeal.en,
  },
  zh: {
    ...investor.zh,
    ...article.zh,
    ...login.zh,
    ...header.zh,
    ...cap.zh,
    ...tablehead.zh,
    ...tableorder.zh,
    ...myads.zh,
    ...mydeals.zh,
    ...landing.zh,
    ...faq.zh,
    ...footer.zh,
    ...auth.zh,
    ...createAd.zh,
    ...settingPay.zh,
    ...userProfile.zh,
    ...transactions.zh,
    ...notifications.zh,
    ...rulesArticle.zh,
    ...aml.zh,
    ...p2pDeal.zh,
  },
  ja: {
    ...investor.ja,
    ...article.ja,
    ...login.ja,
    ...header.ja,
    ...cap.ja,
    ...tablehead.ja,
    ...tableorder.ja,
    ...myads.ja,
    ...mydeals.ja,
    ...landing.ja,
    ...faq.ja,
    ...footer.ja,
    ...auth.ja,
    ...createAd.ja,
    ...settingPay.ja,
    ...userProfile.ja,
    ...transactions.zh,
    ...notifications.zh,
    ...rulesArticle.zh,
    ...aml.zh,
    ...p2pDeal.zh,
  },
};

export const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("language") || "en",
  fallbackLocale: "en",
  messages,
});
