<template>
  <div class="flex flex-col gap-2 mobile:gap-1 mb-1">
    <CheckboxItem
      v-model="formDataLocal.not_us_resident"
      id="notUSResident"
      required
      @update:modelValue="updateFormData('not_us_resident', $event)"
    >
      {{ $t("auth.checkbox.notUSResident") }}
    </CheckboxItem>

    <CheckboxItem
      v-model="formDataLocal.agree_to_terms"
      id="agreeToTerms"
      required
      @update:modelValue="updateFormData('agree_to_terms', $event)"
    >
      {{ $t("auth.checkbox.agreeToTermsStart") }}
      <RouterLink :to="{ name: 'userAgreement' }" class="text-blue-500">
        {{ $t("footer.links.userAgreement") }}
      </RouterLink>
      и
      <RouterLink :to="{ name: 'privacyPolicy' }" class="text-blue-500">
        {{ $t("footer.links.privacy") }}
      </RouterLink>
      *
    </CheckboxItem>

    <CheckboxItem
      v-model="formDataLocal.agree_to_marketing"
      id="agreeToMarketing"
      required
      @update:modelValue="updateFormData('agree_to_marketing', $event)"
    >
      {{ $t("auth.checkbox.agreeToMarketing") }} *
    </CheckboxItem>

    <CheckboxItem
      v-model="formDataLocal.agree_to_asset_use"
      id="agreeToAssetUse"
      required
      @update:modelValue="updateFormData('agree_to_asset_use', $event)"
    >
      {{ $t("auth.checkbox.agreeToAssetUse") }}
    </CheckboxItem>

    <CheckboxItem
      v-model="formDataLocal.agree_to_price_regulation"
      id="agreeToPriceRegulation"
      required
      @update:modelValue="updateFormData('agree_to_price_regulation', $event)"
    >
      {{ $t("auth.checkbox.agreeToPriceRegulation") }}
    </CheckboxItem>
  </div>
  <p v-if="hasCheckboxError" class="text-[9px] text-[#FF9387]">
    {{ $t("auth.checkbox.errorMessage") }}
  </p>
</template>

<script setup>
import { defineProps, defineEmits, reactive, watch, computed } from "vue";
import { RouterLink } from "vue-router";
import { useI18n } from "vue-i18n";
import CheckboxItem from "./CheckboxItem.vue";

const { t } = useI18n();

const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
  errors: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["update:formData"]);

const formDataLocal = reactive({
  not_us_resident: props.formData.not_us_resident || false,
  agree_to_terms: props.formData.agree_to_terms || false,
  agree_to_marketing: props.formData.agree_to_marketing || false,
  agree_to_asset_use: props.formData.agree_to_asset_use || false,
  agree_to_price_regulation: props.formData.agree_to_price_regulation || false,
});

const hasCheckboxError = computed(() => {
  return Object.keys(props.errors).some((key) =>
    [
      "not_us_resident",
      "agree_to_terms",
      "agree_to_marketing",
      "agree_to_asset_use",
      "agree_to_price_regulation",
    ].includes(key)
  );
});

watch(
  () => props.formData,
  (newValue) => {
    Object.keys(formDataLocal).forEach((key) => {
      formDataLocal[key] = newValue[key] || false;
    });
  },
  { deep: true }
);

const updateFormData = (key, value) => {
  formDataLocal[key] = value;
  emit("update:formData", {
    ...props.formData,
    not_us_resident: formDataLocal.not_us_resident,
    agree_to_terms: formDataLocal.agree_to_terms,
    agree_to_marketing: formDataLocal.agree_to_marketing,
    agree_to_asset_use: formDataLocal.agree_to_asset_use,
    agree_to_price_regulation: formDataLocal.agree_to_price_regulation,
  });
};
</script>
