<template>
  <div
    class="bg-white py-[20px] px-[20px] rounded-b-lg w-[414px] text-[#797979] mobile:w-full mobile:rounded-none mobile:h-full"
  >
    <h2 class="text-[18px] font-[600] mb-4 text-center">
      {{ t("passportForm.title") }}
    </h2>

    <form @submit.prevent="handleSubmit">
      <div class="mb-3" v-for="field in passportFields" :key="field.name">
        <label :for="field.name" class="block text-[13px] font-[500] mb-1">
          {{ field.label }}
        </label>
        <input
          :type="field.type"
          :id="field.name"
          v-model="formData[field.name]"
          class="w-full px-3 py-2 border border-gray-300 rounded-md text-[13px]"
          :placeholder="field.placeholder"
        />
      </div>
      <p class="text-[11px] mobile:text-[12px] text-center mb-4 text-[#FF6663]">
        {{ t("passportForm.fillAccurately") }}
      </p>
      <button
        type="submit"
        class="w-full bg-[#4ADE80] text-white text-[13px] font-[500] px-4 py-2 rounded-[5px] mobile:w-full mobile:fixed mobile:bottom-0 mobile:rigth-0 mobile:left-0"
        :disabled="verificationStore.isLoading"
      >
        {{
          verificationStore.isLoading
            ? t("passportForm.sending")
            : mode === "update"
            ? t("passportForm.resend")
            : t("passportForm.sendForVerification")
        }}
      </button>
    </form>
    <div v-if="error" class="text-[#FF6663] text-center mt-4">
      {{ error }}
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVerificationStore } from "@/stores/verification";

const { t } = useI18n();

const props = defineProps({
  uploadedFileIds: {
    type: Array,
    required: true,
  },
  mode: {
    type: String,
    default: "create",
    validator: (value) => ["create", "update"].includes(value),
  },
  kycStatus: {
    type: String,
    default: "",
  },
  kycComment: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["submission-complete"]);
const verificationStore = useVerificationStore();

const passportFields = computed(() => [
  {
    name: "last_name",
    label: t("passportForm.fields.lastName"),
    type: "text",
    placeholder: t("passportForm.placeholders.lastName"),
  },
  {
    name: "first_name",
    label: t("passportForm.fields.firstName"),
    type: "text",
    placeholder: t("passportForm.placeholders.firstName"),
  },
  {
    name: "middle_name",
    label: t("passportForm.fields.middleName"),
    type: "text",
    placeholder: t("passportForm.placeholders.middleName"),
  },
  {
    name: "number",
    label: t("passportForm.fields.passportNumber"),
    type: "text",
    placeholder: t("passportForm.placeholders.passportNumber"),
  },
  {
    name: "birthdate",
    label: t("passportForm.fields.birthDate"),
    type: "date",
    placeholder: t("passportForm.placeholders.birthDate"),
  },
  {
    name: "issued_by",
    label: t("passportForm.fields.issuedBy"),
    type: "text",
    placeholder: t("passportForm.placeholders.issuedBy"),
  },
  {
    name: "city",
    label: t("passportForm.fields.birthCity"),
    type: "text",
    placeholder: t("passportForm.placeholders.birthCity"),
  },
  {
    name: "address",
    label: t("passportForm.fields.address"),
    type: "text",
    placeholder: t("passportForm.placeholders.address"),
  },
]);

const formData = ref({
  last_name: "",
  first_name: "",
  middle_name: "",
  number: "",
  birthdate: "",
  issued_by: "",
  city: "",
  address: "",
});

const error = ref("");

const handleSubmit = () => {
  error.value = "";
  const submissionData = { ...formData.value };

  const promise =
    props.mode === "create"
      ? verificationStore.createKycApplication(submissionData)
      : verificationStore.updateKycApplication(submissionData);

  promise
    .then((result) => {
      if (result.success) {
        emit("submission-complete");
      } else {
        error.value =
          result.message || `Failed to ${props.mode} KYC application`;
      }
    })
    .catch((err) => {
      error.value =
        err.message ||
        `An error occurred while ${
          props.mode === "create" ? "creating" : "updating"
        } the application. Please try again.`;
      console.error(`Error ${props.mode}ing KYC application:`, err);
    });
};
</script>
