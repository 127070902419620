# App.vue
<template>
  <LoadingBar :is-loading="isLoading" />
  <div class="flex flex-col min-h-screen">
    <Header :is-loading="isLoading" />

    <template v-if="isGoogleCallback">
      <GoogleAuthCallback @auth-complete="handleAuthComplete" />
    </template>

    <template v-else>
      <component :is="currentComponent" v-if="shouldRenderComponent" />
    </template>

    <AuthForms
      v-if="authFormsStore.isVisible"
      ref="authForms"
      @close="handleAuthFormClose"
      @login-success="handleLoginSuccess"
      :initial-form="authFormsStore.currentForm"
      :initial-email="authFormsStore.initialEmail"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineAsyncComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "./stores/userStore";
import { useAuthStore } from "./stores/authStore";
import { useAuthFormsStore } from "./stores/authFormsStore";
import Header from "./blocks/Header.vue";
import AuthForms from "./components/auth/AuthForms.vue";
import GoogleAuthCallback from "./components/auth/GoogleAuthCallback.vue";
import LoadingBar from "./components/LoadingBar.vue";

const Page = defineAsyncComponent(() => import("./pages/index.vue"));
const Rules = defineAsyncComponent(() => import("./pages/rules.vue"));
const LandingPage = defineAsyncComponent(() =>
  import("./pages/LandingPage.vue")
);
const InvestorPage = defineAsyncComponent(() => import("./pages/Investor.vue"));

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const authStore = useAuthStore();
const authFormsStore = useAuthFormsStore();

const authForms = ref(null);
const isProcessingAuth = ref(false);
const isLoading = ref(false);

const isAuthenticated = computed(() => authStore.isAuthenticated);
const isGoogleCallback = computed(() => Boolean(route.query.code));
const isMainRoute = computed(() => route.name === "main");
const isLandingPage = computed(() => route.name === "landing");
const isInvestorPage = computed(() => route.name === "investor");
const isRulesRoute = computed(() =>
  ["userAgreement", "amlKycPolicy", "cookies", "privacyPolicy"].includes(
    route.name
  )
);

const currentComponent = computed(() => {
  if (isLandingPage.value) return LandingPage;
  if (isRulesRoute.value) return Rules;
  if (isInvestorPage.value) return InvestorPage;
  return Page;
});

const canAccessRoute = computed(() => {
  if (isMainRoute.value || isInvestorPage.value) return true;
  const requiresAuth = route.meta.requiresAuth;
  return !requiresAuth || isAuthenticated.value;
});

const shouldRenderComponent = computed(
  () =>
    !isGoogleCallback.value &&
    (isMainRoute.value ||
      isLandingPage.value ||
      isInvestorPage.value ||
      canAccessRoute.value)
);

function handleLoginSuccess(user) {
  return userStore
    .updateUser(user)
    .then(() => userStore.setAuthenticated(true))
    .then(() => {
      const intendedRoute = route.query.intendedRoute;
      if (intendedRoute) {
        return router.push(intendedRoute);
      }
    })
    .then(() => handleAuthFormClose())
    .catch((error) => {
      console.error("Login success handling error:", error);
    });
}

function handleAuthFormClose() {
  return authFormsStore
    .close()
    .then(() => {})
    .catch((error) => {
      console.error("Failed to close auth form:", error);
    });
}

function handleAuthComplete(success) {
  isProcessingAuth.value = false;
  if (success) {
    router.push("/p2p");
  } else {
    authFormsStore
      .updateFormState({
        currentForm: "login",
        isVisible: true,
      })
      .then(() => {
        router.push({
          name: "main",
          query: { error: "Authentication failed" },
        });
      });
  }
}

watch(
  () => route.query.code,
  (newCode) => {
    isProcessingAuth.value = Boolean(newCode);
  }
);

watch(
  () => isAuthenticated.value,
  (isAuthenticated) => {
    if (isAuthenticated && route.query.code) {
      router.push("/p2p");
    }
  }
);

onMounted(() => {
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://telegram.org/js/telegram-widget.js";
  document.head.appendChild(script);

  const { form, email } = route.query;
  if (form) {
    authFormsStore.updateFormState({
      currentForm: form,
      initialEmail: email || "",
      isVisible: true,
    });
  }
});
</script>
