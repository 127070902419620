// src/i18n/locales/investor.js
const messages = {
  ru: {
    time: {
      remaining: "{days}д {hours}ч {minutes}м {seconds}с",
      days: "{n}д",
      hours: "{n}ч",
      minutes: "{n}м",
      seconds: "{n}с",
    },
    rounds: {
      round: "Этап {number}",
      raised: "Собранно {amount} / {total} USDT",
      umbrella_rate: "1 UMBRELLA = {rate} USDT",
    },
    invest: {
      i_invest: "Я инвестирую",
      i_receive: "Я получу",
      buy_umbrella: "Купить UMBRELLA",
      fee: "0% комиссии",
    },
    contacts: {
      title: "Контакты",
      telegram_info:
        "По любым вопросам вы можете связаться с командой напрямую в Telegram Private Group для инвесторов",
      form_info:
        "Если вы уже сделали депозиты, пожалуйста, заполните Google форму по ссылке, чтобы получить ваши токены",
    },
    bonuses: {
      title: "Бонусы за объем инвестиций",
      round1: {
        title: "Раунд 1:",
        bonus1: "От $100,000: +3% токенов",
        bonus2: "От $200,000: +5% токенов",
        bonus3: "От $500,000: +7% токенов",
      },
      round2: {
        title: "Раунд 2:",
        bonus1: "От $1,000,000: +3% токенов",
        bonus2: "От $2,000,000: +5% токенов",
        bonus3: "От $5,000,000: +7% токенов",
      },
      round3: {
        title: "Раунд 3:",
        bonus1: "От $3,500,000: +3% токенов",
        bonus2: "От $5,000,000: +5% токенов",
        bonus3: "От $10,000,000: +7% токенов",
      },
    },
    table: {
      raising: "Сбор",
      price: "1 UMBRELLA цена",
      call_option: "UMBRELLA Call опцион",
      available_coins: "Доступные опционные монеты",
      immediate_tokens: "Немедленные токены",
      vesting: "График вестинга 6 мес. клиф",
      apr: "Umbrella APR",
      referral: "Реферальный бонус инвестора",

      within_year: "В течение {years} лет",
      bonus_percent: "Бонус +{percent}%",
    },
    header: {
      notifications: "Уведомления",
    },
    deposit: {
      modalTitle: "Депозит",
      amount: "Сумма",
      token: "Токен",
      network: "Сеть",
      warning: "Внимание: Пожалуйста, проверьте правильность выбранной",
      networkWarning: "сети, иначе средства будут безвозвратно утеряны.",
      address: "Адрес",
      attention: "ВНИМАНИЕ: Мы соблюдаем международные правила и",
      complianceNotice:
        "не работаем с грязными деньгами или с санкционированными лицами и организациями.",
      cexRecommendation:
        "Рекомендуется сначала внести депозит на любую официальную биржу CEX, купить и продать любую валюту, а затем отправить USDT на наш адрес.",
      amlNotice:
        "Перед отправкой просим самостоятельно провести AML проверку вашего кошелька и убедиться, что ваши USDT чистые. Средства будут депонированы на биржу CEX Bybit.com. Если ваш кошелек содержит более 5% грязных денег, средства могут быть задержаны в соответствии с политиками AML/KYC на Bybit.com и антисанкционными мерами, и будут отправлены обратно на ваш кошелек.",
      confirm: "Подтвердить",
    },
  },
  en: {
    deposit: {
      modalTitle: "Deposit",
      amount: "Amount",
      token: "Token",
      network: "Network",
      warning: "Warning: Please verify that you have selected the correct",
      networkWarning: "network, otherwise funds will be irretrievably lost.",
      address: "Address",
      attention: "ATTENTION: We comply with international regulations and do",
      complianceNotice:
        "not work with dirty money or with sanctioned individuals and organizations.",
      cexRecommendation:
        "Best practice is to deposit to any official CEX exchange, buy and sell any currency, and then send USDT to our address.",
      amlNotice:
        "Before sending, we ask you to independently perform an AML check of your wallet and verify that your USDT is clean. The funds will be deposited to the CEX exchange Bybit.com. If your wallet contains more than 5% dirty money, the funds may be held under AML/KYC policies on Bybit.com and anti-sanctions measures, and will be sent back to your wallet.",
      confirm: "Confirm",
    },
    time: {
      remaining: "{days}d {hours}h {minutes}m {seconds}s",
      days: "{n}d",
      hours: "{n}h",
      minutes: "{n}m",
      seconds: "{n}s",
    },
    rounds: {
      round: "Round {number}",
      raised: "Raised {amount} / {total} USDT",
      umbrella_rate: "1 UMBRELLA = {rate} USDT",
    },
    invest: {
      i_invest: "I invest",
      i_receive: "I receive",
      buy_umbrella: "Buy UMBRELLA",
      fee: "0% Fee",
    },
    contacts: {
      title: "Contacts",
      telegram_info:
        "For any questions, you can contact the team directly on Telegram Private Group for investors",
      form_info:
        "If you have already made deposits, please fill out the Google form via the link to receive your tokens",
    },
    bonuses: {
      title: "Investment Volume Bonuses",
      round1: {
        title: "Round 1:",
        bonus1: "From $100,000: +3% tokens",
        bonus2: "From $200,000: +5% tokens",
        bonus3: "From $500,000: +7% tokens",
      },
      round2: {
        title: "Round 2:",
        bonus1: "From $1,000,000: +3% tokens",
        bonus2: "From $2,000,000: +5% tokens",
        bonus3: "From $5,000,000: +7% tokens",
      },
      round3: {
        title: "Round 3:",
        bonus1: "From $3,500,000: +3% tokens",
        bonus2: "From $5,000,000: +5% tokens",
        bonus3: "From $10,000,000: +7% tokens",
      },
    },
    table: {
      raising: "Raising",
      price: "1 UMBRELLA price",
      call_option: "UMBRELLA Call option",
      available_coins: "Option coins available",
      immediate_tokens: "Immediate tokens",
      vesting: "Vesting schedule 6 mo cliff",
      apr: "Umbrella APR",
      referral: "Investor Referral Bonus",
      within_year: "Within {years} years",
      bonus_percent: "Bonus +{percent}%",
    },
    header: {
      notifications: "Notifications",
    },
  },
  zh: {
    deposit: {
      modalTitle: "存款",
      amount: "金额",
      token: "代币",
      network: "网络",
      warning: "警告：请验证您已选择正确的",
      networkWarning: "网络，否则资金将无法挽回地丢失。",
      address: "地址",
      attention: "注意：我们遵守国际法规，",
      complianceNotice: "不与黑钱或受制裁的个人和组织合作。",
      cexRecommendation:
        "最佳做法是将资金存入任何官方CEX交易所，买卖任何货币，然后将USDT发送到我们的地址。",
      amlNotice:
        "发送前，我们请您独立对您的钱包进行AML检查，并验证您的USDT是否清洁。资金将存入CEX交易所Bybit.com。如果您的钱包包含超过5%的黑钱，资金可能会根据Bybit.com的AML/KYC政策和反制裁措施被扣留，并将被退回您的钱包。",
      confirm: "确认",
    },
    time: {
      remaining: "{days}天 {hours}时 {minutes}分 {seconds}秒",
      days: "{n}天",
      hours: "{n}时",
      minutes: "{n}分",
      seconds: "{n}秒",
    },
    rounds: {
      round: "轮次 {number}",
      raised: "已筹集 {amount} / {total} USDT",
      umbrella_rate: "1 UMBRELLA = {rate} USDT",
    },
    invest: {
      i_invest: "我投资",
      i_receive: "我收到",
      buy_umbrella: "购买 UMBRELLA",
      fee: "0% 费用",
    },
    contacts: {
      title: "联系方式",
      telegram_info:
        "如有任何问题，您可以直接在投资者 Telegram 私人群组中联系团队",
      form_info: "如果您已经完成存款，请通过链接填写 Google 表格以接收您的代币",
    },
    bonuses: {
      title: "投资量奖励",
      round1: {
        title: "第1轮:",
        bonus1: "从 $100,000起: +3% 代币",
        bonus2: "从 $200,000起: +5% 代币",
        bonus3: "从 $500,000起: +7% 代币",
      },
      round2: {
        title: "第2轮:",
        bonus1: "从 $1,000,000起: +3% 代币",
        bonus2: "从 $2,000,000起: +5% 代币",
        bonus3: "从 $5,000,000起: +7% 代币",
      },
      round3: {
        title: "第3轮:",
        bonus1: "从 $3,500,000起: +3% 代币",
        bonus2: "从 $5,000,000起: +5% 代币",
        bonus3: "从 $10,000,000起: +7% 代币",
      },
    },
    table: {
      raising: "募资",
      price: "1 UMBRELLA 价格",
      call_option: "UMBRELLA 看涨期权",
      available_coins: "可用期权代币",
      immediate_tokens: "即时代币",
      vesting: "归属计划 6个月锁定期",
      apr: "Umbrella 年化收益率",
      referral: "投资者推荐奖励",
      within_year: "{years}年内",
      bonus_percent: "奖励 +{percent}%",
    },
    header: {
      notifications: "通知",
    },
  },
  ja: {
    time: {
      remaining: "{days}日 {hours}時 {minutes}分 {seconds}秒",
      days: "{n}日",
      hours: "{n}時",
      minutes: "{n}分",
      seconds: "{n}秒",
    },
    rounds: {
      round: "ラウンド {number}",
      raised: "調達済み {amount} / {total} USDT",
      umbrella_rate: "1 UMBRELLA = {rate} USDT",
    },
    invest: {
      i_invest: "投資額",
      i_receive: "受取額",
      buy_umbrella: "UMBRELLA を購入",
      fee: "手数料 0%",
    },
    contacts: {
      title: "連絡先",
      telegram_info:
        "ご質問がある場合は、投資家向け Telegram プライベートグループで直接チームにお問い合わせいただけます",
      form_info:
        "既に入金されている場合は、リンクから Google フォームにご記入いただき、トークンを受け取ることができます",
    },
    bonuses: {
      title: "投資額によるボーナス",
      round1: {
        title: "ラウンド 1:",
        bonus1: "$100,000から: +3%トークン",
        bonus2: "$200,000から: +5%トークン",
        bonus3: "$500,000から: +7%トークン",
      },
      round2: {
        title: "ラウンド 2:",
        bonus1: "$1,000,000から: +3%トークン",
        bonus2: "$2,000,000から: +5%トークン",
        bonus3: "$5,000,000から: +7%トークン",
      },
      round3: {
        title: "ラウンド 3:",
        bonus1: "$3,500,000から: +3%トークン",
        bonus2: "$5,000,000から: +5%トークン",
        bonus3: "$10,000,000から: +7%トークン",
      },
    },
    table: {
      raising: "調達額",
      price: "1 UMBRELLA 価格",
      call_option: "UMBRELLA コールオプション",
      available_coins: "利用可能なオプションコイン",
      immediate_tokens: "即時トークン",
      vesting: "ベスティングスケジュール 6ヶ月クリフ",
      apr: "Umbrella APR",
      referral: "投資家紹介ボーナス",
      within_year: "{years}年以内",
      bonus_percent: "ボーナス +{percent}%",
    },
    header: {
      notifications: "通知",
    },
    deposit: {
      modalTitle: "入金",
      amount: "金額",
      token: "トークン",
      network: "ネットワーク",
      warning: "警告：選択した",
      networkWarning:
        "ネットワークが正しいことを確認してください。さもないと資金は取り返しがつかないほど失われます。",
      address: "アドレス",
      attention: "注意：当社は国際規制を遵守し、",
      complianceNotice:
        "マネーロンダリングや制裁対象の個人・組織との取引は行いません。",
      cexRecommendation:
        "ベストプラクティスは、公式CEX取引所に入金し、任意の通貨を売買した後、USDTを当社のアドレスに送金することです。",
      amlNotice:
        "送金前に、ご自身でウォレットのAMLチェックを行い、USDTがクリーンであることを確認してください。資金はCEX取引所Bybit.comに入金されます。ウォレットに5%以上の不正資金が含まれている場合、Bybit.comのAML/KYCポリシーと制裁対策により資金が保留され、あなたのウォレットに返送される可能性があります。",
      confirm: "確認",
    },
  },
};

export default messages;
