const messages = {
  ru: {
    auth: {
      signUp: {
        title: "Регистрация",
        haveAccount: "Уже есть аккаунт?",
        login: "Войти",
        username: "Логин*",
        email: "Электронная почта*",
        password: "Пароль*",
        generatePassword: "Сгенерировать надежный пароль",
        referralCode: "Реферальный код или ссылка (если есть)",
        register: "Зарегистрироваться",
        oneClickRegister: "или зарегистрируйтесь в 1 клик",
        errors: {
          username:
            "Допустимые символы (от 6 до 20): a-z, 0-9, _. Имя должно начинаться с буквы",
          email: "Пожалуйста, введите корректный email",
          password: "Пароль минимум 6 символов. Без пробелов",
          usernameTaken: "Такой логин уже занят",
          emailTaken: "Такой email уже зарегистрирован",
          defaultError: "Произошла ошибка при регистрации",
          checkboxRequired: "Вы должны согласиться с условиями",
        },
      },
      checkbox: {
        notUSResident:
          "Я не являюсь гражданином или налоговым резидентом США. Не нахожусь и не торгую на территории США. *",
        agreeToTermsStart: "Я соглашаюсь с  ",
        agreeToMarketing:
          "Я согласен на получение рекламных и любых других сообщений по всем каналам связи",
        agreeToAssetUse:
          "Я согласен на предоставление своих активов для использования в иных сферах деятельности и инвестирование, в том числе в высокорискованные активы, как внутри, так и вовне криптобиржи без отчета и уведомлений, и не нахожусь под юрисдикцией стран, где это запрещено *",
        agreeToPriceRegulation:
          "Я согласен, что криптобиржа может регулировать цену при необходимости на некоторые токены, помимо рыночных механизмов для защиты от угроз и уязвимостей и иных целей, и не нахожусь под юрисдикцией стран, где это запрещено *",
        errorMessage: "Вы должны согласиться с условиями",
      },

      emailVerification: {
        title: "Подтвердите ваш E-mail",
        openEmail: "Откройте письмо в вашем почтовом сервисе",
        codeSentTo: "Код отправлен на",
        inputPlaceholder: "Код подтверждения E-mail",
        verifying: "Подтверждение...",
        verify: "Подтвердить",
        back: "Назад",
        resending: "отправка...",
        resendIn: "повторная отправка через {seconds}с",
        resendButton: "отправить повторно",
        noCodeReceived:
          'Не приходит код? Проверьте папку "Спам" или обратитесь в поддержку',
        errors: {
          emptyCode: "Пожалуйста, введите код подтверждения",
          verificationFailed: "Ошибка при подтверждении email",
          resendFailed: "Ошибка при отправке кода. Попробуйте позже.",
        },
      },

      login: {
        title: "Войти",
        noAccount: "Еще нет аккаунта?",
        register: "Зарегистрироваться",
        emailOrLogin: "Электронная почта или логин",
        password: "Пароль",
        resetPassword: "Восстановить пароль",
        loading: "Загрузка...",
        loginButton: "Войти",
        oneClickLogin: "или войдите в 1 клик",
        errors: {
          loginRequired: "Поле логина обязательно для заполнения",
          passwordRequired: "Пароль обязателен",
          incorrectLogin: "Неверный логин",
          incorrectPassword: "Неверный пароль",
          defaultError: "Произошла ошибка при входе",
          socialAuth: "Ошибка авторизации через {provider}",
        },
        placeholders: {
          emailOrLogin: "Электронная почта или логин",
          password: "Пароль",
        },
      },
      verifyEmail: {
        title: "Подтвердите ваш E-mail",
        openEmail: "Откройте письмо в вашем почтовом сервисе",
        codeSentTo: "Код отправлен на",
        codePlaceholder: "Код подтверждения E-mail",
        verifying: "Подтверждение...",
        verify: "Подтвердить",
        back: "Назад",
        noCode:
          'Не приходит код? Проверьте папку "Спам" или обратитесь в поддержку',
        error: "Ошибка подтверждения. Проверьте код.",
      },

      google: {
        processing: "Обработка аутентификации Google...",
        wait: "Пожалуйста, подождите, пока мы завершим процесс.",
        failed: "Authentication failed",
      },
      resetPass: {
        reset: "Восстановление пароля",
        yourEmail: "Введите ваш email для получения кода восстановления",
        email: "Email",
        sendCode: "Отправить код",
        codeGo: "Код отправлен на",
        code: "Код подтверждения",
        sendCode: "Новый пароль",
        newPas: "Подтвердите пароль",
        repeatPas: "Ошибка аутентификации",
        resetPas: "Сбросить пароль",
        back: "Назад",
      },
    },
  },
  en: {
    auth: {
      signUp: {
        title: "Sign Up",
        haveAccount: "Already have an account?",
        login: "Login",
        username: "Username*",
        email: "Email*",
        password: "Password*",
        generatePassword: "Generate strong password",
        referralCode: "Referral code or link (if any)",
        register: "Sign Up",
        oneClickRegister: "or register with 1 click",
        errors: {
          username:
            "Allowed characters (6-20): a-z, 0-9, _. Must start with a letter",
          email: "Please enter a valid email",
          password: "Password must be at least 6 characters. No spaces",
          usernameTaken: "This username is already taken",
          emailTaken: "This email is already registered",
          defaultError: "An error occurred during registration",
          checkboxRequired: "You must agree to the terms",
        },
      },
      checkbox: {
        notUSResident:
          "I am not a US citizen or tax resident. I am not located in and do not trade from the US territory. *",
        agreeToTermsStart:
          "I agree with the User Agreement and Privacy Policy *",
        agreeToMarketing:
          "I agree to receive promotional and any other messages through all communication channels",
        agreeToAssetUse:
          "I agree to provide my assets for use in other areas of activity and investment, including high-risk assets, both within and outside the crypto exchange without reports and notifications, and I am not under the jurisdiction of countries where this is prohibited *",
        agreeToPriceRegulation:
          "I agree that the crypto exchange may regulate the price when necessary for certain tokens, in addition to market mechanisms to protect against threats and vulnerabilities and other purposes, and I am not under the jurisdiction of countries where this is prohibited *",
        errorMessage: "You must agree to the terms",
      },
      emailVerification: {
        title: "Verify your Email",
        openEmail: "Open the email in your mail service",
        codeSentTo: "Code sent to",
        inputPlaceholder: "Email verification code",
        verifying: "Verifying...",
        verify: "Verify",
        back: "Back",
        resending: "sending...",
        resendIn: "resend in {seconds}s",
        resendButton: "resend",
        noCodeReceived:
          "Haven't received the code? Check your Spam folder or contact support",
        errors: {
          emptyCode: "Please enter the verification code",
          verificationFailed: "Email verification failed",
          resendFailed: "Error sending code. Please try again later.",
        },
      },
      login: {
        title: "Login",
        noAccount: "Don't have an account?",
        register: "Sign Up",
        emailOrLogin: "Email or login",
        password: "Password",
        resetPassword: "Reset password",
        loading: "Loading...",
        loginButton: "Login",
        oneClickLogin: "or login with 1 click",
        errors: {
          loginRequired: "Login field is required",
          passwordRequired: "Password is required",
          incorrectLogin: "Incorrect login",
          incorrectPassword: "Incorrect password",
          defaultError: "An error occurred during login",
          socialAuth: "Authentication error with {provider}",
        },
        placeholders: {
          emailOrLogin: "Email or login",
          password: "Password",
        },
      },
      verifyEmail: {
        title: "Verify your E-mail",
        openEmail: "Open the email in your mail service",
        codeSentTo: "Code sent to",
        codePlaceholder: "Email verification code",
        verifying: "Verifying...",
        verify: "Verify",
        back: "Back",
        noCode:
          "Haven't received the code? Check your Spam folder or contact support",
        error: "Verification error. Please check the code.",
      },
      resetPass: {
        reset: "Password Recovery",
        yourEmail: "Enter your email to receive a recovery code",
        email: "Email",
        sendCode: "Send Code",
        codeGo: "Code sent to",
        code: "Verification code",
        sendCode: "New password",
        newPas: "Confirm password",
        repeatPas: "Authentication failed",
        resetPas: "Reset Password",
        back: "Back",
      },
      google: {
        processing: "Processing Google authentication...",
        wait: "Please wait while we complete the process.",
        failed: "Authentication failed",
      },
    },
  },
  zh: {
    auth: {
      signUp: {
        title: "注册",
        haveAccount: "已有账户？",
        login: "登录",
        username: "用户名*",
        email: "电子邮箱*",
        password: "密码*",
        generatePassword: "生成强密码",
        referralCode: "推荐码或链接（如有）",
        register: "注册",
        oneClickRegister: "或一键注册",
        errors: {
          username: "允许字符（6-20位）：a-z、0-9、_。必须以字母开头",
          email: "请输入有效的电子邮箱",
          password: "密码至少6个字符。不含空格",
          usernameTaken: "该用户名已被使用",
          emailTaken: "该邮箱已注册",
          defaultError: "注册过程中发生错误",
          checkboxRequired: "您必须同意条款",
        },
      },
      checkbox: {
        notUSResident:
          "我不是美国公民或税收居民。我不在美国境内，也不在美国境内进行交易。 *",
        agreeToTermsStart: "我同意用户协议和隐私政策 *",
        agreeToMarketing: "我同意通过所有通信渠道接收促销和任何其他消息",
        agreeToAssetUse:
          "我同意将我的资产用于加密货币交易所内外的其他活动领域和投资，包括高风险资产，无需报告和通知，并且我不受禁止此类活动的国家/地区管辖 *",
        agreeToPriceRegulation:
          "我同意加密货币交易所可以在必要时对某些代币的价格进行调控，除市场机制外，还可以防范威胁和漏洞以及其他目的，并且我不受禁止此类活动的国家/地区管辖 *",
        errorMessage: "您必须同意条款",
      },
      emailVerification: {
        title: "验证您的邮箱",
        openEmail: "在您的邮箱服务中打开邮件",
        codeSentTo: "验证码已发送至",
        inputPlaceholder: "邮箱验证码",
        verifying: "验证中...",
        verify: "验证",
        back: "返回",
        resending: "发送中...",
        resendIn: "{seconds}秒后重新发送",
        resendButton: "重新发送",
        noCodeReceived: "没有收到验证码？请检查垃圾邮件文件夹或联系支持",
        errors: {
          emptyCode: "请输入验证码",
          verificationFailed: "邮箱验证失败",
          resendFailed: "发送验证码失败。请稍后重试。",
        },
      },
      login: {
        title: "登录",
        noAccount: "还没有账户？",
        register: "注册",
        emailOrLogin: "邮箱或登录名",
        password: "密码",
        resetPassword: "重置密码",
        loading: "加载中...",
        loginButton: "登录",
        oneClickLogin: "或一键登录",
        errors: {
          loginRequired: "请输入登录名",
          passwordRequired: "请输入密码",
          incorrectLogin: "登录名错误",
          incorrectPassword: "密码错误",
          defaultError: "登录时发生错误",
          socialAuth: "通过{provider}认证时出错",
        },
        placeholders: {
          emailOrLogin: "邮箱或登录名",
          password: "密码",
        },
      },
      verifyEmail: {
        title: "验证您的电子邮箱",
        openEmail: "请在您的邮件服务中打开邮件",
        codeSentTo: "验证码已发送至",
        codePlaceholder: "电子邮箱验证码",
        verifying: "验证中...",
        verify: "验证",
        back: "返回",
        noCode: "没有收到验证码？请检查垃圾邮件文件夹或联系客服",
        error: "验证错误，请检查验证码。",
      },
      resetPass: {
        reset: "密码恢复",
        yourEmail: "输入您的电子邮箱以接收恢复码",
        email: "电子邮箱",
        sendCode: "发送验证码",
        codeGo: "验证码已发送至",
        code: "验证码",
        sendCode: "新密码",
        newPas: "确认密码",
        repeatPas: "身份验证失败",
        resetPas: "重置密码",
        back: "返回",
      },
      google: {
        processing: "正在处理 Google 认证...",
        wait: "请稍候，我们正在完成认证过程。",
        failed: "认证失败",
      },
    },
  },
  ja: {
    auth: {
      signUp: {
        title: "新規登録",
        haveAccount: "すでにアカウントをお持ちですか？",
        login: "ログイン",
        username: "ユーザー名*",
        email: "メールアドレス*",
        password: "パスワード*",
        generatePassword: "強力なパスワードを生成",
        referralCode: "紹介コードまたはリンク（お持ちの場合）",
        register: "登録する",
        oneClickRegister: "または1クリックで登録",
        errors: {
          username:
            "使用可能な文字（6-20文字）：a-z、0-9、_。文字で始める必要があります",
          email: "有効なメールアドレスを入力してください",
          password: "パスワードは6文字以上必要です。スペースは使用できません",
          usernameTaken: "このユーザー名は既に使用されています",
          emailTaken: "このメールアドレスは既に登録されています",
          defaultError: "登録中にエラーが発生しました",
          checkboxRequired: "利用規約に同意する必要があります",
        },
      },
      checkbox: {
        notUSResident:
          "私は米国市民または税務上の居住者ではありません。米国内に所在せず、米国内で取引を行っていません。*",
        agreeToTermsStart: "利用規約とプライバシーポリシーに同意します *",
        agreeToMarketing:
          "すべての通信チャネルを通じてプロモーションおよびその他のメッセージを受け取ることに同意します",
        agreeToAssetUse:
          "私は、暗号資産取引所の内外で、報告や通知なしに、高リスク資産を含む他の活動分野や投資のために自己の資産を提供することに同意し、これが禁止されている国の管轄下にはありません *",
        agreeToPriceRegulation:
          "私は、暗号資産取引所が、市場メカニズムに加えて、脅威や脆弱性からの保護やその他の目的のために、必要に応じて特定のトークンの価格を規制できることに同意し、これが禁止されている国の管轄下にはありません *",
        errorMessage: "利用規約に同意する必要があります",
      },
      emailVerification: {
        title: "メールアドレスを確認",
        openEmail: "メールサービスでメールを開いてください",
        codeSentTo: "コードの送信先：",
        inputPlaceholder: "メール確認コード",
        verifying: "確認中...",
        verify: "確認",
        back: "戻る",
        resending: "送信中...",
        resendIn: "{seconds}秒後に再送信",
        resendButton: "再送信",
        noCodeReceived:
          "コードが届かない場合は、迷惑メールフォルダを確認するかサポートにお問い合わせください",
        errors: {
          emptyCode: "確認コードを入力してください",
          verificationFailed: "メール確認に失敗しました",
          resendFailed:
            "コードの送信に失敗しました。後でもう一度お試しください。",
        },
      },
      login: {
        title: "ログイン",
        noAccount: "アカウントをお持ちでない場合",
        register: "登録",
        emailOrLogin: "メールアドレスまたはログイン名",
        password: "パスワード",
        resetPassword: "パスワードをリセット",
        loading: "読み込み中...",
        loginButton: "ログイン",
        oneClickLogin: "またはワンクリックでログイン",
        errors: {
          loginRequired: "ログイン名を入力してください",
          passwordRequired: "パスワードを入力してください",
          incorrectLogin: "ログイン名が正しくありません",
          incorrectPassword: "パスワードが正しくありません",
          defaultError: "ログイン中にエラーが発生しました",
          socialAuth: "{provider}認証でエラーが発生しました",
        },
        placeholders: {
          emailOrLogin: "メールアドレスまたはログイン名",
          password: "パスワード",
        },
      },
      verifyEmail: {
        title: "メールアドレスの確認",
        openEmail: "メールサービスでメールを開いてください",
        codeSentTo: "コードの送信先：",
        codePlaceholder: "メール確認コード",
        verifying: "確認中...",
        verify: "確認する",
        back: "戻る",
        noCode:
          "コードが届かない場合は、迷惑メールフォルダを確認するかサポートにお問い合わせください",
        error: "確認エラー。コードを確認してください。",
      },
      resetPass: {
        reset: "パスワードの復元",
        yourEmail: "復元コードを受け取るメールアドレスを入力してください",
        email: "メールアドレス",
        sendCode: "コードを送信",
        codeGo: "コードの送信先：",
        code: "確認コード",
        sendCode: "新しいパスワード",
        newPas: "パスワードの確認",
        repeatPas: "認証に失敗しました",
        resetPas: "パスワードをリセット",
        back: "戻る",
      },
      google: {
        processing: "Google認証を処理中...",
        wait: "認証プロセスが完了するまでお待ちください。",
        failed: "認証に失敗しました",
      },
    },
  },
};

export default messages;
