<template>
  <LoginForm
    v-if="authFormsStore.currentForm === 'login'"
    :email="authFormsStore.formData.email"
    @close="closeForm"
    @switch-form="setCurrentForm"
    @login-success="handleLoginSuccess"
    @verification-required="handleLoginVerificationRequired"
  />
  <RegisterForm
    v-if="authFormsStore.currentForm === 'register'"
    ref="registerFormRef"
    :email="authFormsStore.formData.email"
    @close="closeForm"
    @switch-form="setCurrentForm"
    @registration-success="handleRegistrationSuccess"
  />
  <RegisterGoogleTelegram
    v-if="authFormsStore.currentForm === 'google-telegram'"
    :registrationType="authFormsStore.registrationType"
    @close="closeForm"
    @switch-form="setCurrentForm"
  />
  <ResetPassword
    v-if="authFormsStore.currentForm === 'reset-password'"
    @close="closeForm"
    @switch-form="setCurrentForm"
  />
  <ConfirmEmail
    v-if="authFormsStore.currentForm === 'email-verification'"
    :email="authFormsStore.formData.email"
    @close="closeForm"
    @verified="handleEmailVerified"
    @back="handleConfirmEmailBack"
  />
  <LoginVerification
    v-if="authFormsStore.currentForm === 'login-verification'"
    :loginData="authFormsStore.formData"
    @close="closeForm"
    @verified="handleLoginVerified"
    @back="setCurrentForm('login')"
  />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAuthFormsStore } from "@/stores/authFormsStore";
import LoginForm from "./LoginForm.vue";
import RegisterForm from "./RegisterForm.vue";
import RegisterGoogleTelegram from "./RegisterGoogleTelegram.vue";
import ResetPassword from "./ResetPassword.vue";
import ConfirmEmail from "./ConfirmEmail.vue";
import LoginVerification from "./LoginVerification.vue";

const props = defineProps({
  initialForm: String,
  initialEmail: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["close", "login-success"]);
const authFormsStore = useAuthFormsStore();
const registerFormRef = ref(null);

const setCurrentForm = async (formName, data = {}) => {
  try {
    await authFormsStore.updateFormState({
      currentForm: formName,
      ...data,
    });
  } catch (error) {
    console.error(`Failed to switch to ${formName} form:`, error);
  }
};

const handleLoginSuccess = (result) => {
  emit("login-success", result);
  closeForm();
};

const handleRegistrationSuccess = async (data) => {
  console.log("Registration success data:", data);
  await authFormsStore.updateFormState({
    currentForm: "email-verification",
    formData: {
      email: data,
    },
    isVisible: true,
  });
};

const handleEmailVerified = async () => {
  await setCurrentForm("login");
};

const handleConfirmEmailBack = async () => {
  await setCurrentForm("register", { email: authFormsStore.formData.email });
};

const handleLoginVerificationRequired = async (data) => {
  await authFormsStore.updateFormState({
    currentForm: "login-verification",
    formData: {
      ...authFormsStore.formData,
      email: data.email,
      password: data.password,
    },
    isVisible: true,
  });
};

const handleLoginVerified = (result) => {
  emit("login-success", result);
  closeForm();
};

const closeForm = async () => {
  await authFormsStore.close();
  emit("close");
};

onMounted(() => {
  if (props.initialForm || props.initialEmail) {
    authFormsStore.updateFormState({
      currentForm: props.initialForm,
      isVisible: true,
      formData: {
        email: props.initialEmail,
      },
    });
  }
});
</script>
