const messages = {
  ru: {
    history: {
      filterStatus: "Статус задачи",
      filters: {
        current: "Текущие",
        completed: "Завершенные",
      },
      dealStatus: {
        createDeal: "Создайте сделку",
        creating: "Создание сделки",
        inProcess: "В процессе",
        completed: "Завершено",
        dispute: "На апелляции",

        cancelled: "Отменено",
        cancelledNoRating: "Отменено без учета рейтинга",
        notConfirmed: "Не подтверждено",
        unknown: "Неизвестный статус",
      },
      columns: {
        dealNumber: "Сделка №",
        type: "Тип",
        amount: "Сумма",
        participant: "Участник сделки",
        paymentMethod: "Способ оплаты",
        status: "Статус",
        action: "Действие",
      },
      new: "НОВАЯ",
      youBuy: "Я покупаю",
      youSell: "Я продаю",
      for: "За",
      rate: "По курсу",
      sendTo: "Переведу на",
      receiveTo: "Получу на",
      acceptDeal: {
        title: "Подтвердите сделку",
        timeLeft: "в течении",
      },
      actions: {
        accept: "Принять",
        decline: "Отклонить",
        open: "Открыть",
      },
      buyer: "Покупатель",
      seller: "Продавец",

      merchantStats: {
        completed: "исполнено",
        rating: "рейтинг",
      },
      paymentMethods: {
        notSpecified: "Не указано",
        cash: "Наличные",
        bankAccount: "Расчетный счет",
        unknown: "Неизвестный метод",
      },
      noDeals: "У вас пока нет сделок",
      errors: {
        loadingOrders: "Произошла ошибка при загрузке заказов",
        acceptOrder: "Не удалось принять заказ",
        declineOrder: "Не удалось отклонить заказ",
      },
    },
    deal: {
      type: {
        buy: {
          label: "Покупка",
          description: "Вы покупаете монеты",
          receive: "Вы получите на свой криптокошелёк",
          send: "Вы переведете продавцу",
        },
        sell: {
          label: "Продажа",
          description: "Вы продаете монеты",
          send: "Вы отдадите монеты с криптокошелька",
          receive: "Покупатель переведет вам",
        },
      },
      paymentDetails: {
        whereToSend: "Куда перевести продавцу",
        whereToReceive: "Получите на",
      },
      priceDetails: {
        pricePerUnit: "Цена за 1",
        currencyNotSpecified: "Валюта не указана",
      },
    },
  },

  en: {
    history: {
      filterStatus: "Task Status",
      filters: {
        current: "Current",
        completed: "Completed",
      },
      dealStatus: {
        createDeal: "Create deal",
        creating: "Creating deal",
        inProcess: "In progress",
        completed: "Completed",
        dispute: "Under appeal",

        cancelled: "Cancelled",
        cancelledNoRating: "Cancelled without rating",
        notConfirmed: "Not confirmed",
        unknown: "Unknown status",
      },
      columns: {
        dealNumber: "Deal #",
        type: "Type",
        amount: "Amount",
        participant: "Deal Participant",
        paymentMethod: "Payment Method",
        status: "Status",
        action: "Action",
      },
      new: "NEW",
      youBuy: "I'm buying",
      youSell: "I'm selling",
      for: "For",
      rate: "At rate",
      sendTo: "Will send to",
      receiveTo: "Will receive to",
      acceptDeal: {
        title: "Confirm the deal",
        timeLeft: "within",
      },
      actions: {
        accept: "Accept",
        decline: "Decline",
        open: "Open",
      },
      buyer: "Buyer",
      seller: "Seller",
      merchantStats: {
        completed: "completed",
        rating: "rating",
      },
      paymentMethods: {
        notSpecified: "Not specified",
        cash: "Cash",
        bankAccount: "Bank Account",
        unknown: "Unknown method",
      },
      noDeals: "You don't have any deals yet",
      errors: {
        loadingOrders: "Error loading orders",
        acceptOrder: "Failed to accept order",
        declineOrder: "Failed to decline order",
      },
    },
    deal: {
      type: {
        buy: {
          label: "Buy",
          description: "You are buying coins",
          receive: "You will receive to your crypto wallet",
          send: "You will send to seller",
        },
        sell: {
          label: "Sell",
          description: "You are selling coins",
          send: "You will send coins from crypto wallet",
          receive: "Buyer will send you",
        },
      },
      paymentDetails: {
        whereToSend: "Where to send to seller",
        whereToReceive: "Receive to",
      },
      priceDetails: {
        pricePerUnit: "Price per 1",
        currencyNotSpecified: "Currency not specified",
      },
    },
  },

  zh: {
    history: {
      filterStatus: "任务状态",
      filters: {
        current: "当前",
        completed: "已完成",
      },
      dealStatus: {
        createDeal: "创建交易",
        creating: "创建中",
        inProcess: "进行中",
        completed: "已完成",
        dispute: "申诉中",

        cancelled: "已取消",
        cancelledNoRating: "取消（不计入评分）",
        notConfirmed: "未确认",
        unknown: "未知状态",
      },
      columns: {
        dealNumber: "交易号",
        type: "类型",
        amount: "数量",
        participant: "交易参与者",
        paymentMethod: "支付方式",
        status: "状态",
        action: "操作",
      },
      new: "新",
      youBuy: "我购买",
      youSell: "我出售",
      for: "支付",
      rate: "汇率",
      sendTo: "将发送至",
      receiveTo: "将收款至",
      acceptDeal: {
        title: "确认交易",
        timeLeft: "剩余时间",
      },
      actions: {
        accept: "接受",
        decline: "拒绝",
        open: "打开",
      },
      buyer: "买家",
      seller: "卖家",
      merchantStats: {
        completed: "已完成",
        rating: "评分",
      },
      paymentMethods: {
        notSpecified: "未指定",
        cash: "现金",
        bankAccount: "银行账户",
        unknown: "未知方式",
      },
      noDeals: "您还没有任何交易",
      errors: {
        loadingOrders: "加载订单时出错",
        acceptOrder: "接受订单失败",
        declineOrder: "拒绝订单失败",
      },
    },
    deal: {
      type: {
        buy: {
          label: "购买",
          description: "您正在购买代币",
          receive: "您将收到到您的加密钱包",
          send: "您将发送给卖家",
        },
        sell: {
          label: "出售",
          description: "您正在出售代币",
          send: "您将从加密钱包发送代币",
          receive: "买家将发送给您",
        },
      },
      paymentDetails: {
        whereToSend: "发送给卖家至",
        whereToReceive: "接收至",
      },
      priceDetails: {
        pricePerUnit: "单价",
        currencyNotSpecified: "未指定货币",
      },
    },
  },

  ja: {
    history: {
      filterStatus: "タスクステータス",
      filters: {
        current: "現在",
        completed: "完了",
      },
      dealStatus: {
        createDeal: "取引を作成",
        creating: "作成中",
        inProcess: "進行中",
        completed: "完了",
        dispute: "申し立て中",

        cancelled: "キャンセル",
        cancelledNoRating: "評価なしでキャンセル",
        notConfirmed: "未確認",
        unknown: "不明なステータス",
      },
      columns: {
        dealNumber: "取引番号",
        type: "タイプ",
        amount: "金額",
        participant: "取引参加者",
        paymentMethod: "支払方法",
        status: "ステータス",
        action: "アクション",
      },
      new: "新規",
      youBuy: "購入する",
      youSell: "売却する",
      for: "対価",
      rate: "レート",
      sendTo: "送金先",
      receiveTo: "受取先",
      acceptDeal: {
        title: "取引を確認",
        timeLeft: "残り時間",
      },
      actions: {
        accept: "承認",
        decline: "拒否",
        open: "開く",
      },
      buyer: "購入者",
      seller: "販売者",

      merchantStats: {
        completed: "完了",
        rating: "評価",
      },
      paymentMethods: {
        notSpecified: "未指定",
        cash: "現金",
        bankAccount: "銀行口座",
        unknown: "不明な方法",
      },
      noDeals: "取引はまだありません",
      errors: {
        loadingOrders: "注文の読み込みエラー",
        acceptOrder: "注文の承認に失敗",
        declineOrder: "注文の拒否に失敗",
      },
    },
    deal: {
      type: {
        buy: {
          label: "購入",
          description: "コインを購入します",
          receive: "暗号資産ウォレットで受け取ります",
          send: "販売者に送金します",
        },
        sell: {
          label: "売却",
          description: "コインを売却します",
          send: "暗号資産ウォレットからコインを送信します",
          receive: "購入者から受け取ります",
        },
      },
      paymentDetails: {
        whereToSend: "販売者への送金先",
        whereToReceive: "受取先",
      },
      priceDetails: {
        pricePerUnit: "1単位あたりの価格",
        currencyNotSpecified: "通貨未指定",
      },
    },
  },
};

export default messages;
