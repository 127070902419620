const messages = {
  ru: {
    myAds: {
      noAds: "У вас пока нет объявлений",
      turnOffAll: "Выключить все",
      turnOnAll: "Запустить все",
      createAd: "Создать объявление",
      myListings: "Мои объявления",
      edit: "Редактировать",
      delete: "Удалить",
      thead: {
        status: "Статус",
        type: "Тип",
        price: "Цена",
        paymentMethod: "Способ оплаты",
        limitsAvailable: "Доступно | Лимиты",
        action: "Действие",
      },
      status: {
        active: "Активно",
        inactive: "Выкл.",
      },
      type: {
        buy: "Покупка",
        sell: "Продажа",
      },
      priceFor: "Цена за 1",
      available: "Доступно",
      limits: "Лимиты",
      noLimitsData: "Нет данных о лимитах",
      paymentMethods: {
        title: "Способы оплаты",
        banks: "Банки",
        cash: "Наличными",
        noCash: "Наличные",
        bankAccount: "Расчетный счет",
        noMethods: "Нет методов оплаты",
      },
      confirmDelete: "Вы уверены, что хотите удалить это объявление?",
    },
  },
  en: {
    myAds: {
      noAds: "You don't have any advertisements yet",
      turnOffAll: "Turn Off All",
      turnOnAll: "Turn On All",
      createAd: "Create Advertisement",
      myListings: "My Listings",
      edit: "Edit",
      delete: "Delete",
      thead: {
        status: "Status",
        type: "Type",
        price: "Price",
        paymentMethod: "Payment Method",
        limitsAvailable: "Available | Limits",
        action: "Action",
      },
      status: {
        active: "Active",
        inactive: "Off",
      },
      type: {
        buy: "Buy",
        sell: "Sell",
      },
      priceFor: "Price per 1",
      available: "Available",
      limits: "Limits",
      noLimitsData: "No limits data",
      paymentMethods: {
        title: "Payment Methods",
        banks: "Banks",
        cash: "Cash",
        noCash: "Cash",
        bankAccount: "Bank Account",
        noMethods: "No payment methods",
      },
      confirmDelete: "Are you sure you want to delete this advertisement?",
    },
  },
  zh: {
    myAds: {
      noAds: "您还没有广告",
      turnOffAll: "全部关闭",
      turnOnAll: "全部启动",
      createAd: "创建广告",
      myListings: "我的广告",
      edit: "编辑",
      delete: "删除",
      thead: {
        status: "状态",
        type: "类型",
        price: "价格",
        paymentMethod: "支付方式",
        limitsAvailable: "可用额度 | 限额",
        action: "操作",
      },
      status: {
        active: "启用",
        inactive: "关闭",
      },
      type: {
        buy: "购买",
        sell: "出售",
      },
      priceFor: "单价",
      available: "可用",
      limits: "限额",
      noLimitsData: "无限额数据",
      paymentMethods: {
        title: "支付方式",
        banks: "银行",
        cash: "现金",
        noCash: "现金",
        bankAccount: "银行账户",
        noMethods: "无支付方式",
      },
      confirmDelete: "您确定要删除此广告吗？",
    },
  },
  ja: {
    myAds: {
      noAds: "まだ広告がありません",
      turnOffAll: "すべてオフ",
      turnOnAll: "すべてオン",
      createAd: "広告を作成",
      myListings: "マイ広告",
      edit: "編集",
      delete: "削除",
      thead: {
        status: "ステータス",
        type: "タイプ",
        price: "価格",
        paymentMethod: "支払方法",
        limitsAvailable: "利用可能 | 制限",
        action: "アクション",
      },
      status: {
        active: "有効",
        inactive: "オフ",
      },
      type: {
        buy: "購入",
        sell: "売却",
      },
      priceFor: "1あたりの価格",
      available: "利用可能",
      limits: "制限",
      noLimitsData: "制限データなし",
      paymentMethods: {
        title: "支払方法",
        banks: "銀行",
        cash: "現金",
        noCash: "現金",
        bankAccount: "銀行口座",
        noMethods: "支払方法なし",
      },
      confirmDelete: "この広告を削除してもよろしいですか？",
    },
  },
};

export default messages;
