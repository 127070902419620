<template>
  <div class="pt-[15px] border-b">
    <h2
      class="flex items-center justify-start mb-[12px] pl-[45px] desktoponly:pl-[30px] text-[14px] desktoponly:text-[12px] text-nowrap text-[#797979]/50"
    >
      <span class="flex items-center">
        <ArticleTradingIcon class="w-6 h-6 mr-1.5" />
        {{ $t("articleroute.stockMarket") }}
      </span>
    </h2>
    <ul
      class="flex flex-col items-start justify-between font-[300] text-[14px] desktoponly:text-[12px]"
    >
      <li class="text-nowrap w-full">
        <ArticleTradingSpot
          class="pl-[65px] w-full desktoponly:pl-[40px] transition duration-300 ease-in-out py-[7px] hover:bg-gray-100 cursor-pointer"
          :class="[
            route.name === 'spot' ? 'bg-gray-100 text-[#000]' : 'text-[#000]',
          ]"
          @click="navigateTo('spot')"
        />
      </li>
      <li class="flex items-center">
        <ArticleTradingFuchers
          class="flex pl-[65px] desktoponly:pl-[40px] py-[7px] w-full transition duration-300 ease-in-out cursor-not-allowed text-[#797979]"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import ArticleTradingFuchers from "./ArticleTradingFuchers.vue";
import ArticleTradingSpot from "./ArticleTradingSpot.vue";
import ArticleTradingIcon from "./icons/ArticleTradingIcon.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const navigateTo = (name) => {
  router.push({ name: name }).catch((err) => {
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  });
};
</script>
