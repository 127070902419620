const messages = {
  ru: {
    aml: {
      title: "Политика AML/KYC",
      lastUpdate: "Последнее обновление: 01.10.2024",
      warning:
        "В РАМКАХ РЕАЛИЗАЦИИ КОЛЛЕКТИВНЫХ МЕР, НАПРАВЛЕННЫХ НА ПРОТИВОДЕЙСТВИЕ ЛЕГАЛИЗАЦИИ (ОТМЫВАНИЮ) ДОХОДОВ, ПОЛУЧЕННЫХ ПРЕСТУПНЫМ ПУТЕМ, ФИНАНСИРОВАНИЮ ТЕРРОРИЗМА И РАСПРОСТРАНЕНИЮ ОРУЖИЯ МАССОВОГО УНИЧТОЖЕНИЯ ВЫ СОГЛАШАЕТЕСЬ, ЧТО РЕГИСТРИРУЯСЬ НА САЙТЕ И ПОЛЬЗУЯСЬ ПРОДУКТАМИ/СЕРВИСАМИ/УСЛУГАМИ КОМПАНИИ, ВЫ ОБЯЗУЕТЕСЬ ПРЕДОСТАВИТЬ НАМ ВСЮ ИНФОРМАЦИЮ И ПОМОЩЬ, КОТОРАЯ МОЖЕТ ПОТРЕБОВАТЬСЯ ДЛЯ СОБЛЮДЕНИЯ ЗАКОНОВ О ПРОТИВОДЕЙСТВИИ ЛЕГАЛИЗАЦИИ (ОТМЫВАНИЮ) ПРЕСТУПНЫХ ДОХОДОВ, ФИНАНСИРОВАНИЮ ТЕРРОРИЗМА И РАСПРОСТРАНЕНИЯ ОРУЖИЯ МАССОВОГО УНИЧТОЖЕНИЯ.",

      generalTitle: "Общие положения",
      generalOne:
        "Компания UmbrellaX, (также именуемая «Компания», «Сервис», «мы», «наш», «наши»,), осуществляет свою деятельность в строгом соответствии с международными и национальными стандартами, разработанными в целях противодействия легализации (отмыванию) доходов, полученных преступным путем, финансированию терроризма и распространению оружия массового уничтожения и, в частности:",
      one: {
        one: "Рекомендациями ФАТФ (Группы разработки финансовых мер по борьбе с отмыванием денег);",

        two: "Законом Сейшельских островов о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма 2020 г. и последующих поправок к нему;",

        three:
          "Положениями о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма 2020 г. и последующих поправок к ним;",

        four: "Законом о предотвращении терроризма 2004 г. и последующих поправок к нему, и любыми другими нормативными актами и/или циркулярами и/или руководствами, время от времени издаваемыми Управлением по финансовым услугам Сейшельских островов.",
      },
      generalTwo:
        "Компания предостерегает Пользователей от попыток использования продуктов/сервисов/услуг Компании в целях легализации денежных средств, полученных преступным путем, финансирования терроризма, распространения оружия массового уничтожения, мошенничества, покупки запрещенных товаров и услуг, незаконных действий любого иного рода. Также Компания предостерегает Пользователей от попыток сокрытия информации, связывающей их с санкционными списками юридических и физических лиц, стран и иной.",

      generalThree:
        "Политика AML/KYC составлена Компанией в соответствии с международными и национальными стандартами в целях предотвращения легализации (отмывания) доходов, полученных преступным путем, финансирования терроризма, торговли наркотиками и людьми, предотвращения распространения оружия массового уничтожения, коррупции и взяточничества, а также своевременного принятия мер в случае любой подозрительной деятельности со стороны Пользователей Сайта https://umbrellax.io/ («Сайт») и/или продуктов/сервисов/услуг Компании. Политика AML/KYC устанавливает системы контроля и механизмов для предотвращения вовлечения Компании и/или продуктов/сервисов/услуг Компании в легализацию (отмывание) доходов, полученных преступным путем, и/или в деятельность по финансированию терроризма, распространение оружия массового уничтожения, и/или нарушение установленных санкционных ограничений, сохранение и поддержание репутации Компании при взаимодействии с Пользователями, контрагентами и представителями уполномоченных органов.",

      generalFour:
        "Данная Политика AML/KYC описывает основные стандарты, принципы, правила и подходы, используемые Компанией для изучения Пользователей и контрагентов, проведения мероприятий по управлению рисками отмывания (легализации) доходов, полученных преступным путем, финансирования терроризма, распространения оружия массового уничтожения, санкций и стран.",

      generalFive:
        "Для выполнения процедур, предусмотренных данной Политикой AML/KYC, Компания разрабатывает и внедряет внутреннюю систему оценки уровня рисков Пользователя и их операций, определяет минимально необходимый набор требований, процедур, механизмов, отчетов, систем и мер контроля для управления рисками Компании. Такие документы соответствуют данной Политике AML/KYC, однако являются конфиденциальной информацией Компании с ограниченным доступом. ",

      generalSix:
        "Для выполнения процедур, предусмотренных данной Политикой AML/KYC, Компания имеет право пользоваться услугами сторонних поставщиков.",

      generalSeven:
        "Для Пользователей и операций с высоким риском применяются более жесткие процедуры.",

      generalEight:
        "Компания обеспечивает выполнение требований данной Политики AML/KYC и применимого законодательства всеми сотрудниками Компании.",

      generalNine:
        "Предотвращение легализации (отмывания) доходов, полученных преступным путем, финансирования терроризма и распространения оружия массового уничтожения является одной из основных обязанностей и целей Компании. В рамках указанной деятельности Компания гарантирует, что:",
      oneNine: {
        one: "идентифицирует своих Пользователей и/или контрагентов и гарантирует, что они действительно являются теми лицами, за которых себя выдают, путем запроса документа, удостоверяющего личность, содержащего все необходимые реквизиты;",

        two: "идентифицирует и проверяет постоянный адрес Пользователей и/или контрагентов путем предоставления недавно выданного документа;",

        three:
          "осуществляет мониторинг операций и деловых отношений для выявления необычных и/или подозрительных транзакций;",

        four: "собирает, оценивает и изучает информацию, связанную с подозрительными транзакциями, и сообщает о подозрительных транзакциях в соответствующие компетентные органы;",
        five: "осуществляет постоянный мониторинг всех сообщений о подозрительной деятельности и тесно сотрудничает с соответствующими компетентными органами. сохраняет копии документов и информации, которые необходимы для соблюдения требований должной осмотрительности, подтверждающие доказательства и записи о транзакциях, необходимые для идентификации транзакций и подтверждающие записи о переписке с Пользователями и/или контрагентами и другими лицами, с которыми поддерживаются деловые отношения, в течение минимум 5 лет после окончания деловых отношений между Компанией и Пользователем и/или контрагентом или после даты совершения разовой сделки;",
        six: "обеспечивает, чтобы все сотрудники были полностью осведомлены о своих юридических обязательствах в соответствии с Законом о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма, и были достаточно обучены соответствующим процедурам KYC и AML, а также тому, что является подозрительной деятельностью и процессу внутреннего информирования о такой деятельности Ответственного сотрудника Компании.",
        seven:
          "использует все имеющиеся ресурсы и информацию для обеспечения расследования всех подозрительных действий.",
      },
      generalTen:
        "Данная Политика является частью Пользовательского соглашения. Принимая условия Пользовательского соглашения, вы автоматически соглашаетесь с Политикой AML/KYC.",
      generalEleven:
        "Пожалуйста, внимательно прочитайте указанную Политику AML/KYC. По всем вопросам, связанным с настоящей Политикой AML/KYC, вы можете связаться с нами через следующий адрес электронной почты support@umbrellax.io.",
      generalTwelve:
        "Согласно Политике AML/KYC, слова «Сайт», «Компания», «мы», «нам» или «наш» относятся к компании UmbrellaX, включая, без ограничений, ее владельцев, директоров, инвесторов, сотрудников и других относящихся к ней лиц. Политика AML/KYC является неотъемлемой частью Пользовательского соглашения. Принимая условия Пользовательского соглашения, вы автоматически соглашаетесь с данной Политикой AML/KYC.",
      generalThirteen:
        "Любое использование термина «Пользователь», «Вы», «Ваш» и т.д. должно пониматься как ссылка на Пользователя Сайта и/или продуктов/сервисов/услуг Компании, подпадающего под действие настоящей Политики AML/KYC, Пользовательского соглашения, Политики конфиденциальности.",
      term: "Основные определения",
      termAll:
        "Все термины, указанные в настоящей Политике AML/KYC заглавными буквами и не имеющие иных определений имеют те же значения, что и в Пользовательском соглашении.",
      termOne:
        "Ответственный сотрудник — лицо, чья обязанность заключается в обеспечении эффективного внедрения и обеспечения соблюдения Политики AML/KYC.",
      termTwo:
        "Легализация доходов, полученных преступным путем — сокрытие незаконного источника денежных средств путем перевода их в денежные средства или инвестиции, которые выглядят законно.",
      termThree:
        "Запрещенное поведение — любое незаконное поведение, которое включает мошенничество, коррупцию, отмывание денег, сговор, финансирование терроризма и любое другое преступное поведение.",
      termFour:
        "Мошенничество — использование обмана с целью преследования личных интересов и нанесения ущерба интересам Пользователей и/или Компании по способу хищения чужого имущества либо приобретение на него права путем обмана.",
      termFive:
        "Скам — способ обмана, мошенничества, чтобы получить деньги от пользователей интернета, может включать в себя скрытие информации или предоставление неверной информации с целью вымогательства у жертв денег, имущества и наследства.",
      termSix:
        "Коррупция — предложение, предоставление, получение или ходатайство, прямо или косвенно, чего-либо ценного, что могло бы оказать ненадлежащее влияние на действия другой стороны.",
      termSeven:
        "Отмывание денег — схема финансовых транзакций, целью которой является сокрытие личности, источника и места назначения незаконно полученных денег или финансирование незаконной деятельности. Это действия, направленные на сокрытие или маскировку истинного происхождения доходов, полученных преступным путем, и дальнейшее придание их получению законного характера.",
      termEight:
        "Противодействие отмыванию денег (Anti Money laundering / AML) — комплекс мероприятий и процедур, направленных на выявление и/или предотвращение использования Компании, ее Сайта и предоставляемых ею продуктов/сервисов/услуг в целях отмывания денег.      ",
      termNine:
        "Противодействие финансированию терроризма (Counter-terrorist financing / CTF) — комплекс мероприятий и процедур, направленных на выявление и/или предотвращение использования Компании, ее Сайта или предоставляемых ею продуктов/сервисов/услуг в целях финансирования терроризма.",
      termTen:
        "Санкции — Экономические санкции —коммерческие и финансовые санкции, применяемые одной или несколькими странами против целевых самоуправляющихся государств, групп или отдельных лиц.",
      termEleven:
        "«Красные флаги» — предупреждение или индикатор, предполагающий наличие потенциальной проблемы или угрозы с операцией Пользователя, которые проходят через Компанию, ее Сайт и/или в ходе использования продуктов/сервисов/услуг Компании.",
      termTwelve:
        "Сговор — договоренность между двумя или более сторонами, направленная на достижение ненадлежащей цели, включая неправомерное влияние на действия другой стороны.",
      termThirteen:
        "Транзакция — транзакция с любыми цифровыми активами, которая производится Пользователем через Сайт.",
      termFourteen:
        "Финансирование терроризма — предоставление или сбор средств для целей планирования или совершения действий, признаваемых террористическими актами, или для финансирования операций террористических организаций, или при условии, что выделенные или собранные средства будут использованы для вышеупомянутых целей.",
      termFifteen:
        "Преступное поведение — преступление в любой части мира или то, что будет квалифицироваться как преступление в любой части мира, если оно произошло там.",
      termSixteen:
        "Знай Своего Клиента ( Know Your Client / KYC) — комплекс мероприятий и процедур, направленных на получение информации о клиенте и его деятельности с целью управления рисками компании.",
      termSeventeen:
        "Надлежащая комплексная проверка клиентов (Customer Due Diligence / CDD) — проверка данных/информации о Пользователе и другие проверки, связанные с изучением Пользователя и его деятельности, с целью комплексной оценки риска Пользователя при принятии его на обслуживание и во время его обслуживания.",
      termEighteen:
        "Политически значимое лицо (Politically exposed person / РЕР) — физическое лицо, играющее выдающуюся общественную роль внутри той или иной страны, или на международном уровне.",
      termNineteen:
        "Нормативные требования — означают любой применимый закон, статут, постановление, приказ, судебное решение, решение, рекомендацию, правило, политику (в том числе, но не исключительно Политику KYC/AML, включающую также меры, направленные на борьбу с финансированием терроризма и распространением оружия массового уничтожения) или руководство, принятые или изданные парламентом, правительством или любым компетентным судом или органом власти, или любой платежной системой (включая, помимо прочего, банковские платежные системы, карточные платежные системы, такие как Visa, MasterCard, или любая другая платежная, клиринговая или расчетная система, или аналогичное соглашение, которое используется для предоставления продуктов/сервисов/услуг).",
      politically: "Ответственный за соблюдение Политики",
      politicallyOne:
        "Для контроля и реализации процедур, отраженных в Политике AML/KYC, Компания назначает Ответственного сотрудника.",
      politicallyTwo:
        "Ответственный сотрудник определяет процедуры и правила для идентификации Пользователей, проверки и мониторинга необычных транзакций. Обязанностью Ответственного сотрудника является контроль всех аспектов настоящей Политики, в том числе:",
      politicallyThree: "сбор персональных данных Пользователя;",
      politicallyFour:
        "создание и обновление внутренних политик и процедур для завершения, рассмотрения, предоставления и хранения всех отчетов и записей, требуемых в соответствии с действующими законами и нормами;",
      politicallyFive:
        "мониторинг действий Пользователя и исследование существенных отклонений от стандартной деятельности;      ",
      politicallySix:
        "внедрение системы управления записями для соответствующего хранения и поиска документов, файлов, форм и журналов;",
      politicallySeven: "регулярная актуализация оценки рисков;",
      politicallyEight:
        "предоставление правоохранительным органам необходимой информации в соответствии с действующими законами и нормами.",
      politicallyNine:
        "Ответственный сотрудник является контактным лицом Компании при взаимодействии с правоохранительными органами, в целях предотвращения отмывания денег, финансирования терроризма и другой незаконной деятельности.",
      rightsOne: "Права компании в ходе реализации Политики AML/KYC",
      rightsTwo:
        "Личность Пользователя устанавливается путем его идентификации, а также путем анализа шаблонности его действий. Компания использует анализ данных как инструмент оценки рисков и выявления подозрительной активности. Для целей выполнения существующих обязательств Компания осуществляет сбор и систематизацию данных, ведение записей, управление процессами сбора сведений и предоставление отчетности.",
      rightsThree:
        "В соответствии с настоящей Политикой AML/KYC Компания обязуется отслеживать все действия и оставляет за собой право:",
      rightsFour:
        "взаимодействовать с правоохранительными органами, которые занимаются предотвращением отмывания денег, финансирования терроризма и другой незаконной деятельности, сообщать правоохранительным органам о действиях подозрительного характера;",
      rightsFive:
        "запрашивать у Пользователя какую-либо дополнительную информацию или документы в случае возникновения подозрительных действий; полностью или частично приостанавливать доступ Пользователя к учетной записи на Сайте в случае наличия обоснованных подозрений в причастности Пользователя к незаконной деятельности.",
      rightsSix:
        "Представленный перечень является неполным, отслеживание действий Пользователей будет осуществляться Ответственным сотрудником в целях определения того, относятся ли действия Пользователя к подозрительным, и стоит ли о них сообщать правоохранительным органам или нет.",
      verification: "Процедура верификации",
      verificationOne:
        "Надлежащая проверка Пользователей является одним из международных стандартов предотвращения незаконной деятельности. Компания устанавливает свои собственные процедуры верификации в рамках настоящей Политики AML/KYC.",
      verificationTwo:
        "Ответственный сотрудник уполномочен требовать от Пользователей предоставления документов, подтверждающих их личность.",
      verificationThree:
        "Для прохождения процедуры верификации Пользователю необходимо предоставить Ответственному сотруднику официальные документы от независимых источников или информацию (например, национальное удостоверение личности, международный паспорт, выписку из банка, счет за коммунальные услуги). С целью соблюдения настоящей Политики AML/KYC Ответственный сотрудник оставляет за собой право запрашивать персональные данные Пользователя, а также предпринимать шаги для подтверждения подлинности документов и информации, предоставляемой Пользователем. Ответственный сотрудник оставляет за собой право собирать сведения о Пользователе, которые были классифицированы как опасные или подозрительные.",
      verificationFour:
        "Ответственный сотрудник не уполномочен и не обязан устанавливать факт того, является ли документ, предоставленный Пользователем для идентификации, легальным. Однако в случае очевидных несоответствий в полученной информации Ответственный сотрудник вправе потребовать от Пользователя предоставить дополнительные документы для идентификации его личности. Пользователь обязан предоставить запрашиваемые данные в течение 5 дней с момента запроса. В случае если Пользователь отказывается предоставлять требуемую информацию, когда это необходимо, или пытается ввести в заблуждение Ответственного сотрудника, данному Пользователю может быть отказано в предоставлении услуг.",
      verificationFive:
        "Ответственный сотрудник оставляет за собой право осуществлять проверку личности Пользователя на постоянной основе, особенно если Пользователь изменил персональные данные или если его деятельность показалась подозрительной (нехарактерной для конкретного Пользователя). Кроме того, Ответственный сотрудник оставляет за собой право запрашивать у Пользователей обновленные документы, даже если ранее они прошли верификацию.      ",
      verificationSix:
        "Сбор, хранение, разглашение и защита персональных данных Пользователя будут осуществляться строго в соответствии с Политикой конфиденциальности и только в той мере, в какой это необходимо для обеспечения надлежащего оказания услуг Пользователям.",
      verificationSeven:
        "Как только личность Пользователя установлена, Ответственный сотрудник может отказаться от потенциальной юридической ответственности в ситуации, если предоставленные продукты/сервисы/услуги Компании используются Пользователем для осуществления незаконной деятельности.",
      verificationEight:
        "Если Ответственным сотрудником будут обнаружены признаки подозрительной деятельности Пользователя, которая может быть связана с легализацией (отмыванием) доходов, полученных преступным путем, финансированием терроризма, торговлей наркотиками и людьми, распространением оружия массового уничтожения, коррупцией и взяточничеством, информация о ней будет доведена до ведома компетентных органов без обязательства получения одобрения или предварительного уведомления Пользователя.",

      verificationNine:
        "Ответственный сотрудник ориентируется на списки, опубликованные местными властями и международными организациями, в которых содержатся имена лиц, подозреваемых в террористической деятельности, террористических организаций, стран с высоким уровнем риска, ограниченный список стран, подпадающих под санкции ООН, США, ЕС, а также высокорисковые юрисдикции, которые не обеспечивают достаточный уровень процедур по борьбе с отмыванием денег, чтобы определить, включен ли Пользователь и или страна юрисдикции такого Пользователя в вышеуказанные списки.",

      verificationTen:
        "Компания собирает личную информацию от Пользователей только в той мере, в какой это необходимо для обеспечения надлежащего оказания услуг Пользователям. Такая личная информация о Пользователях и бывших Пользователях может быть передана третьим лицам только при ограниченном числе обстоятельств в соответствии с применимыми законами и/или запросами компетентных органов или в порядке, предусмотренном Пользовательским соглашением и/или Политикой конфиденциальности.      ",

      verificationEleven:
        "Компания имеет право приостановить действие учетной записи, связанной с подозрительной деятельностью (в том числе, но не исключительно деятельностью, которая может быть определена как легализация (отмывание) доходов, полученных преступным путем, финансирование терроризма, скам и т.д.), и попросить владельца такой учетной записи пройти углубленную процедуру KYC (запросить дополнительные необходимые документы). В случае если Пользователь не предоставит необходимые документы или предоставленных документов будет недостаточно для снятия подозрения в подозрительной деятельности, Компания имеет право приостановить обслуживание учетной записи / операций Пользователя временно или на неопределенное время, в том числе до полного блокирования учетной записи и блокировки всех активов.",

      verificationTwelve:
        "Компания оставляет за собой право получать дополнительную информацию о Пользователях, которые были определены, как Пользователи повышенного риска. Также, если идентификационная информация была изменена или действия Пользователя показались Компании подозрительными, последняя имеет право запросить у Пользователя документы повторно, даже если Пользователь уже прошел проверку ранее.",

      risks: "Оценка рисков",
      risksOne:
        "Для выполнения своих обязательств по борьбе с отмыванием денег и противодействию финансированию терроризма, Компания проводит ежегодную оценку рисков отмывания денег, полученных преступным путем. Цель оценки рисков — не допустить, чтобы Сайт и/или продукты/сервисы/услуги Компании использовались для отмывания денег, выделяя риски и оценивая меры контроля, установленные Компанией. Подход, основанный на оценке риска, используется для идентификации Пользователей и отслеживания того, как они пользуются Сайтом и/или продуктами/сервисами/услугами Компании.      ",

      risksTwo:
        "Ответственный сотрудник отвечает за управление рисками финансовых преступлений и внесение улучшений в управление рисками финансовых преступлений путем выявления общих и конкретных рисков отмывания денег, с которыми сталкивается Компания, установления того, как эти риски смягчаются средствами существующего контроля Компании в данной сфере и определения остаточного риска, который остается за Компанией.",

      risksThree:
        "Любая финансовая операция, которая может быть связана с легализацией (отмыванием) доходов, полученных преступным путем, финансированием терроризма, нарушением санкционных ограничений, скам, считается подозрительной.",

      risksFour:
        "Компания самостоятельно разрабатывает и внедряет механизм выявления таких операций, систему и определения «красных флагов», критерии определения рисков. Основанием для определения того, что конкретная транзакция является подозрительной, могут быть личные наблюдения и опыт сотрудников Компании, информация, полученная во время процедур KYC, информация, полученная с применением специализированных аналитических программ и/или систем и т.д.",

      risksFive:
        "Компания на регулярной основе отслеживает транзакционную деятельность своих Пользователей, обновляет системы и критерии «красных флагов», используемые для обнаружения подозрительных действий, внедряет лучшие международные практики для выявления подозрительных действий.      ",

      risksSix:
        "В соответствии с применимыми действующими законами и требованиями компетентных международных организаций, Компания может, где это уместно, и без обязательства получения одобрения или уведомления Пользователя, уведомлять регулирующие и / или правоохранительные органы о любых подозрительных операциях, а также предоставлять необходимую информацию в ответ на запросы таких организаций.",
      risksSeven:
        "При проведении изучения Пользователей и анализа их операций Компания использует следующие списки:",

      risksEight:
        "санкционных лиц, известных террористов и / или террористических организаций, или лиц, подозреваемых в террористической деятельности, опубликованные местными властями и международными организациями — OFAC (Office of Foreign Assets Control), ЕС, ООН и т.д.,",

      risksNine:
        "юрисдикций, которые не обеспечивают достаточный уровень процедур по борьбе с отмыванием денег, в соответствии с политиками FATF, а также страны, на которые распространяются санкции OFAC, ЕС, ООН и т.д.,",
      risksTen:
        "стран с высоким уровнем риска, чтобы определить, включен ли Пользователь или потенциальный Пользователь и / или страна / юрисдикции такого Пользователя в вышеуказанные списки, сотрудничество с которым запрещено или нежелательно.",

      risksEleven:
        "Компания постоянно проводит процедуры должной осмотрительности в отношении своих Пользователей и тщательно проверяет проводимые ими транзакции, чтобы обеспечить совместимость этих транзакций с KYC, их бизнеса и, при необходимости, их источнике доходов.",
      risksTwelve:
        "Компания не устанавливает отношения с Пользователями, которые внесены в санкционные списки или зарегистрированные / расположенные в запрещенных территориях / юрисдикциях или находятся под контролем таких лиц.",

      risksThirteen:
        "При установлении у Пользователя статуса неприемлемо высокого риска Компания может отказать такому Пользователю в дальнейшем обслуживании.",
      risksFourteen:
        "Компания также сохраняет за собой право при обнаружении подозрительных операций запрашивать у Пользователя дополнительные документы, приостанавливать или прекращать действие учетной записи Пользователя, приостанавливать оборот или замораживать активы Пользователя до выяснения обстоятельств, и осуществлять иные действия соизмеримые с выявленными рисками.      ",

      thirdPartiesOne: "Третьи стороны",
      thirdPartiesTwo:
        "Для выполнения некоторых своих бизнес-функций Компания может привлекать сторонних поставщиков услуг или взаимодействовать с контрагентами. Компания прикладывает все возможные усилия, чтобы изучить такого поставщика услуг / контрагента и его деятельность, а также определить, насколько это возможно, его репутацию (есть ли какие-либо инициированные расследования и иски против любых таких сторонних поставщиков услуг). Компания также определяет, получил ли сторонний поставщик все необходимые лицензии, разрешения и одобрения, прежде чем устанавливать деловые отношения с таким сторонним поставщиком услуг.",
      thirdPartiesThree:
        "Компания не устанавливает отношения с поставщиком услуг и/или контрагентом, которые внесены в санкционные списки или зарегистрированы/расположены в запрещенных территориях/юрисдикциях или которые находятся под контролем таких лиц.",
      changesOne: "Изменение Политики AML/KYC",
      changesTwo:
        "Компания оставляет за собой право по своему усмотрению и в любое время вносить изменения в настоящую Политику AML/KYC (по мере выявления и идентификации новых рисков, внедрению новых продуктов/услуг/сервисов изменений в применимом законодательстве) и осуществлять контроль за соблюдением ее положений и требований.",
      changesThree:
        "Актуальная версия Политики AML/KYC публикуется на Сайте https://umbrellax.io/",
      changesFour:
        "Вы признаете и соглашаетесь с тем, что вы несете ответственность за периодическую проверку Политики AML/KYC и ознакомление с изменениями и дополнениями.",
      changesFive:
        "В случае несогласия с каким-либо изменениями Политики AML/KYC, вы должны немедленно прекратить использование Сайта и/или продуктов/сервисов/услуг Компании. Ваше дальнейшее использование Сайта и/или продуктов/сервисов/услуг Компании после публикации изменений в Политике AML/KYC будет рассматриваться как ваше согласие с этими изменениями.",
      liabilitiesOne: "Обязательства Пользователя",
      liabilitiesTwo:
        "Используя Сайт и/или продукты/сервисы/услуги Компании, вы гарантируете, что не собираетесь совершать какие-либо запрещенные действия, описанные в данном документе. Кроме того, вы соглашаетесь на любые проверки, связанные с проведением расследования в соответствии с Политикой AML/KYC, а также соглашаетесь полностью и оперативно сотрудничать с Ответственным сотрудником и иными уполномоченными лицами в рамках такого расследования. Отказ от сотрудничества или непредставление необходимых сведений/документов может послужить основанием для приостановления вашего обслуживания или полного отказа от дальнейшего обслуживания.",
    },
  },
  en: {},
  zh: {},
  ja: {},
};

export default messages;
