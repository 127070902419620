<template>
  <button
    class="flex justify-between items-center px-1.5 py-1 rounded w-[80px] h-6 mobile:h-[22px] mobile:text-[10px] mobile:w-[70px] text-xs leading-tight text-white bg-[#0098ea] hover:bg-[#0098ea]/75 w-[65px] shadow mobile:!justify-center"
    :class="{ 'w-[194px] h-[30px]': showSlot || showSlotReg }"
  >
    <TelegramIcon class="mobile:w-[12px] mobile:h-[12px]" />
    <p class="">
      <slot v-if="showSlot"><span>{{ $t("login.entertg") }}</span></slot>
      <slot v-if="showSlotReg"><span>{{ $t("login.registrationtg") }}</span></slot>
      Telegram
    </p>
  </button>
</template>

<script setup>
import TelegramIcon from "@/components/icons/TelegramIcon.vue";
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const props = defineProps({
  showSlot: {
    type: Boolean,
    default: false,
  },
  showSlotReg: {
    type: Boolean,
    default: false,
  },
});
</script>
