const messages = {
  ru: {
    p2p: {
      common: {
        loading: "Загрузка...",
        max: "MAX.",
      },
      deal: {
        buttons: {
          buy: "Купить",
          buyDeal: "Создать сделку",
          createDeal: "Создание сделки...",
          showDetails: "Показать детали сделки",
          backToDeal: "Назад к сделке",
          cancelDeal: "Отменить сделку",
          backToDeal: "Назад к сделке",
          backToDeal: "Назад к сделке",
          backToDeal: "Назад к сделке",
          backToDeal: "Назад к сделке",
        },
        form: {
          price: "Цена за",
          willTransfer: "Я переведу продавцу на его реквизиты",
          willReceive: "Я получу на свой криптокошелек",
          paymentMethod: "Оплачу продавцу на",
          selectPayment: "Выберите метод оплаты",
          errors: {
            maxAmount: "Максимальная сумма транзакции составляет",
            minAmount: "Минимальная сумма транзакции составляет",
          },
        },
        details: {
          orderNumber: "Номер заявки",
          youBuyer: "Вы покупаете у продавца",
          title: "Детали сделки с продавцом",
          available: "У продавца в наличии",
          limits: "Лимиты",
          paymentTime: "Время на оплату",
          create: "Создайте сделку, если согалсны",
          completeDeal: "Завершите создание сделки в течение",
          warning: "Внимание",
          warningDesq:
            "Если не согласны с деталями сделки, то можно её отменить (на этом этапе на рейтинг не влияет)",
          youPay: "Вы отдаете",
          priceOne: "Цена за 1",
          payTime: "Длительность оплаты",
          timeUnit: "Способ оплаты",
          timeUnit: "мин",
          timeUnit: "мин",
          timeUnit: "мин",
          timeUnit: "мин",
          timeUnit: "мин",
          acceptedPayments: "Продавец принимает на:",
        },
        warning: {
          warUp: "Только мошенники предлагают общение",
          warDown: "и проведение сделок вне P2P Маркета",
        },
        comment: {
          sellerComment: "Комментарий от продавца:",
          yourComment: "Ваш комментарий:",
          buyerComment: "Комментарий покупателя: ",
          title: "Комментарий ",
        },
      },
    },
  },
  en: {}, // English translations will be added later
  zh: {}, // Chinese translations will be added later
  ja: {}, // Japanese translations will be added later
};

export default messages;
