<template>
  <div class="relative">
    <span class="absolute top-[-2px] right-[-22px] text-[10px] text-[#FF6663]">
      {{ $t("articleroute.skoro") }}
    </span>
    <button class="flex items-center gap-x-[5px] justify-start">
      <span>{{ $t("articleroute.dailyTasks") }}</span>
      <!-- <PresentNew class="max-[1439px]:w-6 max-[1439px]:h-6" /> -->
    </button>
  </div>
</template>

<script setup>
import PresentNew from "./PresentNew.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
