const messages = {
  ru: {
    currency: {
      loading: "Загрузка...",
      noCurrencies: "Нет доступных валют",
      selectCurrency: "Выберите валюту",
      unknown: "Unknown",
      crypto: {
        buyAction: "Куплю",
        sellAction: "Продаю",
        cryptoSuffix: "эту криптовалюту",
      },
      fiat: {
        buyAction: "Оплачу",
        sellAction: "Принимаю",
        prefix: "в",
      },
    },
    price: {
      type: "Тип цены",
      fixed: "Фиксированная",
      yourRate: "Ваш курс",
      buy: "покупки",
      sell: "продажи",
      perOne: "за 1",
      marketRate: "Рыночный курс:",
      loading: "Загрузка...",
      input: {
        placeholder: "Введите сумму",
        ratePlaceholder: "Курс {action} за 1 {crypto}",
      },
      validation: {
        error:
          "Цена не может быть ниже чем {min} {currency} и выше чем {max} {currency}",
      },
    },
    amount: {
      coinCount: {
        buy: "Кол-во монет к покупке",
        sell: "Кол-во монет к продаже",
      },
      balance: {
        label: "Ваш баланс",
        value: "{amount} {currency}",
      },
      input: {
        placeholder: "10 - 1 000 000",
      },
      minMax: {
        title: "Мин. и макс. сумма сделки",
        validation: {
          minError: "Цена не может быть ниже 500 {currency}",
          maxError: "Цена не может быть ниже 500 {currency}",
        },
        placeholderMin: "Мин",
        placeholderMax: "Макс (необязательно)",
      },
    },
    paymentTime: {
      time: {
        label: "Время на оплату",
        format: {
          minutes: "{minutes} мин",
          hours: "{hours} ч",
        },
      },
      methods: {
        buy: "Могу оплатить продавцу на",
        sell: "Принимаю оплату на",
        select: "Выберите способы оплаты",
        limit: "До 5 способов одного типа",
        validation: {
          maxTypeLimitReached: "Достигнут лимит для метода {methodId} ({max})",
          cashCityExists:
            "Метод оплаты наличными уже выбран для города {cityId}",
          bankExists: "Расчетный счет для банка {bank} уже выбран",
          methodExists: "Метод оплаты {methodId} уже выбран",
          maxUniqueLimitReached:
            "Достигнут лимит уникальных методов оплаты ({max})",
        },
      },
    },
    comment: {
      label: "Комментарий (необязательно)",
      placeholder: "Введите сообщение",
    },
    createAd: {
      title: "Создать объявление",
      editTitle: "Редактировать объявление",

      back: "Назад",
      deleteAd: "Удалить объявление",
      editAd: "Редактировать объявление",
      saving: "Сохранение...",
      creating: "Создание...",
      saveChanges: "Сохранить изменения",
      createAd: "Создать объявление",

      deleteConfirmation: "Вы уверены, что хотите удалить это объявление?",

      generalError:
        "Не удалось создать объявление. Пожалуйста, попробуйте еще раз.",
      updateError:
        "Не удалось обновить объявление. Пожалуйста, попробуйте еще раз.",
      deleteError:
        "Не удалось удалить объявление. Пожалуйста, попробуйте еще раз.",
      loadError: "Не удалось загрузить данные объявления",
      maxAdsError:
        "Вы достигли максимального количества объявлений для этой криптовалюты или уже создали объявление этого типа.",

      selectCrypto: "Пожалуйста, выберите криптовалюту",
      selectNational: "Пожалуйста, выберите национальную валюту",
      selectPrice: "Пожалуйста, выберите цену",
      selectAmount: "Пожалуйста, выберите сумму",
      selectPaymentTime: "Пожалуйста, выберите время оплаты",
      selectPaymentMethod: "Пожалуйста, выберите хотя бы один способ оплаты",
      maxAmountError: "Максимальная сумма должна быть больше минимальной",
      amountRangeError:
        "Пожалуйста, укажите корректные минимальную и максимальную суммы (не менее 500)",
    },
  },
  en: {
    currency: {
      loading: "Loading...",
      noCurrencies: "No currencies available",
      selectCurrency: "Select currency",
      unknown: "Unknown",
      crypto: {
        buyAction: "I buy",
        sellAction: "I sell",
        cryptoSuffix: "this cryptocurrency",
      },
      fiat: {
        buyAction: "I pay",
        sellAction: "I accept",
        prefix: "in",
      },
    },
    price: {
      type: "Price type",
      fixed: "Fixed",
      yourRate: "Your rate",
      buy: "buy",
      sell: "sell",
      perOne: "per 1",
      marketRate: "Market rate:",
      loading: "Loading...",
      input: {
        placeholder: "Enter amount",
        ratePlaceholder: "{action} rate per 1 {crypto}",
      },
      validation: {
        error:
          "Price cannot be lower than {min} {currency} and higher than {max} {currency}",
      },
    },
    amount: {
      coinCount: {
        buy: "Coins to buy",
        sell: "Coins to sell",
      },
      balance: {
        label: "Your balance",
        value: "{amount} {currency}",
      },
      input: {
        placeholder: "10 - 1,000,000",
      },
      minMax: {
        title: "Min and max deal amount",
        validation: {
          minError: "Price cannot be lower than 500 {currency}",
          maxError: "Price cannot be lower than 500 {currency}",
        },
        placeholderMin: "Min",
        placeholderMax: "Max (optional)",
      },
    },
    paymentTime: {
      time: {
        label: "Payment time",
        format: {
          minutes: "{minutes} min",
          hours: "{hours} h",
        },
      },
      methods: {
        buy: "I can pay the seller via",
        sell: "I accept payments via",
        select: "Select payment methods",
        limit: "Up to 5 methods of one type",
        validation: {
          maxTypeLimitReached: "Limit reached for method {methodId} ({max})",
          cashCityExists:
            "Cash payment method already selected for city {cityId}",
          bankExists: "Bank account for {bank} already selected",
          methodExists: "Payment method {methodId} already selected",
          maxUniqueLimitReached: "Unique payment methods limit reached ({max})",
        },
      },
    },
    comment: {
      label: "Comment (optional)",
      placeholder: "Enter message",
    },
    createAd: {
      title: "Create Advertisement",
      editTitle: "Edit Advertisement",
      back: "Back",
      deleteAd: "Delete advertisement",
      editAd: "Edit advertisement",
      saving: "Saving...",
      creating: "Creating...",
      saveChanges: "Save changes",
      createAd: "Create advertisement",
      deleteConfirmation: "Are you sure you want to delete this advertisement?",
      generalError: "Failed to create advertisement. Please try again.",
      updateError: "Failed to update advertisement. Please try again.",
      deleteError: "Failed to delete advertisement. Please try again.",
      loadError: "Failed to load advertisement data",
      maxAdsError:
        "You have reached the maximum number of ads for this cryptocurrency or already created an ad of this type.",
      selectCrypto: "Please select cryptocurrency",
      selectNational: "Please select national currency",
      selectPrice: "Please select price",
      selectAmount: "Please select amount",
      selectPaymentTime: "Please select payment time",
      selectPaymentMethod: "Please select at least one payment method",
      maxAmountError: "Maximum amount must be greater than minimum",
      amountRangeError:
        "Please specify correct minimum and maximum amounts (not less than 500)",
    },
  },
  zh: {
    currency: {
      loading: "加载中...",
      noCurrencies: "无可用货币",
      selectCurrency: "选择货币",
      unknown: "未知",
      crypto: {
        buyAction: "我购买",
        sellAction: "我出售",
        cryptoSuffix: "这种加密货币",
      },
      fiat: {
        buyAction: "我支付",
        sellAction: "我接受",
        prefix: "以",
      },
    },
    price: {
      type: "价格类型",
      fixed: "固定价格",
      yourRate: "您的汇率",
      buy: "购买",
      sell: "出售",
      perOne: "每1个",
      marketRate: "市场汇率：",
      loading: "加载中...",
      input: {
        placeholder: "输入金额",
        ratePlaceholder: "每1个{crypto}的{action}汇率",
      },
      validation: {
        error: "价格不能低于{min}{currency}且不能高于{max}{currency}",
      },
    },
    amount: {
      coinCount: {
        buy: "购买币数",
        sell: "出售币数",
      },
      balance: {
        label: "您的余额",
        value: "{amount} {currency}",
      },
      input: {
        placeholder: "10 - 1,000,000",
      },
      minMax: {
        title: "最小和最大交易金额",
        validation: {
          minError: "价格不能低于500 {currency}",
          maxError: "价格不能低于500 {currency}",
        },
        placeholderMin: "最小值",
        placeholderMax: "最大值（可选）",
      },
    },
    paymentTime: {
      time: {
        label: "支付时间",
        format: {
          minutes: "{minutes}分钟",
          hours: "{hours}小时",
        },
      },
      methods: {
        buy: "我可以通过以下方式向卖家付款",
        sell: "我接受以下付款方式",
        select: "选择支付方式",
        limit: "每种类型最多5种方式",
        validation: {
          maxTypeLimitReached: "已达到{methodId}方式的限制（{max}）",
          cashCityExists: "已为{cityId}选择现金支付方式",
          bankExists: "已选择{bank}的银行账户",
          methodExists: "已选择支付方式{methodId}",
          maxUniqueLimitReached: "已达到唯一支付方式的限制（{max}）",
        },
      },
    },
    comment: {
      label: "备注（可选）",
      placeholder: "输入消息",
    },
    createAd: {
      title: "创建广告",
      editTitle: "编辑广告",
      back: "返回",
      deleteAd: "删除广告",
      editAd: "编辑广告",
      saving: "保存中...",
      creating: "创建中...",
      saveChanges: "保存更改",
      createAd: "创建广告",
      deleteConfirmation: "您确定要删除此广告吗？",
      generalError: "创建广告失败。请重试。",
      updateError: "更新广告失败。请重试。",
      deleteError: "删除广告失败。请重试。",
      loadError: "加载广告数据失败",
      maxAdsError: "您已达到此加密货币的最大广告数量或已创建此类型的广告。",
      selectCrypto: "请选择加密货币",
      selectNational: "请选择法定货币",
      selectPrice: "请选择价格",
      selectAmount: "请选择金额",
      selectPaymentTime: "请选择支付时间",
      selectPaymentMethod: "请至少选择一种支付方式",
      maxAmountError: "最大金额必须大于最小金额",
      amountRangeError: "请指定正确的最小和最大金额（不少于500）",
    },
  },
  ja: {
    currency: {
      loading: "読み込み中...",
      noCurrencies: "利用可能な通貨がありません",
      selectCurrency: "通貨を選択",
      unknown: "不明",
      crypto: {
        buyAction: "購入します",
        sellAction: "売却します",
        cryptoSuffix: "この暗号通貨を",
      },
      fiat: {
        buyAction: "支払います",
        sellAction: "受け取ります",
        prefix: "で",
      },
    },
    price: {
      type: "価格タイプ",
      fixed: "固定価格",
      yourRate: "あなたのレート",
      buy: "購入",
      sell: "売却",
      perOne: "1あたり",
      marketRate: "市場レート：",
      loading: "読み込み中...",
      input: {
        placeholder: "金額を入力",
        ratePlaceholder: "1 {crypto}あたりの{action}レート",
      },
      validation: {
        error:
          "価格は{min} {currency}より低く、{max} {currency}より高くすることはできません",
      },
    },
    amount: {
      coinCount: {
        buy: "購入するコイン数",
        sell: "売却するコイン数",
      },
      balance: {
        label: "あなたの残高",
        value: "{amount} {currency}",
      },
      input: {
        placeholder: "10 - 1,000,000",
      },
      minMax: {
        title: "最小・最大取引額",
        validation: {
          minError: "価格は500 {currency}より低くすることはできません",
          maxError: "価格は500 {currency}より低くすることはできません",
        },
        placeholderMin: "最小額",
        placeholderMax: "最大額（任意）",
      },
    },
    paymentTime: {
      time: {
        label: "支払い時間",
        format: {
          minutes: "{minutes}分",
          hours: "{hours}時間",
        },
      },
      methods: {
        buy: "売り手に以下で支払い可能",
        sell: "以下での支払いを受け付けます",
        select: "支払い方法を選択",
        limit: "1タイプにつき最大5つの方法",
        validation: {
          maxTypeLimitReached: "方法{methodId}の制限に達しました（{max}）",
          cashCityExists: "都市{cityId}の現金支払い方法は既に選択されています",
          bankExists: "{bank}の銀行口座は既に選択されています",
          methodExists: "支払い方法{methodId}は既に選択されています",
          maxUniqueLimitReached: "一意の支払い方法の制限に達しました（{max}）",
        },
      },
    },
    comment: {
      label: "コメント（任意）",
      placeholder: "メッセージを入力",
    },
    createAd: {
      title: "広告を作成",
      editTitle: "広告を編集",
      back: "戻る",
      deleteAd: "広告を削除",
      editAd: "広告を編集",
      saving: "保存中...",
      creating: "作成中...",
      saveChanges: "変更を保存",
      createAd: "広告を作成",
      deleteConfirmation: "この広告を削除してもよろしいですか？",
      generalError: "広告の作成に失敗しました。もう一度お試しください。",
      updateError: "広告の更新に失敗しました。もう一度お試しください。",
      deleteError: "広告の削除に失敗しました。もう一度お試しください。",
      loadError: "広告データの読み込みに失敗しました",
      maxAdsError:
        "この暗号通貨の広告数が上限に達しているか、このタイプの広告を既に作成しています。",
      selectCrypto: "暗号通貨を選択してください",
      selectNational: "法定通貨を選択してください",
      selectPrice: "価格を選択してください",
      selectAmount: "金額を選択してください",
      selectPaymentTime: "支払い時間を選択してください",
      selectPaymentMethod: "少なくとも1つの支払い方法を選択してください",
      maxAmountError: "最大金額は最小金額より大きくなければなりません",
      amountRangeError: "正しい最小・最大金額を指定してください（500以上）",
    },
  },
};

export default messages;
