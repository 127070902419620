const messages = {
  ru: {
    buttoncap: {
      buycap: "Купить",
      sellcap: "Продать",
      sumcap: "Сумма сделки",
      nofiat: "Валюты не найдены",
      paycap: "Все способы оплаты",
      paydelete: "Сбросить",
      sumCap: "Введите сумму",
      payGood: "Подтвердить",
      search: "Поиск",
      merchanttooltip: "Показать только проверенных мерчантов?",
      settingcap: "Обновлять страницу?",
      setcapno: "Нет",
      setcapfive: "Каждые 5 с",
      setcapten: "Каждые 10 с",
      selected: "Выбрано",
      selectCity: "Выберите город",
    },
  },
  en: {
    buttoncap: {
      buycap: "Buy",
      sellcap: "Sell",
      sumcap: "Transaction Amount",
      nofiat: "No currencies found",
      paycap: "All Payment Methods",
      paydelete: "Reset",
      sumCap: "Enter amount",
      payGood: "Confirm",
      search: "Search",
      merchanttooltip: "Show verified merchants only?",
      settingcap: "Refresh page?",
      setcapno: "No",
      setcapfive: "Every 5 s",
      setcapten: "Every 10 s",
      selected: "Selected",
      selectCity: "Select city",
    },
  },
  zh: {
    buttoncap: {
      buycap: "购买",
      sellcap: "出售",
      sumcap: "交易金额",
      nofiat: "没有找到货币",
      paycap: "所有支付方式",
      paydelete: "重置",
      sumCap: "输入金额",
      payGood: "确认",
      search: "搜索",
      merchanttooltip: "只显示已验证商家？",
      settingcap: "刷新页面？",
      setcapno: "否",
      setcapfive: "每5秒",
      setcapten: "每10秒",
      selected: "已选择",
      selectCity: "选择城市",
    },
  },
  ja: {
    buttoncap: {
      buycap: "購入",
      sellcap: "売却",
      sumcap: "取引金額",
      nofiat: "通貨が見つかりません",
      paycap: "すべての支払方法",
      paydelete: "リセット",
      sumCap: "金額を入力",
      payGood: "確認",
      search: "検索",
      merchanttooltip: "認証済み業者のみ表示しますか？",
      settingcap: "ページを更新しますか？",
      setcapno: "いいえ",
      setcapfive: "5秒ごと",
      setcapten: "10秒ごと",
      selected: "選択済み",
      selectCity: "都市を選択",
    },
  },
};

export default messages;
