import { defineStore } from "pinia";
import { $axios, apiList } from "../api";

export const useRateStore = defineStore("rate", {
  state: () => ({
    cryptoRates: {},
    fiatRates: {},
    fixedRates: {},
    loading: false,
    error: null,
    balances: {},
    lastUpdateTime: null,
    updateInterval: null,
    pendingPromise: null,
  }),

  actions: {
    setBalances(balances) {
      this.balances = balances || {};
    },

    fetchAllRates() {
      if (this.pendingPromise) {
        return this.pendingPromise;
      }

      this.loading = true;
      this.error = null;

      this.pendingPromise = $axios
        .request({
          url: apiList.rates.getActual.url,
          method: apiList.rates.getActual.method,
        })
        .then((response) => {
          this.cryptoRates = response.data.crypto_rates;
          this.fiatRates = response.data.fiat_rates;
          this.fixedRates = response.data.fixed_rates;
          this.lastUpdateTime = new Date().getTime();
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching rates:", error);
          this.error = "Failed to fetch rates";
          throw error;
        })
        .finally(() => {
          this.loading = false;
          this.pendingPromise = null;
        });

      return this.pendingPromise;
    },

    startAutoUpdate() {
      this.stopAutoUpdate();
      this.updateInterval = setInterval(() => {
        if (this.isStale && !this.pendingPromise) {
          this.fetchAllRates();
        }
      }, 10000);
    },

    stopAutoUpdate() {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
      }
    },

    getCryptoUsdRate(crypto) {
      const currentRate = this.cryptoRates?.fiat?.[crypto]?.USD || 0;
      const fixedRate = this.fixedRates?.crypto_rates?.fiat?.[crypto]?.USD || 0;

      const rate = currentRate;
      if (fixedRate === 0) return rate;

      const changePercent = ((currentRate - fixedRate) / fixedRate) * 100;
      return {
        rate: currentRate,
        change: `${changePercent > 0 ? "+" : ""}${changePercent.toFixed(2)}%`,
      };
    },

    getFixedCryptoUsdRate(crypto) {
      return this.fixedRates?.crypto_rates?.fiat?.[crypto]?.USD || 0;
    },

    getPriceChangeFromFixed(crypto) {
      const currentPrice = this.getCryptoUsdRate(crypto);
      const fixedPrice = this.getFixedCryptoUsdRate(crypto);

      if (currentPrice === 0 || fixedPrice === 0) return "0.00%";

      const changePercent = ((currentPrice - fixedPrice) / fixedPrice) * 100;
      return `${changePercent > 0 ? "+" : ""}${changePercent.toFixed(2)}%`;
    },

    isPriceIncreasedFromFixed(crypto) {
      const currentPrice = this.getCryptoUsdRate(crypto);
      const fixedPrice = this.getFixedCryptoUsdRate(crypto);

      if (currentPrice === fixedPrice) return null;
      return currentPrice > fixedPrice;
    },
  },

  getters: {
    cryptoUsdRates: (state) => {
      const rates = {};
      if (state.cryptoRates?.fiat) {
        Object.keys(state.cryptoRates.fiat).forEach((crypto) => {
          rates[crypto] = state.cryptoRates.fiat[crypto].USD || 0;
        });
      }
      return rates;
    },

    balancesInFiat: (state) => (selectedFiat) => {
      if (!state.balances) return [];

      return Object.entries(state.balances).map(([ticker, amount]) => {
        const currentRate =
          state.cryptoRates?.fiat?.[ticker]?.[selectedFiat] || 0;
        const fixedRate =
          state.fixedRates?.crypto_rates?.fiat?.[ticker]?.[selectedFiat] || 0;
        const fiatBalance = amount * currentRate;

        let percentChange = "0.00%";
        let priceIncreased = null;

        if (fixedRate !== 0) {
          const changePercent = ((currentRate - fixedRate) / fixedRate) * 100;
          percentChange = `${
            changePercent > 0 ? "+" : ""
          }${changePercent.toFixed(2)}%`;
          priceIncreased = currentRate > fixedRate;
        }

        return {
          ticker,
          amount,
          fiatBalance,
          fiatTicker: selectedFiat,
          pricePerCoin: currentRate,
          priceIncreased,
          percentChange,
          lastUpdate: state.lastUpdateTime,
        };
      });
    },

    totalBalance: (state) => (selectedFiat) => {
      if (!state.balances) return 0;

      const total = Object.entries(state.balances).reduce(
        (sum, [ticker, amount]) => {
          const rate = state.cryptoRates?.fiat?.[ticker]?.[selectedFiat] || 0;
          return sum + amount * rate;
        },
        0
      );

      return parseFloat(total.toFixed(2));
    },

    isStale: (state) => {
      if (!state.lastUpdateTime) return true;
      const staleThreshold = 10000;
      return new Date().getTime() - state.lastUpdateTime > staleThreshold;
    },
  },
});
