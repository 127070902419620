<template>
  <div class="flex flex-col gap-[8px] pt-[12px] pb-[12px]">
    <router-link
      :to="{ name: 'userProfilePersonal' }"
      class="block flex gap-[8px] py-1 font-[500] text-[14px] text-[#797979] hover:bg-gray-100 px-[22px]"
      @click="$emit('menuItemClicked')"
    >
      <ProfileMenuIcon />
      {{ $t("profilemenu.personalData") }}
    </router-link>
    <router-link
      :to="{ name: 'userProfileP2P' }"
      class="block flex gap-[8px] py-1 font-[500] text-[14px] text-[#797979] hover:bg-gray-100 px-[22px]"
      @click="$emit('menuItemClicked')"
    >
      <ProfileMenuP2P />
      {{ $t("profilemenu.p2pProfile") }}
    </router-link>
    <router-link
      :to="{ name: 'userProfileSecurity' }"
      class="block flex gap-[8px] py-1 font-[500] text-[14px] text-[#797979] hover:bg-gray-100 px-[22px]"
      @click="$emit('menuItemClicked')"
    >
      <ProfileMenuSec />
      {{ $t("profilemenu.security") }}
    </router-link>
    <router-link
      :to="{ name: 'userProfileReferral' }"
      class="block flex gap-[8px] py-1 font-[500] text-[14px] text-[#797979] hover:bg-gray-100 px-[22px]"
      @click="$emit('menuItemClicked')"
    >
      <ProfileMenuRef />
      {{ $t("profilemenu.referral") }}
    </router-link>
    <router-link
      :to="{ name: 'userProfilePresents' }"
      class="block flex gap-[8px] py-1 font-[500] text-[14px] text-[#797979] hover:bg-gray-100 px-[22px]"
      @click="$emit('menuItemClicked')"
    >
      <ProfileMenuPresent />
      {{ $t("profilemenu.presents") }}
    </router-link>
    <a
      href="#"
      class="block flex gap-[8px] py-1 font-[500] text-[14px] text-[#FF6663] hover:bg-gray-100 px-[22px]"
      @click.prevent="
        $emit('logout');
        $emit('menuItemClicked');
      "
    >
      <ProfileMenuExit />
      {{ $t("profilemenu.logout") }}
    </a>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import ProfileMenuIcon from "@/components/icons/ProfileMenuIcon.vue";
import ProfileMenuP2P from "@/components/icons/ProfileMenuP2P.vue";
import ProfileMenuSec from "@/components/icons/ProfileMenuSec.vue";
import ProfileMenuRef from "@/components/icons/ProfileMenuRef.vue";
import ProfileMenuPresent from "@/components/icons/ProfileMenuPresent.vue";
import ProfileMenuExit from "@/components/icons/ProfileMenuExit.vue";

const router = useRouter();

defineEmits(["logout", "menuItemClicked"]);

const goToProfile = () => {
  router.push({ name: "userProfilePersonal" });
};
</script>
