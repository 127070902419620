<template>
  <TransitionGroup
    tag="div"
    name="notification-list"
    class="fixed top-[5px] right-[13%] z-[99991] w-[390px] mobile:top-0 mobile:right-0 mobile:left-0 mobile:w-full"
  >
    <div
      class="notification-stack-container relative mobile:w-full"
      :class="{
        'h-0': !visiblePopupNotifications.length,
        'h-[90px]': visiblePopupNotifications.length,
      }"
      :key="'stack-container'"
    >
      <NotificationItem
        v-for="(notification, index) in visiblePopupNotifications"
        :key="notification.id"
        :notification="notification"
        :isPopup="true"
        :style="getNotificationStyle(index)"
        class="notification-item bg-[#fff]/95 absolute top-0 left-0 w-full transition-all duration-300 mobile:w-full mobile:bg-white"
        @touchstart="handleTouchStart($event, notification)"
        @touchmove="handleTouchMove($event, notification)"
        @touchend="handleTouchEnd(notification)"
      />
    </div>
  </TransitionGroup>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useNotificationStore } from "@/stores/notificationStore";
import { useUserStore } from "@/stores/userStore";
import NotificationItem from "./NotificationItem.vue";
import { shouldPlaySound } from "@/helpers/notificationHelper";
import { playCloseNotificationSound } from "@/helpers/soundHelper";
import { shouldShowNotification } from "@/helpers/notificationHelper";

const notificationStore = useNotificationStore();
const userStore = useUserStore();

let hideTimeout = null;
let lastActivityTime = Date.now();

const touchStartY = ref(0);
const touchCurrentY = ref(0);
const swipingNotificationId = ref(null);
const SWIPE_THRESHOLD = -50;

const visiblePopupNotifications = computed(() => {
  const userId = userStore.user?.id;
  return notificationStore.popupNotifications
    .filter((notification) => shouldShowNotification(notification, userId))
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
});

const getNotificationStyle = (index) => {
  const isMobile = window.innerWidth < 768;
  const baseStyle = {
    zIndex: 1000 - index,
    position: "absolute",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  };

  if (
    swipingNotificationId.value === visiblePopupNotifications.value[index]?.id
  ) {
    const translateY = Math.min(0, touchCurrentY.value - touchStartY.value);
    baseStyle.transform = `translateY(${translateY}px)`;
    baseStyle.opacity = 1 + translateY / 200;
  } else if (index === 0) {
    baseStyle.top = "0px";
  } else {
    const stackIndex = index - 1;
    const offset = isMobile ? 1 : 2;
    baseStyle.transform = `translate(${stackIndex * offset}px, ${
      stackIndex * offset
    }px)`;
    baseStyle.opacity = Math.max(1 - stackIndex * 0.2, 0.5);
  }

  return baseStyle;
};

const handleTouchStart = (event, notification) => {
  if (window.innerWidth >= 768) return;
  touchStartY.value = event.touches[0].clientY;
  swipingNotificationId.value = notification.id;
};

const handleTouchMove = (event, notification) => {
  if (window.innerWidth >= 768) return;
  touchCurrentY.value = event.touches[0].clientY;

  if (swipingNotificationId.value === notification.id) {
    event.preventDefault();
  }
};

const handleTouchEnd = (notification) => {
  if (window.innerWidth >= 768) return;

  const swipeDistance = touchCurrentY.value - touchStartY.value;

  if (swipeDistance < SWIPE_THRESHOLD) {
    notificationStore.closePopupNotification(notification.id);
  }

  touchStartY.value = 0;
  touchCurrentY.value = 0;
  swipingNotificationId.value = null;
};

const resetHideTimeout = () => {
  clearTimeout(hideTimeout);
  hideTimeout = setTimeout(() => {
    if (Date.now() - lastActivityTime > 5000) {
      notificationStore.closeAllPopupNotifications();
    }
  }, 5000);
};

const onUserActivity = () => {
  lastActivityTime = Date.now();
  resetHideTimeout();
};

onMounted(() => {
  window.addEventListener("mousemove", onUserActivity);
  window.addEventListener("keydown", onUserActivity);

  watch(
    visiblePopupNotifications,
    (newValue) => {
      if (newValue.length > 0) {
        resetHideTimeout();
        const latestNotification = newValue[0];
        if (shouldPlaySound(latestNotification, userStore.user?.id)) {
          playCloseNotificationSound();
        }
      }
    },
    { immediate: true }
  );
});

onUnmounted(() => {
  window.removeEventListener("mousemove", onUserActivity);
  window.removeEventListener("keydown", onUserActivity);
  clearTimeout(hideTimeout);
});
</script>

<style scoped>
.notification-stack-container {
  perspective: 1000px;
  transition: height 0.3s ease;
}

.notification-item {
  backface-visibility: hidden;
  transform-origin: top center;
  touch-action: pan-x; /* Разрешаем только горизонтальный панинг */
}

.notification-list-enter-active,
.notification-list-leave-active {
  transition: all 0.5s ease;
}

.notification-list-leave-to,
.notification-list-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.notification-list-move {
  transition: transform 0.5s ease;
}

@media (max-width: 768px) {
  .notification-item {
    margin: 0 auto;
    width: 100%;
  }

  .notification-stack-container {
    padding: 0.5rem;
  }
}
</style>
