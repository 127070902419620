const messages = {
  ru: {
    sidebar: {
      title: "Политики и соглашения",
      links: {
        amlKycPolicy: "Политика AML/KYC",
        cookies: "Политика использования Cookies",
        userAgreement: "Пользовательское соглашение",
        privacyPolicy: "Политика Конфиденциальности",
      },
      govTitle: "Для представителей органов власти",
      goHome: "На главную страницу",
    },
  },
  en: {
    sidebar: {
      title: "Policies and Agreements",
      links: {
        amlKycPolicy: "AML/KYC Policy",
        cookies: "Cookie Policy",
        userAgreement: "User Agreement",
        privacyPolicy: "Privacy Policy",
      },
      govTitle: "For Government Representatives",
      goHome: "Back to Home Page",
    },
  },
  zh: {
    sidebar: {
      title: "政策和协议",
      links: {
        amlKycPolicy: "AML/KYC政策",
        cookies: "Cookie使用政策",
        userAgreement: "用户协议",
        privacyPolicy: "隐私政策",
      },
      govTitle: "政府代表专用",
      goHome: "返回主页",
    },
  },
  ja: {
    sidebar: {
      title: "ポリシーと規約",
      links: {
        amlKycPolicy: "AML/KYCポリシー",
        cookies: "Cookie使用ポリシー",
        userAgreement: "利用規約",
        privacyPolicy: "プライバシーポリシー",
      },
      govTitle: "行政機関担当者向け",
      goHome: "ホームページへ戻る",
    },
  },
};

export default messages;
