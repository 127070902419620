<template>
  <div class="relative">
    <button @click="showHistory" class="w-full text-left flex items-center">
      <span class="relative inline-flex items-center">
        {{ $t("articleroute.mydeal") }}
        <span
          v-if="currentDealsCount > 0"
          class="absolute -top-1 -right-4 bg-[#06C6FF] text-white text-[8px] font-[700] rounded-full w-[12px] h-[12px] flex items-center justify-center"
        >
          {{ currentDealsCount }}
        </span>
      </span>
    </button>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useNavigationStore } from "../stores/marketStore";
import { useOrdersStore } from "../stores/ordersStore";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const navigationStore = useNavigationStore();
const ordersStore = useOrdersStore();

const { currentDealsCount } = storeToRefs(ordersStore);

function showHistory() {
  navigationStore.setView("history");
  router.push({ name: "history" }).catch((err) => {
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  });
}
</script>
