<template>
  <Transition name="fade">
    <div v-if="show" class="loading-bar-wrapper">
      <div
        class="loading-bar"
        :style="{
          width: `${progress}%`,
          transition: `width ${transitionSpeed}ms ease-out`,
        }"
      ></div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const progress = ref(0);
const show = ref(false);
const transitionSpeed = 300;
let timeoutIds = [];

const startLoading = () => {
  show.value = true;
  progress.value = 0;

  timeoutIds.push(
    setTimeout(() => {
      progress.value = 90;
    }, 50)
  );
};

const completeLoading = () => {
  progress.value = 100;

  timeoutIds.push(
    setTimeout(() => {
      show.value = false;
      progress.value = 0;
    }, transitionSpeed + 100)
  );
};

watch(
  () => props.isLoading,
  (newValue) => {
    if (newValue) {
      startLoading();
    } else {
      completeLoading();
    }
  }
);

onUnmounted(() => {
  timeoutIds.forEach((id) => clearTimeout(id));
});

onMounted(() => {
  router.beforeEach((to, from, next) => {
    startLoading();
    next();
  });

  router.afterEach(() => {
    completeLoading();
  });

  router.onError(() => {
    completeLoading();
  });
});
</script>

<style scoped>
.loading-bar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 9999;
}

.loading-bar {
  height: 100%;
  background: linear-gradient(90deg, #2196f3, #42a5f5);
  box-shadow: 0 0 8px rgba(33, 150, 243, 0.5);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
