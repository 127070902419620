<template>
  <div class="flex items-center justify-center min-h-screen">
    <div class="text-center">
      <p>{{ $t("auth.google.processing") }}</p>
      <p>{{ $t("auth.google.wait") }}</p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useSocialAuthStore } from "@/stores/socialAuthStore";
import { completeAuthHandling } from "@/router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const socialAuthStore = useSocialAuthStore();

onMounted(() => {
  const { code, scope, authuser, prompt } = route.query;

  if (!code) {
    completeAuthHandling();
    router.replace({ name: "landing" });
    return;
  }

  return socialAuthStore
    .registrationUsingSocial("google", {
      code,
      scope,
      authuser,
      prompt,
    })
    .then((response) => {
      completeAuthHandling();
      router.push("/p2p");
    })
    .catch((error) => {
      console.error("Auth failed:", error);
      completeAuthHandling();
      router.push({
        name: "main",
        query: { form: "login", error: "Authentication failed" },
      });
    });
});
</script>
