import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useUserStore } from "@/stores/userStore";

export const useFilterStore = defineStore("filter", () => {
  const filterMode = ref("buy");
  const selectedCryptoCurrency = ref(null);
  const selectedFiatCurrency = ref(6);
  const price = ref("");
  const selectedPaymentMethods = ref([]);
  const isMerchantOnly = ref(false);
  const refreshOption = ref("no");
  const filteredPosts = ref([]);
  const currentPage = ref(1);
  const itemsPerPage = ref(100);

  const userStore = useUserStore();

  const CASH_PAYMENT_METHOD_ID = 1;

  const applyFilters = (posts) => {
    if (posts) {
      if (isMerchantOnly.value) {
        const merchantPromises = posts.map((post) => {
          return userStore
            .getUserById(post.user_id)
            .then((userData) => ({
              ...post,
              isMerchant: userData?.status === "merchant",
            }))
            .catch((error) => {
              console.error("Failed to fetch user data:", error);
              return {
                ...post,
                isMerchant: false,
              };
            });
        });

        return Promise.all(merchantPromises).then((postsWithMerchantData) => {
          applyAllFilters(postsWithMerchantData);
        });
      } else {
        applyAllFilters(posts);
        return Promise.resolve();
      }
    }
    setCurrentPage(1);
    return Promise.resolve();
  };

  const applyAllFilters = (postsToFilter) => {
    filteredPosts.value = postsToFilter.filter((post) => {
      if (isMerchantOnly.value && !post.isMerchant) return false;

      if (filterMode.value === "buy" && post.type !== "sell") return false;
      if (filterMode.value === "sell" && post.type !== "buy") return false;

      if (
        !post.currency_from_id ||
        Number(post.currency_from_id) !== selectedCryptoCurrency.value
      )
        return false;

      if (
        !post.currency_to_id ||
        Number(post.currency_to_id) !== selectedFiatCurrency.value
      )
        return false;

      if (price.value && parseFloat(post.price) > parseFloat(price.value)) {
        return false;
      }

      if (selectedPaymentMethods.value.length > 0) {
        const hasMatchingMethod = selectedPaymentMethods.value.some(
          (selectedMethod) => {
            return post.payment_methods.some((postMethod) => {
              if (selectedMethod.payment_method_id === CASH_PAYMENT_METHOD_ID) {
                return (
                  postMethod.payment_method_id === CASH_PAYMENT_METHOD_ID &&
                  selectedMethod.extra_data?.city_id ===
                    postMethod.extra_data?.city_id
                );
              }

              return (
                postMethod.payment_method_id ===
                selectedMethod.payment_method_id
              );
            });
          }
        );

        if (!hasMatchingMethod) return false;
      }

      return true;
    });

    filteredPosts.value.sort(
      (a, b) => parseFloat(a.price) - parseFloat(b.price)
    );
  };

  const setFilterMode = (mode) => {
    filterMode.value = mode;
  };

  const setSelectedCryptoCurrency = (currencyId) => {
    selectedCryptoCurrency.value = Number(currencyId);
  };

  const setSelectedFiatCurrency = (currencyId) => {
    selectedFiatCurrency.value = Number(currencyId);
  };

  const setPrice = (value) => {
    price.value = value;
  };

  const setSelectedPaymentMethods = (methods) => {
    selectedPaymentMethods.value = methods;
  };

  const toggleMerchantOnly = (value) => {
    isMerchantOnly.value = value;
  };

  const setRefreshOption = (option) => {
    refreshOption.value = option;
  };

  const setCurrentPage = (page) => {
    currentPage.value = page;
  };

  const getPaginatedFilteredPosts = computed(() => {
    const start = (currentPage.value - 1) * itemsPerPage.value;
    const end = start + itemsPerPage.value;
    return filteredPosts.value.slice(start, end);
  });

  const totalFilteredPages = computed(() => {
    return Math.ceil(filteredPosts.value.length / itemsPerPage.value);
  });

  const initializeFilters = () => {
    return Promise.resolve();
  };

  return {
    filterMode,
    selectedCryptoCurrency,
    selectedFiatCurrency,
    price,
    selectedPaymentMethods,
    isMerchantOnly,
    refreshOption,
    currentPage,
    setFilterMode,
    setSelectedCryptoCurrency,
    setSelectedFiatCurrency,
    setPrice,
    setSelectedPaymentMethods,
    toggleMerchantOnly,
    setRefreshOption,
    setCurrentPage,
    applyFilters,
    getPaginatedFilteredPosts,
    totalFilteredPages,
    initializeFilters,
    filteredPosts,
  };
});
