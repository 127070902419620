const messages = {
  ru: {
    copy: {
      copy: "Скопировать",
      copyEnd: "Скопировано",
    },
    buttonheader: {
      profile: "Профиль",
      notification: "Уведомления",
    },
    profilemenu: {
      login: "Логин",
      wallet: "Кошелёк",
      id: "ID",
      balance: "Общий баланс",
      status: "Статус",
      verification: "Верифицирован",
      personalData: "Личные данные",
      p2pProfile: "P2P профиль",
      security: "Безопасность",
      referral: "Реферальная программа",
      presents: "Подарки",
      logout: "Выход",
      beginner: "Новичок",
      buyVip: "Купить VIP",
      support: "По всем вопросам",
      policies: "Ознакомьтесь со всеми",
      policiesLink: "Политиками и соглашениями",
    },
    verification: {
      pending: "Верификация на рассмотрении",
      rejected: "Верификация отклонена",
      reviewing: "На рассмотрении",
      start: "Пройти верификацию",
    },
    banner: {
      myads: "Мои объявления",
      settings: "Настройки оплаты",
      mydeals: "История сделок",
      spot: "Спот",
      wallet: "Кошелёк",
      transfer: "Перевод на другой счет",
      deposit: "Внести на биржу",
      exchange: "Обмен у биржи",
      withdraw: "Вывод из биржи",
      operations: "Операции",
      aml: "Политика AML/KYC",
      privacy: "Политика Конфиденциальности",
      cookies: "Политика использования Cookies",
      agreement: "Пользовательское соглашение",
    },
    common: {
      copied: "Скопировано",
    },
  },
  en: {
    copy: {
      copy: "Copy",
      copyEnd: "Copied",
    },

    buttonheader: {
      profile: "Profile",
      notification: "Notifications",
    },
    profilemenu: {
      login: "Login",
      wallet: "Wallet",
      id: "ID",
      balance: "Total Balance",
      status: "Status",
      verification: "Verified",
      personalData: "Personal Data",
      p2pProfile: "P2P Profile",
      security: "Security",
      referral: "Referral Program",
      presents: "Presents",
      logout: "Logout",
      beginner: "Beginner",
      buyVip: "Buy VIP",
      support: "For all questions",
      policies: "Read all",
      policiesLink: "Policies and Agreements",
    },
    verification: {
      pending: "Verification in progress",
      rejected: "Verification rejected",
      reviewing: "Under review",
      start: "Start verification",
    },
    banner: {
      myads: "My Listings",
      settings: "Payment Settings",
      mydeals: "Transaction History",
      spot: "Spot",
      wallet: "Wallet",
      transfer: "Transfer to Another Account",
      deposit: "Deposit to Exchange",
      exchange: "Exchange on Platform",
      withdraw: "Withdraw from Exchange",
      operations: "Operations",
      aml: "AML/KYC Policy",
      privacy: "Privacy Policy",
      cookies: "Cookie Policy",
      agreement: "User Agreement",
    },
    common: {
      copied: "Copied",
    },
  },
  zh: {
    copy: {
      copy: "复制",
      copyEnd: "已复制",
    },
    buttonheader: {
      profile: "个人资料",
      notification: "通知",
    },
    profilemenu: {
      login: "登录名",
      wallet: "钱包",
      id: "ID",
      balance: "总余额",
      status: "状态",
      verification: "已验证",
      personalData: "个人资料",
      p2pProfile: "P2P资料",
      security: "安全",
      referral: "推荐计划",
      presents: "礼物",
      logout: "退出",
      beginner: "新手",
      buyVip: "购买VIP",
      support: "如有任何问题",
      policies: "阅读所有",
      policiesLink: "政策和协议",
    },
    verification: {
      pending: "验证进行中",
      rejected: "验证被拒绝",
      reviewing: "审核中",
      start: "开始验证",
    },
    banner: {
      myads: "我的广告",
      settings: "支付设置",
      mydeals: "交易历史",
      spot: "现货",
      wallet: "钱包",
      transfer: "转账到其他账户",
      deposit: "存入交易所",
      exchange: "平台兑换",
      withdraw: "从交易所提现",
      operations: "操作",
      aml: "AML/KYC 政策",
      privacy: "隐私政策",
      cookies: "Cookie 政策",
      agreement: "用户协议",
    },
    common: {
      copied: "已复制",
    },
  },
  ja: {
    copy: {
      copy: "コピー",
      copyEnd: "コピーしました",
    },
    buttonheader: {
      profile: "プロフィール",
      notification: "通知",
    },
    profilemenu: {
      login: "ログイン",
      wallet: "ウォレット",
      id: "ID",
      balance: "総残高",
      status: "ステータス",
      verification: "認証済み",
      personalData: "個人情報",
      p2pProfile: "P2Pプロフィール",
      security: "セキュリティ",
      referral: "紹介プログラム",
      presents: "プレゼント",
      logout: "ログアウト",
      beginner: "初心者",
      buyVip: "VIP購入",
      support: "お問い合わせ",
      policies: "すべての",
      policiesLink: "ポリシーと規約をお読みください",
    },
    verification: {
      pending: "認証処理中",
      rejected: "認証拒否",
      reviewing: "審査中",
      start: "認証を開始",
    },
    banner: {
      myads: "マイ広告",
      settings: "支払い設定",
      mydeals: "取引履歴",
      spot: "スポット",
      wallet: "ウォレット",
      transfer: "他のアカウントへ転送",
      deposit: "取引所に入金",
      exchange: "プラットフォーム取引",
      withdraw: "取引所から出金",
      operations: "操作",
      aml: "AML/KYC ポリシー",
      privacy: "プライバシーポリシー",
      cookies: "Cookie ポリシー",
      agreement: "利用規約",
    },
    common: {
      copied: "コピーしました",
    },
  },
};

export default messages;
