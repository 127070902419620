const messages = {
  ru: {
    userProfile: {
      login: "Логин",
      id: "ID",
      personalData: "Личные данные",
      p2pProfile: "P2P профиль",
      security: "Безопасность",
      referral: "Реферальная программа",
      presents: "Подарки",
    },
    profile: {
      name: "Имя",
      verified: "Верифицировано",
      notVerified: "Не верифицировано",
      notSpecified: "Не указано",
      withdrawalWarning1: "После изменения доступ к выводу средств будет",
      withdrawalWarning2: "заморожен на 48 часов",
    },
    level: {
      currentLevel: "Текущий уровень",
      lvl: "LVL {level}",
      bonuses: "Бонусы",
      p2pBuy: "Покупка P2P",
      p2pSell: "Продажа P2P",
      nextLevel: "Следующий LVL",
      previousLevel: "Предыдущий LVL",
      currentLevelStar: "Текущий LVL*",
      nextLevelFor: "Следующий LVL {level} для P2P",
      buy: "Купить",
      sell: "Продать",
      resetNote: "*LVL сбрасываются в 01:00 по мск, Premium через 24 часа",
    },
    verificationUser: {
      title: "Верификация",
      resend: "Отправить повторно",
      notVerifiedWarning:
        "Ваш аккаунт не верифицирован или не имеет особого статуса. Необходимо для доступа ко всем функциям на криптобирже",
      verificationPassed: "Верификация пройдена",
      verificationRejected: "Верификация отклонена",
      reason: "Причина",
      documentsInReview: "Ваши документы проходят проверку. Ожидайте",
      or: "или",
      passVerification: "Пройти верификацию",
    },
    verificationModal: {
      uploadDocumentsRequest:
        "Пожалуйста, загрузите изображения своих документов:",
      passportAndRegistration: "Паспорт и Регистрация",
      documentQualityNote:
        "Убедитесь, что вся информация хорошо читается и не засвечена, углы документа не обрезаны",
      uploadOrDrag: "Загрузите или перетащите файлы",
      delete: "Удалить",
      photosUploaded: "{count}/2 фото загружено",
      selfieInstructions:
        "Сфотографируйтесь в полный рост с паспортом в руках и отдельное фото паспорта на фоне сайта без лица",
      selfieQualityNote:
        "На фото должны быть полностью видны ваше лицо и вся информация на документе",
      loading: "Загрузка...",
      proceedToData: "Перейти к заполнению данных",
      requirements: {
        fileFormat:
          "• Загрузите файл формата .jpg, .jpeg или .png не более 25MB",
        fullSize: "• Полноразмерное, неотредактированное фото",
        background: "• Документ размещён на однотонном фоне",
        readability: "• Информация хорошо читаема",
        photoQuality: "• Фотография цветная, сделанная при хорошем освещении",
      },
      errors: {
        fileTooLarge:
          "Файл {filename} слишком большой. Максимальный размер 25MB.",
        wrongFormat:
          "Файл {filename} имеет неверный формат. Разрешены только .jpg, .jpeg или .png",
        tooManyFiles:
          "Выбрано слишком много файлов. Используются только первые 2 файла.",
      },
    },
    passportForm: {
      title: "Заполните данные из паспорта",
      fillAccurately:
        "Заполнять в абсолютной точности со всеми буквами, пробелами и точками, если таковые есть, как в паспорте",
      sending: "Отправка...",
      resend: "Отправить повторно",
      sendForVerification: "Отправить на проверку",
      fields: {
        lastName: "Фамилия",
        firstName: "Имя",
        middleName: "Отчество",
        passportNumber: "Серия и номер паспорта",
        birthDate: "Дата рождения",
        issuedBy: "Кем выдано",
        birthCity: "Город рождения",
        address: "Адрес прописки",
      },
      placeholders: {
        lastName: "Иванов",
        firstName: "Иван",
        middleName: "Иванович",
        passportNumber: "3253812449",
        birthDate: "31.01.1990",
        issuedBy: "ОТДЕЛОМ МВД Г. МОСКВА",
        birthCity: "ГОР МОСКВА",
        address: "ул. Шастунова, дом 30, квартира 25",
      },
      errors: {
        createFailed: "Не удалось создать заявку на верификацию",
        updateFailed: "Не удалось обновить заявку на верификацию",
        generalError: "Произошла ошибка. Пожалуйста, попробуйте снова.",
        notAllFilesUploaded: "Не все файлы были успешно загружены",
        uploadError:
          "Ошибка загрузки файлов. Пожалуйста, попробуйте снова: {error}",
      },
    },
    p2pProfile: {
      title: "P2PProfile",
      earnedOnCommissions: "Заработано на P2P комиссиях",
      commissions: "Комиссии",
      p2pBuy: "P2P Покупка",
      p2pSell: "P2P Продажа",
      totalTurnover: "Оборот за все время",
      todayTurnover: "Оборот за сегодня",
      deals30Days: "Сделки за 30 дней",
      numberOfDeals: "Количество сделок",
      completedDeals: "Завершенные сделки",
      purchase: "Покупка",
      sale: "Продажа",
      rating: "Рейтинг %",
      daysFromCreation: "Дней с создания аккаунта",
      viewReviews: "Посмотреть отзывы",
    },
    merchantInfo: {
      deals30Days: "Сделки за 30 дней",
      orders: "Ордера",
      numberOfDeals: "Количество сделок",
      completedDeals: "Завершенные сделки",
      purchase: "Покупка",
      sale: "Продажа",
      rating: "Рейтинг %",
      accountAgeDays: "Дней с создания аккаунта",
      days: "дн.",
    },
    p2pStatus: {
      title: "Статусы P2P",
      displayedStatuses: "Отображаемые статусы в заявках",
      premium: "Premium",
      coOwner: "Совладелец",
      regularStatus: "Обычный статус",
      newbie: "Новичок",
      merchant: "Мерчант",
      paidStatuses: "Платные статусы",
      achievementStatus: "Статус за достижения*",
      top100: "ТОП 100",
      top3: "ТОП 3",
      top2: "ТОП 2",
      top1: "ТОП 1",
      resetNote: "*Сбрасывается через 30 дней",
    },
    merchant: {
      title: "Мерчант",
      conditionsTitle: "Условия получения статуса мерчанта",
      buy: "Купить",
      sell: "Продать",
      p2pDeals30Days: "P2P Сделок за 30 дней",
      verification: "Верификация",
      deposit: "Депозит",
      or: "или",
      makeDeposit: "Внести депозит",
      insufficientBalance: "На балансе недостаточно монет в $ эквиваленте",
      completedDealsPercent: "Процент завершенных сделок",
    },
    security: {
      title: "Безопасность",
      email: {
        label: "Электронная почта",
        change: "Изменить",
        notConnected: "не подключено",
      },
      password: {
        label: "Пароль",
        change: "Изменить",
      },
      twoFa: {
        label: "2FA-Аутентификация Google",
        connected: "Подключено",
        notConnected: "Не подключено",
        bind: "Привязать",
        unbind: "Отвязать",
        disabling: "Отключение...",
        error: "Ошибка при отключении 2FA",
      },
      telegram: {
        label: "Telegram для входа",
        notConnected: "не подключено",
        bind: "Привязать",
      },
      google: {
        label: "Google почта для мгновенного входа",
        notConnected: "не подключено",
        bind: "Привязать",
      },
      warning: {
        withdrawalAccess: "После изменения доступ к выводу средств будет",
        frozenPeriod: "заморожен на 48 часов",
      },
    },
    twoFaModal: {
      success: {
        title: "2FA успешно подключена!",
      },
      setup: {
        title: "Разрешить 2FA-аутентификацию Google",
        step1: {
          title: "Скачать Google Authenticator Android / iOS",
          description:
            "Google Authenticator можно скачать в App store или Google Play наберите в поиске",
          appName: "Google Authenticator",
        },
        step2: {
          title:
            "Добавьте в Google Authenticator кодовую фразу. Вам НЕОБХОДИМО сохранить ее!",
          description:
            "Откройте Google Authenticator, отсканируйте QR-код или вручную введите кодовую фразу для активации токен подтверждения.",
          warning:
            "Ключевая фраза используется для восстановления Google Authenticator. Обязательно сохраните ключевую фразу в надежном месте!",
        },
        qrCode: {
          instruction: "Отсканировать QR-code внутри Google Authenticator",
          loading: "Загрузка QR-кода...",
          alternativeText: "или вставьте этот код в Google Authenticator",
        },
        verification: {
          inputLabel: "Вставьте полученный код",
          placeholder: "Код от Google Authenticator",
        },
        buttons: {
          connect: "Подключить",
          connecting: "Подключение...",
          later: "Позже",
        },
      },
      errors: {
        copyFailed: "Не удалось скопировать ключ",
        invalidCode: "Код должен состоять из 6 цифр",
        verificationFailed: "Ошибка при включении 2FA. Попробуйте еще раз.",
        authError: "Ошибка авторизации. Попробуйте перезайти",
        initFailed: "Не удалось инициализировать 2FA. Попробуйте еще раз.",
        emptyCode: "Пожалуйста, введите 6-значный код",
      },
    },
    authUser: {
      resetPass: {
        reset: "Сброс пароля",
        yourEmail: "Ваш Email",
        email: "Email",
        sendCode: "Отправить код",
        codeGo: "Код отправлен на",
        code: "Код",
        newPas: "Новый пароль",
        repeatPas: "Повторите пароль",
        resetPas: "Сбросить пароль",
        back: "Назад",
        errors: {
          networkError:
            "Ошибка сети. Возможно, проблема с CORS настройками на сервере.",
          sendCode: "Ошибка при отправке кода. Пожалуйста, попробуйте снова.",
          passwordsNotMatch: "Пароли не совпадают",
          resetFailed: "Не удалось сбросить пароль",
          authFailed:
            "Не удалось авторизовать запрос. Пожалуйста, проверьте свои учетные данные.",
          generalError:
            "Ошибка при сбросе пароля. Пожалуйста, проверьте введенные данные и попробуйте снова.",
        },
        success: {
          codeSent: "Код восстановления отправлен на ваш email",
          alreadySent: "Код восстановления уже отправлен на ваш email",
          resetSuccess: "Пароль успешно сброшен",
        },
      },
    },
    googleLink: {
      title: "Привязка Google почты",
      email: {
        label: "Google почта",
        placeholder: "Введите Google почту",
        required: "Google почта обязательна",
        invalid: "Введите корректный email адрес",
      },
      buttons: {
        link: "Привязать",
        linking: "Привязка...",
        cancel: "Отмена",
      },
      errors: {
        updateError: "Ошибка обновления профиля. Попробуйте позже.",
      },
    },
    emailChange: {
      title: "Изменение email",
      instruction: "Введите новый email адрес",
      email: {
        placeholder: "Новый email",
        required: "Email обязателен",
        invalid: "Введите корректный email адрес",
        sameAsCurrent: "Новый email совпадает с текущим",
      },
      buttons: {
        save: "Сохранить",
        saving: "Сохранение...",
        cancel: "Отмена",
      },
      errors: {
        updateError: "Ошибка обновления email. Попробуйте позже.",
      },
    },
    telegramLink: {
      title: "Привязка Telegram",
      telegramId: {
        label: "ID Telegram",
        placeholder: "Введите ID телеграм",
        required: "ID Telegram обязателен",
      },
      username: {
        label: "Имя пользователя Telegram",
        placeholder: "Введите username",
        required: "Имя пользователя Telegram обязательно",
        startWith: "Имя пользователя должно начинаться с @",
      },
      buttons: {
        link: "Привязать",
        linking: "Привязка...",
        cancel: "Отмена",
      },
      errors: {
        updateError: "Ошибка обновления профиля. Попробуйте позже.",
      },
    },
    referralProgram: {
      title: "Реферальная программа",
      currentRate: "20%",
      upgradeText: "Повысить до 50%",
      fromPaymentsText: "от всех оплат и комиссий",
      lifetime: "пожизненно!",
      earnings30Days: "Ваш заработок за 30 дней",
      referralsCount: "Кол-во рефералов",
      avgEarnings30Days: "Средний заработок пользователей за 30 дней",
      shareLink: "Рекомнедуй ссылку, зарабтывай пожизенно!",
      refCodeUnavailable: "Код реферала не доступен",
      transferButton: "Перевести на баланс",
    },
    presents: {
      title: "Подарки",
      earnedOnGifts: "Заработано на подарках",
      available: "Доступно",
      giftHistory: "История подарков",
      p2pDealGift: "Umbrella и USDT за P2P сделку",
      registrationGift: "Umbrella и USDT за Регистрацию",
      dailyTaskGift: "Umbrella и USDT за Ежедневное задание",
      commissionBonus: "комиссия за каждую сделку на",
      stakingBonus: "стейкинг на",
      day: "день",
      days: "дней",
    },
  },

  en: {
    userProfile: {
      login: "Login",
      id: "ID",
      personalData: "Personal Data",
      p2pProfile: "P2P Profile",
      security: "Security",
      referral: "Referral Program",
      presents: "Presents",
    },
    profileData: {
      name: "Name",
      verified: "Verified",
      notVerified: "Not Verified",
      notSpecified: "Not Specified",
      withdrawal1: "After changes, access to fund withdrawal will be",
      withdrawal2: "frozen for 48 hours",
    },
    level: {
      currentLevel: "Current Level",
      lvl: "LVL {level}",
      bonuses: "Bonuses",
      p2pBuy: "P2P Buy",
      p2pSell: "P2P Sell",
      nextLevel: "Next LVL",
      previousLevel: "Previous LVL",
      currentLevelStar: "Current LVL*",
      nextLevelFor: "Next LVL {level} for P2P",
      buy: "Buy",
      sell: "Sell",
      resetNote: "*LVL resets at 01:00 MSK, Premium after 24 hours",
    },
    verificationUser: {
      title: "Verification",
      resend: "Resend",
      notVerifiedWarning:
        "Your account is not verified or does not have special status. Required for access to all features on the crypto exchange",
      verificationPassed: "Verification Passed",
      verificationRejected: "Verification Rejected",
      reason: "Reason",
      documentsInReview: "Your documents are under review. Please wait",
      or: "or",
      passVerification: "Pass Verification",
    },
    verificationModal: {
      uploadDocumentsRequest: "Please upload images of your documents:",
      passportAndRegistration: "Passport and Registration",
      documentQualityNote:
        "Make sure all information is clearly readable and not overexposed, document corners are not cut off",
      uploadOrDrag: "Upload or drag files",
      delete: "Delete",
      photosUploaded: "{count}/2 photos uploaded",
      selfieInstructions:
        "Take a full-length photo with passport in hand and a separate photo of the passport against the website background without face",
      selfieQualityNote:
        "The photo must show your face and all document information completely",
      loading: "Loading...",
      proceedToData: "Proceed to data entry",
      requirements: {
        fileFormat:
          "• Upload file in .jpg, .jpeg or .png format no larger than 25MB",
        fullSize: "• Full-size, unedited photo",
        background: "• Document placed on a solid background",
        readability: "• Information is clearly readable",
        photoQuality: "• Color photo taken in good lighting",
      },
      errors: {
        fileTooLarge: "File {filename} is too large. Maximum size is 25MB.",
        wrongFormat:
          "File {filename} has wrong format. Only .jpg, .jpeg or .png are allowed",
        tooManyFiles:
          "Too many files selected. Only first 2 files will be used.",
      },
    },
    passportForm: {
      title: "Fill in passport data",
      fillAccurately:
        "Fill in with absolute accuracy, including all letters, spaces, and periods if any, exactly as in the passport",
      sending: "Sending...",
      resend: "Resend",
      sendForVerification: "Send for verification",
      fields: {
        lastName: "Last name",
        firstName: "First name",
        middleName: "Middle name",
        passportNumber: "Passport series and number",
        birthDate: "Date of birth",
        issuedBy: "Issued by",
        birthCity: "City of birth",
        address: "Registration address",
      },
      placeholders: {
        lastName: "Smith",
        firstName: "John",
        middleName: "Robert",
        passportNumber: "3253812449",
        birthDate: "31.01.1990",
        issuedBy: "DEPARTMENT OF INTERNAL AFFAIRS",
        birthCity: "LONDON",
        address: "30 Baker Street, Apt 25",
      },
      errors: {
        createFailed: "Failed to create verification request",
        updateFailed: "Failed to update verification request",
        generalError: "An error occurred. Please try again.",
        notAllFilesUploaded: "Not all files were uploaded successfully",
        uploadError: "Error uploading files. Please try again: {error}",
      },
    },
    p2pProfile: {
      title: "P2PProfile",
      earnedOnCommissions: "Earned on P2P commissions",
      commissions: "Commissions",
      p2pBuy: "P2P Buy",
      p2pSell: "P2P Sell",
      totalTurnover: "Total turnover",
      todayTurnover: "Today's turnover",
      deals30Days: "Deals in 30 days",
      numberOfDeals: "Number of deals",
      completedDeals: "Completed deals",
      purchase: "Purchase",
      sale: "Sale",
      rating: "Rating %",
      daysFromCreation: "Days since account creation",
      viewReviews: "View reviews",
    },
    merchantInfo: {
      deals30Days: "Deals in 30 days",
      orders: "Orders",
      numberOfDeals: "Number of deals",
      completedDeals: "Completed deals",
      purchase: "Purchase",
      sale: "Sale",
      rating: "Rating %",
      accountAgeDays: "Days since account creation",
      days: "days",
    },
    p2pStatus: {
      title: "P2P Statuses",
      displayedStatuses: "Displayed statuses in orders",
      premium: "Premium",
      coOwner: "Co-owner",
      regularStatus: "Regular status",
      newbie: "Newbie",
      merchant: "Merchant",
      paidStatuses: "Paid statuses",
      achievementStatus: "Achievement status*",
      top100: "TOP 100",
      top3: "TOP 3",
      top2: "TOP 2",
      top1: "TOP 1",
      resetNote: "*Resets after 30 days",
    },
    merchant: {
      title: "Merchant",
      conditionsTitle: "Conditions for obtaining merchant status",
      buy: "Buy",
      sell: "Sell",
      p2pDeals30Days: "P2P Deals in 30 days",
      verification: "Verification",
      deposit: "Deposit",
      or: "or",
      makeDeposit: "Make deposit",
      insufficientBalance: "Insufficient coins in $ equivalent on balance",
      completedDealsPercent: "Completed deals percentage",
    },
    security: {
      title: "Security",
      email: {
        label: "Email",
        change: "Change",
        notConnected: "not connected",
      },
      password: {
        label: "Password",
        change: "Change",
      },
      twoFa: {
        label: "Google 2FA Authentication",
        connected: "Connected",
        notConnected: "Not Connected",
        bind: "Bind",
        unbind: "Unbind",
        disabling: "Disabling...",
        error: "Error disabling 2FA",
      },
      telegram: {
        label: "Telegram for login",
        notConnected: "not connected",
        bind: "Bind",
      },
      google: {
        label: "Google email for instant login",
        notConnected: "not connected",
        bind: "Bind",
      },
      warning: {
        withdrawalAccess: "After changes, withdrawal access will be",
        frozenPeriod: "frozen for 48 hours",
      },
    },
    twoFaModal: {
      success: {
        title: "2FA successfully connected!",
      },
      setup: {
        title: "Enable Google 2FA Authentication",
        step1: {
          title: "Download Google Authenticator for Android / iOS",
          description:
            "Google Authenticator can be downloaded from App Store or Google Play by searching for",
          appName: "Google Authenticator",
        },
        step2: {
          title:
            "Add the code phrase to Google Authenticator. You MUST save it!",
          description:
            "Open Google Authenticator, scan the QR code or manually enter the code phrase to activate the confirmation token.",
          warning:
            "The key phrase is used to recover Google Authenticator. Make sure to save the key phrase in a secure place!",
        },
        qrCode: {
          instruction: "Scan QR code inside Google Authenticator",
          loading: "Loading QR code...",
          alternativeText: "or enter this code in Google Authenticator",
        },
        verification: {
          inputLabel: "Enter the received code",
          placeholder: "Code from Google Authenticator",
        },
        buttons: {
          connect: "Connect",
          connecting: "Connecting...",
          later: "Later",
        },
      },
      errors: {
        copyFailed: "Failed to copy key",
        invalidCode: "Code must be 6 digits",
        verificationFailed: "Error enabling 2FA. Please try again.",
        authError: "Authorization error. Please try logging in again",
        initFailed: "Failed to initialize 2FA. Please try again.",
        emptyCode: "Please enter a 6-digit code",
      },
    },
    authUser: {
      resetPass: {
        reset: "Password Reset",
        yourEmail: "Your Email",
        email: "Email",
        sendCode: "Send Code",
        codeGo: "Code sent to",
        code: "Code",
        newPas: "New password",
        repeatPas: "Repeat password",
        resetPas: "Reset Password",
        back: "Back",
        errors: {
          networkError:
            "Network error. Possible CORS configuration issue on the server.",
          sendCode: "Error sending code. Please try again.",
          passwordsNotMatch: "Passwords do not match",
          resetFailed: "Failed to reset password",
          authFailed:
            "Failed to authorize request. Please check your credentials.",
          generalError:
            "Error resetting password. Please check your input and try again.",
        },
        success: {
          codeSent: "Recovery code sent to your email",
          alreadySent: "Recovery code has already been sent to your email",
          resetSuccess: "Password successfully reset",
        },
      },
    },
    googleLink: {
      title: "Link Google Email",
      email: {
        label: "Google Email",
        placeholder: "Enter Google email",
        required: "Google email is required",
        invalid: "Enter a valid email address",
      },
      buttons: {
        link: "Link",
        linking: "Linking...",
        cancel: "Cancel",
      },
      errors: {
        updateError: "Profile update error. Please try again later.",
      },
    },
    emailChange: {
      title: "Change Email",
      instruction: "Enter new email address",
      email: {
        placeholder: "New email",
        required: "Email is required",
        invalid: "Enter a valid email address",
        sameAsCurrent: "New email is the same as current",
      },
      buttons: {
        save: "Save",
        saving: "Saving...",
        cancel: "Cancel",
      },
      errors: {
        updateError: "Error updating email. Please try again later.",
      },
    },
    telegramLink: {
      title: "Link Telegram",
      telegramId: {
        label: "Telegram ID",
        placeholder: "Enter Telegram ID",
        required: "Telegram ID is required",
      },
      username: {
        label: "Telegram Username",
        placeholder: "Enter username",
        required: "Telegram username is required",
        startWith: "Username must start with @",
      },
      buttons: {
        link: "Link",
        linking: "Linking...",
        cancel: "Cancel",
      },
      errors: {
        updateError: "Profile update error. Please try again later.",
      },
    },
    referralProgram: {
      title: "Referral Program",
      currentRate: "20%",
      upgradeText: "Upgrade to 50%",
      fromPaymentsText: "from all payments and commissions",
      lifetime: "lifetime!",
      earnings30Days: "Your earnings in 30 days",
      referralsCount: "Number of referrals",
      avgEarnings30Days: "Average user earnings in 30 days",
      shareLink: "Share the link, earn lifetime!",
      refCodeUnavailable: "Referral code unavailable",
      transferButton: "Transfer to balance",
    },
    presents: {
      title: "Gifts",
      earnedOnGifts: "Earned from gifts",
      available: "Available",
      giftHistory: "Gift history",
      p2pDealGift: "Umbrella and USDT for P2P deal",
      registrationGift: "Umbrella and USDT for Registration",
      dailyTaskGift: "Umbrella and USDT for Daily task",
      commissionBonus: "commission for each trade for",
      stakingBonus: "staking for",
      day: "day",
      days: "days",
    },
  },

  zh: {
    userProfile: {
      login: "登录",
      id: "ID",
      personalData: "个人资料",
      p2pProfile: "P2P 档案",
      security: "安全",
      referral: "推荐计划",
      presents: "礼物",
    },
    profile: {
      name: "姓名",
      verified: "已验证",
      notVerified: "未验证",
      notSpecified: "未指定",
      withdrawalWarning1: "更改后，提现功能将",
      withdrawalWarning2: "冻结48小时",
    },
    level: {
      currentLevel: "当前等级",
      lvl: "等级 {level}",
      bonuses: "奖励",
      p2pBuy: "P2P 购买",
      p2pSell: "P2P 出售",
      nextLevel: "下一等级",
      previousLevel: "上一等级",
      currentLevelStar: "当前等级*",
      nextLevelFor: "P2P下一等级 {level}",
      buy: "购买",
      sell: "出售",
      resetNote: "*等级在莫斯科时间01:00重置 Premium在24小时后重置",
    },
    verificationUser: {
      title: "验证",
      resend: "重新发送",
      notVerifiedWarning:
        "您的帐户未经验证或没有特殊状态。访问加密货币交易所的所有功能需要验证",
      verificationPassed: "验证通过",
      verificationRejected: "验证被拒绝",
      reason: "原因",
      documentsInReview: "您的文件正在审核中。请等待",
      or: "或者",
      passVerification: "通过验证",
    },
    verificationModal: {
      uploadDocumentsRequest: "请上传您的证件图片：",
      passportAndRegistration: "护照和登记证",
      documentQualityNote: "确保所有信息清晰可读且未过度曝光，证件边角未被裁剪",
      uploadOrDrag: "上传或拖拽文件",
      delete: "删除",
      photosUploaded: "已上传 {count}/2 张照片",
      selfieInstructions:
        "请拍摄手持护照的全身照，以及一张在网站背景下的护照照片（无需露脸）",
      selfieQualityNote: "照片必须完整显示您的脸部和所有证件信息",
      loading: "加载中...",
      proceedToData: "继续填写数据",
      requirements: {
        fileFormat: "• 上传.jpg、.jpeg或.png格式文件，大小不超过25MB",
        fullSize: "• 完整尺寸，未经编辑的照片",
        background: "• 证件放置在纯色背景上",
        readability: "• 信息清晰可读",
        photoQuality: "• 在良好光线下拍摄的彩色照片",
      },
      errors: {
        fileTooLarge: "文件 {filename} 太大。最大大小为25MB。",
        wrongFormat: "文件 {filename} 格式错误。仅允许.jpg、.jpeg或.png格式",
        tooManyFiles: "选择的文件太多。仅使用前2个文件。",
      },
    },
    passportForm: {
      title: "填写护照信息",
      fillAccurately:
        "请完全按照护照上的信息准确填写，包括所有字母、空格和标点符号",
      sending: "发送中...",
      resend: "重新发送",
      sendForVerification: "提交验证",
      fields: {
        lastName: "姓",
        firstName: "名",
        middleName: "父称",
        passportNumber: "护照号码",
        birthDate: "出生日期",
        issuedBy: "签发机关",
        birthCity: "出生城市",
        address: "登记地址",
      },
      placeholders: {
        lastName: "张",
        firstName: "明",
        middleName: "华",
        passportNumber: "3253812449",
        birthDate: "1990-01-31",
        issuedBy: "公安部出入境管理局",
        birthCity: "北京市",
        address: "朝阳区建国路30号25室",
      },
      errors: {
        createFailed: "创建验证请求失败",
        updateFailed: "更新验证请求失败",
        generalError: "发生错误，请重试",
        notAllFilesUploaded: "并非所有文件都上传成功",
        uploadError: "上传文件时出错。请重试：{error}",
      },
    },
    p2pProfile: {
      title: "P2P档案",
      earnedOnCommissions: "P2P佣金收入",
      commissions: "佣金",
      p2pBuy: "P2P购买",
      p2pSell: "P2P出售",
      totalTurnover: "总成交额",
      todayTurnover: "今日成交额",
      deals30Days: "30天内交易",
      numberOfDeals: "交易数量",
      completedDeals: "已完成交易",
      purchase: "购买",
      sale: "出售",
      rating: "评分 %",
      daysFromCreation: "账户创建天数",
      viewReviews: "查看评价",
    },
    merchantInfo: {
      deals30Days: "30天内交易",
      orders: "订单",
      numberOfDeals: "交易数量",
      completedDeals: "已完成交易",
      purchase: "购买",
      sale: "出售",
      rating: "评分 %",
      accountAgeDays: "账户创建天数",
      days: "天",
    },
    p2pStatus: {
      title: "P2P状态",
      displayedStatuses: "订单中显示的状态",
      premium: "高级会员",
      coOwner: "共同所有者",
      regularStatus: "普通状态",
      newbie: "新手",
      merchant: "商家",
      paidStatuses: "付费状态",
      achievementStatus: "成就状态*",
      top100: "前100名",
      top3: "前3名",
      top2: "前2名",
      top1: "第1名",
      resetNote: "*30天后重置",
    },
    merchant: {
      title: "商家",
      conditionsTitle: "获得商家身份的条件",
      buy: "购买",
      sell: "出售",
      p2pDeals30Days: "30天内P2P交易",
      verification: "验证",
      deposit: "存款",
      or: "或",
      makeDeposit: "存入",
      insufficientBalance: "余额中等值$币种不足",
      completedDealsPercent: "已完成交易百分比",
    },
    security: {
      title: "安全",
      email: {
        label: "电子邮箱",
        change: "更改",
        notConnected: "未连接",
      },
      password: {
        label: "密码",
        change: "更改",
      },
      twoFa: {
        label: "Google两步验证",
        connected: "已连接",
        notConnected: "未连接",
        bind: "绑定",
        unbind: "解绑",
        disabling: "正在禁用...",
        error: "禁用两步验证时出错",
      },
      telegram: {
        label: "Telegram登录",
        notConnected: "未连接",
        bind: "绑定",
      },
      google: {
        label: "Google邮箱快速登录",
        notConnected: "未连接",
        bind: "绑定",
      },
      warning: {
        withdrawalAccess: "更改后，提现功能将",
        frozenPeriod: "冻结48小时",
      },
    },
    twoFaModal: {
      success: {
        title: "2FA连接成功！",
      },
      setup: {
        title: "启用Google两步验证",
        step1: {
          title: "下载适用于Android/iOS的Google Authenticator",
          description: "在App Store或Google Play中搜索以下应用",
          appName: "Google Authenticator",
        },
        step2: {
          title: "将验证码添加到Google Authenticator。您必须保存它！",
          description:
            "打开Google Authenticator，扫描二维码或手动输入验证码以激活确认令牌。",
          warning:
            "密钥用于恢复Google Authenticator。请务必将密钥保存在安全的地方！",
        },
        qrCode: {
          instruction: "在Google Authenticator中扫描二维码",
          loading: "正在加载二维码...",
          alternativeText: "或在Google Authenticator中输入此代码",
        },
        verification: {
          inputLabel: "输入收到的验证码",
          placeholder: "Google Authenticator验证码",
        },
        buttons: {
          connect: "连接",
          connecting: "连接中...",
          later: "稍后",
        },
      },
      errors: {
        copyFailed: "复制密钥失败",
        invalidCode: "验证码必须为6位数字",
        verificationFailed: "启用2FA时出错。请重试。",
        authError: "授权错误。请重新登录",
        initFailed: "初始化2FA失败。请重试。",
        emptyCode: "请输入6位验证码",
      },
    },
    authUser: {
      resetPass: {
        reset: "重置密码",
        yourEmail: "您的邮箱",
        email: "邮箱",
        sendCode: "发送验证码",
        codeGo: "验证码已发送至",
        code: "验证码",
        newPas: "新密码",
        repeatPas: "确认密码",
        resetPas: "重置密码",
        back: "返回",
        errors: {
          networkError: "网络错误。可能是服务器CORS配置问题。",
          sendCode: "发送验证码出错。请重试。",
          passwordsNotMatch: "两次输入的密码不一致",
          resetFailed: "密码重置失败",
          authFailed: "请求授权失败。请检查您的凭据。",
          generalError: "重置密码时出错。请检查输入并重试。",
        },
        success: {
          codeSent: "恢复码已发送到您的邮箱",
          alreadySent: "恢复码已经发送到您的邮箱",
          resetSuccess: "密码重置成功",
        },
      },
    },
    googleLink: {
      title: "绑定Google邮箱",
      email: {
        label: "Google邮箱",
        placeholder: "输入Google邮箱",
        required: "必须输入Google邮箱",
        invalid: "请输入有效的邮箱地址",
      },
      buttons: {
        link: "绑定",
        linking: "绑定中...",
        cancel: "取消",
      },
      errors: {
        updateError: "更新档案时出错。请稍后再试。",
      },
    },
    emailChange: {
      title: "更改邮箱",
      instruction: "输入新邮箱地址",
      email: {
        placeholder: "新邮箱",
        required: "邮箱为必填项",
        invalid: "请输入有效的邮箱地址",
        sameAsCurrent: "新邮箱与当前邮箱相同",
      },
      buttons: {
        save: "保存",
        saving: "保存中...",
        cancel: "取消",
      },
      errors: {
        updateError: "更新邮箱时出错。请稍后再试。",
      },
    },
    telegramLink: {
      title: "绑定Telegram",
      telegramId: {
        label: "Telegram ID",
        placeholder: "输入Telegram ID",
        required: "必须输入Telegram ID",
      },
      username: {
        label: "Telegram用户名",
        placeholder: "输入用户名",
        required: "必须输入Telegram用户名",
        startWith: "用户名必须以@开头",
      },
      buttons: {
        link: "绑定",
        linking: "绑定中...",
        cancel: "取消",
      },
      errors: {
        updateError: "更新档案时出错。请稍后再试。",
      },
    },
    referralProgram: {
      title: "推荐计划",
      currentRate: "20%",
      upgradeText: "提升至50%",
      fromPaymentsText: "来自所有支付和佣金",
      lifetime: "终身！",
      earnings30Days: "您30天的收益",
      referralsCount: "推荐人数",
      avgEarnings30Days: "用户30天平均收益",
      shareLink: "分享链接，终身赚钱！",
      refCodeUnavailable: "推荐码不可用",
      transferButton: "转入余额",
    },
    presents: {
      title: "礼品",
      earnedOnGifts: "礼品收益",
      available: "可用",
      giftHistory: "礼品历史",
      p2pDealGift: "P2P交易获得的Umbrella和USDT",
      registrationGift: "注册获得的Umbrella和USDT",
      dailyTaskGift: "每日任务获得的Umbrella和USDT",
      commissionBonus: "每笔交易的手续费",
      stakingBonus: "质押期限",
      day: "天",
      days: "天",
    },
  },

  ja: {
    userProfile: {
      login: "ログイン",
      id: "ID",
      personalData: "個人情報",
      p2pProfile: "P2Pプロフィール",
      security: "セキュリティ",
      referral: "紹介プログラム",
      presents: "プレゼント",
    },
    profile: {
      name: "名前",
      verified: "認証済み",
      notVerified: "未認証",
      notSpecified: "未指定",
      withdrawalWarning1: "変更後、出金機能は",
      withdrawalWarning2: "48時間凍結されます",
    },
    level: {
      currentLevel: "現在のレベル",
      lvl: "レベル {level}",
      bonuses: "ボーナス",
      p2pBuy: "P2P購入",
      p2pSell: "P2P販売",
      nextLevel: "次のレベル",
      previousLevel: "前のレベル",
      currentLevelStar: "現在のレベル*",
      nextLevelFor: "P2Pの次のレベル {level}",
      buy: "購入",
      sell: "販売",
      resetNote: "*レベルはMSK 01:00にリセット、プレミアムは24時間後",
    },
    verificationUser: {
      title: "認証",
      resend: "再送信",
      notVerifiedWarning:
        "アカウントが未認証または特別なステータスがありません。暗号取引所のすべての機能にアクセスするために必要です",
      verificationPassed: "認証完了",
      verificationRejected: "認証拒否",
      reason: "理由",
      documentsInReview: "書類を確認中です。お待ちください",
      or: "または",
      passVerification: "認証を通過",
    },
    verificationModal: {
      uploadDocumentsRequest: "書類の画像をアップロードしてください：",
      passportAndRegistration: "パスポートと登録証",
      documentQualityNote:
        "すべての情報が明確に読め、露出過度でなく、書類の角が切れていないことを確認してください",
      uploadOrDrag: "ファイルをアップロードまたはドラッグ",
      delete: "削除",
      photosUploaded: "{count}/2 枚の写真がアップロードされました",
      selfieInstructions:
        "パスポートを手に持った全身写真と、ウェブサイトを背景にしたパスポートの写真（顔なし）を撮影してください",
      selfieQualityNote:
        "写真には顔とすべての書類情報が完全に表示されている必要があります",
      loading: "読み込み中...",
      proceedToData: "データ入力に進む",
      requirements: {
        fileFormat:
          "• .jpg、.jpegまたは.png形式、25MB以下のファイルをアップロード",
        fullSize: "• フルサイズ、未編集の写真",
        background: "• 単色の背景に書類を配置",
        readability: "• 情報が明確に読める",
        photoQuality: "• 適切な照明で撮影されたカラー写真",
      },
      errors: {
        fileTooLarge:
          "ファイル {filename} が大きすぎます。最大サイズは25MBです。",
        wrongFormat:
          "ファイル {filename} の形式が正しくありません。.jpg、.jpeg、.pngのみ許可されています",
        tooManyFiles:
          "選択されたファイルが多すぎます。最初の2ファイルのみ使用されます。",
      },
    },
    passportForm: {
      title: "パスポート情報を入力",
      fillAccurately:
        "パスポートに記載されている通りに、すべての文字、スペース、句読点を正確に入力してください",
      sending: "送信中...",
      resend: "再送信",
      sendForVerification: "確認のため送信",
      fields: {
        lastName: "姓",
        firstName: "名",
        middleName: "ミドルネーム",
        passportNumber: "パスポート番号",
        birthDate: "生年月日",
        issuedBy: "発行機関",
        birthCity: "出生地",
        address: "登録住所",
      },
      placeholders: {
        lastName: "田中",
        firstName: "太郎",
        middleName: "",
        passportNumber: "3253812449",
        birthDate: "1990-01-31",
        issuedBy: "外務省",
        birthCity: "東京都",
        address: "新宿区新宿30-25",
      },
      errors: {
        createFailed: "認証リクエストの作成に失敗しました",
        updateFailed: "認証リクエストの更新に失敗しました",
        generalError: "エラーが発生しました。もう一度お試しください",
        notAllFilesUploaded: "一部のファイルのアップロードに失敗しました",
        uploadError:
          "ファイルのアップロードエラー。もう一度お試しください：{error}",
      },
    },
    p2pProfile: {
      title: "P2Pプロフィール",
      earnedOnCommissions: "P2P手数料での収入",
      commissions: "手数料",
      p2pBuy: "P2P購入",
      p2pSell: "P2P販売",
      totalTurnover: "累計取引高",
      todayTurnover: "本日の取引高",
      deals30Days: "30日間の取引",
      numberOfDeals: "取引数",
      completedDeals: "完了した取引",
      purchase: "購入",
      sale: "販売",
      rating: "評価 %",
      daysFromCreation: "アカウント作成からの日数",
      viewReviews: "レビューを見る",
    },
    merchantInfo: {
      deals30Days: "30日間の取引",
      orders: "注文",
      numberOfDeals: "取引数",
      completedDeals: "完了した取引",
      purchase: "購入",
      sale: "販売",
      rating: "評価 %",
      accountAgeDays: "アカウント作成からの日数",
      days: "日",
    },
    p2pStatus: {
      title: "P2Pステータス",
      displayedStatuses: "注文で表示されるステータス",
      premium: "プレミアム",
      coOwner: "共同オーナー",
      regularStatus: "通常ステータス",
      newbie: "新規",
      merchant: "商人",
      paidStatuses: "有料ステータス",
      achievementStatus: "達成ステータス*",
      top100: "TOP 100",
      top3: "TOP 3",
      top2: "TOP 2",
      top1: "TOP 1",
      resetNote: "*30日後にリセット",
    },
    merchant: {
      title: "マーチャント",
      conditionsTitle: "マーチャントステータス取得条件",
      buy: "購入",
      sell: "販売",
      p2pDeals30Days: "30日間のP2P取引",
      verification: "認証",
      deposit: "入金",
      or: "または",
      makeDeposit: "入金する",
      insufficientBalance: "残高のドル相当のコインが不足しています",
      completedDealsPercent: "取引完了率",
    },
    security: {
      title: "セキュリティ",
      email: {
        label: "メールアドレス",
        change: "変更",
        notConnected: "未接続",
      },
      password: {
        label: "パスワード",
        change: "変更",
      },
      twoFa: {
        label: "Google二段階認証",
        connected: "接続済み",
        notConnected: "未接続",
        bind: "連携",
        unbind: "解除",
        disabling: "無効化中...",
        error: "二段階認証の無効化エラー",
      },
      telegram: {
        label: "Telegramログイン",
        notConnected: "未接続",
        bind: "連携",
      },
      google: {
        label: "Googleメール即時ログイン",
        notConnected: "未接続",
        bind: "連携",
      },
      warning: {
        withdrawalAccess: "変更後、出金機能は",
        frozenPeriod: "48時間凍結されます",
      },
    },
    twoFaModal: {
      success: {
        title: "2FA接続成功！",
      },
      setup: {
        title: "Google二段階認証を有効化",
        step1: {
          title: "Android/iOS用Google Authenticatorをダウンロード",
          description: "App StoreまたはGoogle Playで以下を検索してください",
          appName: "Google Authenticator",
        },
        step2: {
          title:
            "Google Authenticatorに認証コードを追加してください。必ず保存してください！",
          description:
            "Google Authenticatorを開き、QRコードをスキャンするか、手動で認証コードを入力して確認トークンを有効化してください。",
          warning:
            "認証キーはGoogle Authenticatorの復元に使用されます。必ず安全な場所に保管してください！",
        },
        qrCode: {
          instruction: "Google Authenticator内でQRコードをスキャン",
          loading: "QRコード読み込み中...",
          alternativeText: "または、このコードをGoogle Authenticatorに入力",
        },
        verification: {
          inputLabel: "受け取ったコードを入力",
          placeholder: "Google Authenticatorのコード",
        },
        buttons: {
          connect: "接続",
          connecting: "接続中...",
          later: "後で",
        },
      },
      errors: {
        copyFailed: "キーのコピーに失敗しました",
        invalidCode: "コードは6桁の数字である必要があります",
        verificationFailed:
          "2FAの有効化に失敗しました。もう一度お試しください。",
        authError: "認証エラー。再度ログインしてください",
        initFailed: "2FAの初期化に失敗しました。もう一度お試しください。",
        emptyCode: "6桁のコードを入力してください",
      },
    },
    authUser: {
      resetPass: {
        reset: "パスワードリセット",
        yourEmail: "メールアドレス",
        email: "メールアドレス",
        sendCode: "コードを送信",
        codeGo: "コードの送信先：",
        code: "コード",
        newPas: "新しいパスワード",
        repeatPas: "パスワードを再入力",
        resetPas: "パスワードをリセット",
        back: "戻る",
        errors: {
          networkError:
            "ネットワークエラー。サーバーのCORS設定に問題がある可能性があります。",
          sendCode: "コードの送信に失敗しました。もう一度お試しください。",
          passwordsNotMatch: "パスワードが一致しません",
          resetFailed: "パスワードのリセットに失敗しました",
          authFailed:
            "リクエストの認証に失敗しました。認証情報をご確認ください。",
          generalError:
            "パスワードのリセットに失敗しました。入力内容を確認して再試行してください。",
        },
        success: {
          codeSent: "復旧コードをメールに送信しました",
          alreadySent: "復旧コードはすでにメールに送信されています",
          resetSuccess: "パスワードが正常にリセットされました",
        },
      },
    },
    googleLink: {
      title: "Googleメール連携",
      email: {
        label: "Googleメール",
        placeholder: "Googleメールを入力",
        required: "Googleメールは必須です",
        invalid: "有効なメールアドレスを入力してください",
      },
      buttons: {
        link: "連携",
        linking: "連携中...",
        cancel: "キャンセル",
      },
      errors: {
        updateError:
          "プロフィールの更新に失敗しました。後でもう一度お試しください。",
      },
    },
    emailChange: {
      title: "メールアドレスの変更",
      instruction: "新しいメールアドレスを入力してください",
      email: {
        placeholder: "新しいメールアドレス",
        required: "メールアドレスは必須です",
        invalid: "有効なメールアドレスを入力してください",
        sameAsCurrent: "新しいメールアドレスが現在のものと同じです",
      },
      buttons: {
        save: "保存",
        saving: "保存中...",
        cancel: "キャンセル",
      },
      errors: {
        updateError:
          "メールアドレスの更新に失敗しました。後でもう一度お試しください。",
      },
    },
    telegramLink: {
      title: "Telegram連携",
      telegramId: {
        label: "Telegram ID",
        placeholder: "Telegram IDを入力",
        required: "Telegram IDは必須です",
      },
      username: {
        label: "Telegramユーザー名",
        placeholder: "ユーザー名を入力",
        required: "Telegramユーザー名は必須です",
        startWith: "ユーザー名は@で始める必要があります",
      },
      buttons: {
        link: "連携",
        linking: "連携中...",
        cancel: "キャンセル",
      },
      errors: {
        updateError:
          "プロフィールの更新に失敗しました。後でもう一度お試しください。",
      },
    },
    referralProgram: {
      title: "紹介プログラム",
      currentRate: "20%",
      upgradeText: "50%にアップグレード",
      fromPaymentsText: "すべての支払いと手数料から",
      lifetime: "永久的！",
      earnings30Days: "30日間の収益",
      referralsCount: "紹介数",
      avgEarnings30Days: "ユーザーの30日間平均収益",
      shareLink: "リンクを共有して、永久的に稼ぎましょう！",
      refCodeUnavailable: "紹介コードは利用できません",
      transferButton: "残高に転送",
    },
    presents: {
      title: "ギフト",
      earnedOnGifts: "ギフトで獲得",
      available: "利用可能",
      giftHistory: "ギフト履歴",
      p2pDealGift: "P2P取引でのUmbrellaとUSDT",
      registrationGift: "登録でのUmbrellaとUSDT",
      dailyTaskGift: "デイリータスクでのUmbrellaとUSDT",
      commissionBonus: "取引手数料",
      stakingBonus: "ステーキング期間",
      day: "日",
      days: "日",
    },
  },
};

export default messages;
