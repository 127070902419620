const messages = {
  ru: {
    tableorder: {
      noOffers: "Объявлений не найдено",
      coowner: "Совладелец",
      premium: "Premium",
      verifiedMerchant: "Проверенный мерчант",
      verifiedPassport: "Верифицирован паспорт",
      online: "Онлайн",
      offline: "Оффлайн",
      completed: "исполнено",
      pricePerUnit: "Цена за 1",
      banks: "Банки",
      cash: "Наличными",
      available: "Доступно",
      limit: "Лимит",
      payMethods: "Способы оплаты",
      minMaxAmount: "Min. - Max. сумма сделки",
    },
  },
  en: {
    tableorder: {
      noOffers: "No offers found",
      coowner: "Co-owner",
      premium: "Premium",
      verifiedMerchant: "Verified Merchant",
      verifiedPassport: "Verified Passport",
      online: "Online",
      offline: "Offline",
      completed: "completed",
      pricePerUnit: "Price per 1",
      banks: "Banks",
      cash: "Cash",
      available: "Available",
      limit: "Limit",
      payMethods: "Payment methods",
      minMaxAmount: "Min. - Max. transaction amount",
    },
  },
  zh: {
    tableorder: {
      noOffers: "未找到报价",
      coowner: "共同所有人",
      premium: "高级会员",
      verifiedMerchant: "已验证商家",
      verifiedPassport: "已验证护照",
      online: "在线",
      offline: "离线",
      completed: "已完成",
      pricePerUnit: "单价",
      banks: "银行",
      cash: "现金",
      available: "可用",
      limit: "限额",
      payMethods: "付款方法",
      minMaxAmount: "最小 - 最大交易金额",
    },
  },
  ja: {
    tableorder: {
      noOffers: "オファーが見つかりません",
      coowner: "共同所有者",
      premium: "プレミアム",
      verifiedMerchant: "認証済み業者",
      verifiedPassport: "パスポート認証済み",
      online: "オンライン",
      offline: "オフライン",
      completed: "完了済み",
      pricePerUnit: "1単位あたりの価格",
      banks: "銀行",
      cash: "現金",
      available: "利用可能",
      limit: "リミット",
      payMethods: "お支払い方法",
      minMaxAmount: "最小 - 最大取引額",
    },
  },
};

export default messages;
