const messages = {
  ru: {
    faq: {
      title: "Часто задаваемые вопросы",
      items: [
        {
          question: "Что такое криптовалютная биржа",
          answer:
            "Биржи криптовалют — это цифровые площадки, позволяющие пользователям покупать и продавать криптовалюты, такие как Биткоин, Ethereum и Tether. Биржа UmbrellaX является крупнейшей криптовалютной биржей по объему торгов.",
        },
        {
          question: "Какие продукты представлены на UmbrellaX",
          answer:
            "UmbrellaX — ведущая мировая биржа криптовалют, обслуживающая 235 миллионов зарегистрированных пользователей в более чем 180 странах.Благодаря низким комиссиям и возможности торговать более чем 350 криптовалютами, UmbrellaX является предпочтительной биржей для торговли биткоином, альткоинами и другими виртуальными активами.С помощью UmbrellaX пользователи могут: Торговать сотнями криптовалют на споте, маржой и фьючерсными контрактами. Покупайте и продавайте криптовалюту с помощью UmbrellaX P2P. Зарабатывайте проценты от своих криптовалют с помощью UmbrellaX Earn. Покупайте или зарабатывайте новые токены на UmbrellaX Launchpad. Торгуйте, инвестируйте, размещайте в стейкинге и оформляйте займы NFT на маркетплейсе UmbrellaX NFT.",
        },
        {
          question: "Как купить биткоин и другие криптовалюты на UmbrellaX",
          answer:
            "Существует несколько способов купить криптовалюту на UmbrellaX. Для покупки криптовалюты на UmbrellaX можно использовать кредитную/дебетовую карту, фиатный баланс или Apple Pay/Google Pay.Прежде чем приступить к работе, убедитесь, что вы прошли процедуру Проверки персональных данных своей учетной записи UmbrellaX.",
        },
        {
          question: "Как отслеживать цены на криптовалюту",
          answer:
            "Самый простой способ следить за последними ценами на криптовалюту, объемами торгов, популярными альткоинами и рыночной капитализацией — это Каталог криптовалют UmbrellaX. Нажмите на монету, чтобы увидеть ее историческую цену, объем торгов за 24 часа и цену криптовалюты в реальном времени, например данные Биткоина, Ethereum, BNB и других.",
        },
        {
          question: "Как торговать криптовалютой на UmbrellaX",
          answer:
            "Вы можете торговать сотнями криптовалют на UmbrellaX, используя спотовую торговлю, маржу, фьючерсные контракты и опционные контракты. Чтобы начать торговлю, пользователи должны зарегистрировать учетную запись, пройти проверку личности, купить/внести криптовалюту на аккаунт и совершить сделку.",
        },
        {
          question: "Как заработать на криптовалюте на UmbrellaX",
          answer:
            "Пользователи могут получать вознаграждения за более чем 180 криптовалют, используя продукты, предлагаемые на UmbrellaX Earn. Наша платформа предлагает десятки цифровых активов, таких как Биткоин, Ethereum, а также стейблкоины.",
        },
      ],
    },
  },
  en: {
    faq: {
      title: "Frequently Asked Questions",
      items: [
        {
          question: "What is a cryptocurrency exchange",
          answer:
            "Cryptocurrency exchanges are digital platforms that allow users to buy and sell cryptocurrencies such as Bitcoin, Ethereum, and Tether. UmbrellaX is the largest cryptocurrency exchange by trading volume.",
        },
        {
          question: "What products are available on UmbrellaX",
          answer:
            "UmbrellaX is a leading global cryptocurrency exchange serving 235 million registered users in over 180 countries. With low fees and the ability to trade more than 350 cryptocurrencies, UmbrellaX is the preferred exchange for trading Bitcoin, altcoins, and other virtual assets. With UmbrellaX, users can: Trade hundreds of cryptocurrencies in spot, margin, and futures contracts. Buy and sell cryptocurrency using UmbrellaX P2P. Earn interest on your cryptocurrencies with UmbrellaX Earn. Buy or earn new tokens on UmbrellaX Launchpad. Trade, invest, stake and take out loans on NFTs on the UmbrellaX NFT marketplace.",
        },
        {
          question:
            "How to buy Bitcoin and other cryptocurrencies on UmbrellaX",
          answer:
            "There are several ways to buy cryptocurrency on UmbrellaX. You can use a credit/debit card, fiat balance, or Apple Pay/Google Pay to buy cryptocurrency on UmbrellaX. Before getting started, make sure you have completed the Personal Data Verification procedure for your UmbrellaX account.",
        },
        {
          question: "How to track cryptocurrency prices",
          answer:
            "The easiest way to track the latest cryptocurrency prices, trading volumes, popular altcoins, and market capitalization is through the UmbrellaX Cryptocurrency Catalog. Click on a coin to see its historical price, 24-hour trading volume, and real-time cryptocurrency price, such as Bitcoin, Ethereum, BNB, and others.",
        },
        {
          question: "How to trade cryptocurrency on UmbrellaX",
          answer:
            "You can trade hundreds of cryptocurrencies on UmbrellaX using spot trading, margin, futures contracts, and options contracts. To start trading, users must register an account, verify their identity, buy/deposit cryptocurrency to their account, and make a trade.",
        },
        {
          question: "How to earn from cryptocurrency on UmbrellaX",
          answer:
            "Users can earn rewards on more than 180 cryptocurrencies using products offered on UmbrellaX Earn. Our platform offers dozens of digital assets such as Bitcoin, Ethereum, and stablecoins.",
        },
      ],
    },
  },
  zh: {
    faq: {
      title: "常见问题",
      items: [
        {
          question: "什么是加密货币交易所",
          answer:
            "加密货币交易所是允许用户买卖比特币、以太坊和泰达币等加密货币的数字平台。UmbrellaX是按交易量计算最大的加密货币交易所。",
        },
        {
          question: "UmbrellaX提供哪些产品",
          answer:
            "UmbrellaX是全球领先的加密货币交易所，为180多个国家的2.35亿注册用户提供服务。凭借低手续费和可交易350多种加密货币的能力，UmbrellaX是交易比特币、山寨币和其他虚拟资产的首选交易所。通过UmbrellaX，用户可以：在现货、保证金和期货合约中交易数百种加密货币。使用UmbrellaX P2P买卖加密货币。通过UmbrellaX Earn赚取加密货币利息。在UmbrellaX Launchpad上购买或赚取新代币。在UmbrellaX NFT市场上交易、投资、质押和贷款NFT。",
        },
        {
          question: "如何在UmbrellaX上购买比特币和其他加密货币",
          answer:
            "在UmbrellaX上购买加密货币有多种方式。您可以使用信用卡/借记卡、法币余额或Apple Pay/Google Pay在UmbrellaX上购买加密货币。开始之前，请确保您已完成UmbrellaX账户的个人数据验证程序。",
        },
        {
          question: "如何追踪加密货币价格",
          answer:
            "追踪最新加密货币价格、交易量、流行山寨币和市值的最简单方法是通过UmbrellaX加密货币目录。点击代币可查看其历史价格、24小时交易量和实时加密货币价格，如比特币、以太坊、BNB等。",
        },
        {
          question: "如何在UmbrellaX上交易加密货币",
          answer:
            "您可以在UmbrellaX上使用现货交易、保证金、期货合约和期权合约交易数百种加密货币。要开始交易，用户必须注册账户、验证身份、购买/存入加密货币到账户并进行交易。",
        },
        {
          question: "如何在UmbrellaX上通过加密货币赚钱",
          answer:
            "用户可以使用UmbrellaX Earn提供的产品在180多种加密货币上赚取奖励。我们的平台提供比特币、以太坊以及稳定币等数十种数字资产。",
        },
      ],
    },
  },
  ja: {
    faq: {
      title: "よくある質問",
      items: [
        {
          question: "暗号資産取引所とは",
          answer:
            "暗号資産取引所は、ビットコイン、イーサリアム、テザーなどの暗号資産の売買ができるデジタルプラットフォームです。UmbrellaXは取引量で最大の暗号資産取引所です。",
        },
        {
          question: "UmbrellaXではどのような商品が提供されていますか",
          answer:
            "UmbrellaXは、180カ国以上で2億3500万人の登録ユーザーにサービスを提供する世界有数の暗号資産取引所です。低手数料で350以上の暗号資産を取引できることから、ビットコイン、アルトコイン、その他の仮想資産取引に適した取引所として選ばれています。UmbrellaXでは以下のことができます：現物取引、証拠金取引、先物取引で数百の暗号資産を取引。UmbrellaX P2Pで暗号資産の売買。UmbrellaX Earnで暗号資産の利息を獲得。UmbrellaX Launchpadで新規トークンの購入や獲得。UmbrellaX NFTマーケットプレイスでNFTの取引、投資、ステーキング、ローン。",
        },
        {
          question: "UmbrellaXでビットコインやその他の暗号資産を購入する方法",
          answer:
            "UmbrellaXで暗号資産を購入する方法は複数あります。クレジットカード/デビットカード、法定通貨残高、またはApple Pay/Google Payを使用して暗号資産を購入できます。始める前に、UmbrellaXアカウントの本人確認手続きを完了していることを確認してください。",
        },
        {
          question: "暗号資産価格の追跡方法",
          answer:
            "最新の暗号資産価格、取引量、人気のアルトコイン、時価総額を追跡する最も簡単な方法は、UmbrellaX暗号資産カタログです。コインをクリックすると、ビットコイン、イーサリアム、BNBなどの過去の価格、24時間取引量、リアルタイムの暗号資産価格を確認できます。",
        },
        {
          question: "UmbrellaXでの暗号資産取引方法",
          answer:
            "UmbrellaXでは、現物取引、証拠金取引、先物取引、オプション取引を使用して数百の暗号資産を取引できます。取引を開始するには、アカウントを登録し、本人確認を行い、暗号資産を購入/入金してから取引を行う必要があります。",
        },
        {
          question: "UmbrellaXで暗号資産から収益を得る方法",
          answer:
            "ユーザーは、UmbrellaX Earnが提供する商品を使用して、180以上の暗号資産で報酬を獲得できます。当プラットフォームでは、ビットコイン、イーサリアム、ステーブルコインなど、数十種類のデジタル資産を提供しています。",
        },
      ],
    },
  },
};

export default messages;
