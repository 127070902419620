<template>
  <button @click="showSettings" class="w-full text-left">{{ $t("articleroute.pay") }}</button>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useNavigationStore } from "../stores/marketStore";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const navigationStore = useNavigationStore();

function showSettings() {
  navigationStore.setView('settings');
  router.push({ name: 'settings' }).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
}
</script>
