<template>
  <button @click="showMyAds" class="w-full text-left">{{ $t("articleroute.myads") }}</button>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useNavigationStore } from "../stores/marketStore";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const navigationStore = useNavigationStore();

function showMyAds() {
  navigationStore.setView('myAds');
  router.push({ name: 'myAds' }).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
}
</script>
