const messages = {
  ru: {
    transactions: {
      confirmTransactions: {
        confirmTransfer: "Вы собираетесь перевести",
        confirmAddress: "На адрес:",
        confirmWait: "Обработка...",
        confirmEnter: "Подтвердить перевод",
        confirmCancel: "Отменить",
      },
      confirmExchange: {
        confirm: {
          willExchange: "Вы собираетесь обменять",
          confirmButton: "Подтвердить обмен",
          cancelButton: "Отменить",
        },
      },
      confirmWithdrawal: {
        confirm: {
          willWithdraw: "Вы собираетесь вывести",
          network: "Сеть",
          toAddress: "На адрес",
          tagMemo: "Tag/MEMO",
          commission: "Комиссия",
          totalAmount: "Итоговая сумма к отправке",
          confirmButton: "Подтвердить вывод",
          cancelButton: "Отменить",
        },
      },
      wallet: {
        fullBalance: "Общий баланс",
        fullBalanceCrypto: "Общий баланс всех криптовалют",
        myCrypto: "Мои криптовалюты",
        balance: "Баланс",
        deposit: "Пополнить",
        depositRuInfo: "Оплата из РФ доступна через партнеров",
        buyWithCard: "Купить криптовалюту картой",
        depositToExchange: "Внести на биржу",
        withdraw: "Вывод",
        withdrawFromExchange: "Вывод из биржи",
        exchange: "Обмен",
        exchangeOnPlatform: "Обменять у биржи",
        history: {
          title: "История пополнений и выводов на бирже",
          cryptocurrency: "Криптовалюта",
          amount: "Сумма",
          walletAddress: "Адрес кошелька",
          time: "Время",
          network: "Сеть",
        },
      },
      actions: {
        deposit: {
          title: "Пополнить",
          ruInfo: "Оплата из РФ доступна через партнеров",
          buyWithCard: "Купить криптовалюту картой",
          depositToExchange: "Внести на биржу",
        },
        withdraw: {
          title: "Вывод",
          fromExchange: "Вывод из биржи",
        },
        exchange: {
          title: "Обмен",
          onPlatform: "Обменять у биржи",
        },
      },
      deposit: {
        history: {
          title: "История пополнений с криптовлютами",
          cryptocurrency: "Криптовалюта",
          amount: "Сумма",
          withdrawalAddress: "Адрес вывода",
          time: "Время",
          network: "Сеть",
        },
        buy: "Купить",
        ruAvailability: "Покупка из РФ доступна через партнеров",
        buyWithCardOrCash: "Купить картой или наличными",
        or: "или",
        depositToExchange: "Внести на биржу",
        depositingCoin: "Вношу монету",
        selectNetwork: "Выберите сеть",
        networkWarning: "Внимание, проверьте, что вы выбрали верно сеть,",
        networkWarningLoss: "Иначе средства могут быть утеряны безвозвратно",
        depositAddress: "Ваш адрес на нашей бирже для пополнения",
        copied: "Скопировано",
        tagMemo: "Tag/MEMO (если требуется)",
        tagMemoWarning: "Внимание, проверьте, что вы указали верный Tag/MEMO",
        tagMemoWarningLoss: "Иначе средства могут быть утеряны безвозвратно",
        zeroCommission: "0% комиссия",
        cashbackUp: "Кешбек до",
      },
      transfer: {
        myAccount: "Мой счет",
        myAccountFull: "Мой счет для переводов внутри биржи",
        recipient: {
          title: "Кому перевести внутри биржи",
          placeholder: "Счет или логин получателя",
        },
        amount: {
          title: "Сумма к отправке",
          yourBalance: "У вас {balance} {currency}",
          commission: "0% комиссия",
        },
        comment: {
          title: "Комментарий (Необязательно)",
        },
        buttons: {
          submit: "Перевести",
          processing: "Обработка...",
        },
        errors: {
          requiredFields: "Пожалуйста, заполните все обязательные поля",
          selectCurrency: "Пожалуйста, выберите валюту",
          invalidAmount: "Пожалуйста, введите корректную сумму",
          insufficientFunds: "Недостаточно средств",
          defaultError: "Произошла ошибка при переводе",
        },
        history: {
          title: "История переводов внутри биржи",
          columns: {
            amount: "Сумма",
            accountNumber: "Номер счета",
            time: "Время",
          },
          status: {
            received: "Пришло",
            sent: "Отправлено",
          },
          loading: "Загрузка...",
        },
      },
      exchange: {
        from: {
          title: "Вы отдаете",
          balance: "У вас {amount} {currency}",
        },
        to: {
          title: "Вы получаете",
          balance: "У вас {amount} {currency}",
        },
        buttons: {
          exchange: "Обменять",
          insufficientFunds: "Недостаточно средств",
        },
        history: {
          title: "История обмена",
          columns: {
            sent: "Вы отдали",
            received: "Вы получили",
            time: "Время",
          },
          mobile: {
            sent: "Отдали",
            received: "Получили",
          },
        },
      },
      withdraw: {
        title: "Вывод из биржи",
        form: {
          selectCoin: "Вывожу монету",
          selectNetwork: "Выберите сеть",
          warning: {
            attention: "Внимание, проверьте, что вы выбрали верно сеть,",
            lossWarning: "Иначе средства могут быть утеряны безвозвратно",
          },
          address: {
            title: "Внешний адрес для вывода из биржи",
            placeholder: "Внешний адрес куда перевести",
          },
          tagMemo: {
            title: "Tag/MEMO (если требуется)",
            placeholder: "Введите Tag/MEMO",
          },
          amount: {
            title: "Сумма",
            placeholder: "Введите кол-во монет",
          },
          commission: {
            checkbox: "Вычитать комиссию из суммы",
            info: "-0.1% комиссия (не менее 1.9$)",
          },
          total: {
            title: "Итоговая сумма к отправке",
            balance: "У вас {balance} {currency}",
          },
        },
        buttons: {
          confirm: "Подтвердить",
          insufficientFunds: "Недостаточно средств",
        },
        errors: {
          selectCoin: "Пожалуйста, выберите монету",
          selectNetwork: "Пожалуйста, выберите сеть",
          enterAddress: "Пожалуйста, введите адрес для вывода",
          invalidAmount: "Пожалуйста, введите корректную сумму",
          insufficientFunds: "Недостаточно средств для вывода",
        },
        history: {
          title: "История вывода из биржи",
          columns: {
            cryptocurrency: "Криптовалюта",
            amount: "Сумма",
            withdrawalAddress: "Адрес вывода",
            time: "Время",
          },
        },
      },
    },
  },

  en: {
    transactions: {
      confirmTransactions: {
        confirmTransfer: "You are about to transfer",
        confirmAddress: "To address:",
        confirmWait: "Processing...",
        confirmEnter: "Confirm transfer",
        confirmCancel: "Cancel",
      },
      confirmExchange: {
        confirm: {
          willExchange: "You are going to exchange",
          confirmButton: "Confirm Exchange",
          cancelButton: "Cancel",
        },
      },
      confirmWithdrawal: {
        confirm: {
          willWithdraw: "You are going to withdraw",
          network: "Network",
          toAddress: "To address",
          tagMemo: "Tag/MEMO",
          commission: "Commission",
          totalAmount: "Total amount to send",
          confirmButton: "Confirm Withdrawal",
          cancelButton: "Cancel",
        },
      },
      wallet: {
        fullBalance: "Total Balance",
        fullBalanceCrypto: "Total Cryptocurrency Balance",
        myCrypto: "My Cryptocurrencies",
        balance: "Balance",
        deposit: "Deposit",
        depositRuInfo: "Payment from RF available through partners",
        buyWithCard: "Buy cryptocurrency with card",
        depositToExchange: "Deposit to exchange",
        withdraw: "Withdraw",
        withdrawFromExchange: "Withdraw from exchange",
        exchange: "Exchange",
        exchangeOnPlatform: "Exchange on platform",
        history: {
          title: "Deposit and Withdrawal History",
          cryptocurrency: "Cryptocurrency",
          amount: "Amount",
          walletAddress: "Wallet Address",
          time: "Time",
          network: "Network",
        },
      },
      actions: {
        deposit: {
          title: "Deposit",
          ruInfo: "Payment from RF available through partners",
          buyWithCard: "Buy with card",
          depositToExchange: "Deposit to exchange",
        },
        withdraw: {
          title: "Withdraw",
          fromExchange: "Withdraw from exchange",
        },
        exchange: {
          title: "Exchange",
          onPlatform: "Exchange on platform",
        },
      },
      deposit: {
        history: {
          title: "Cryptocurrency Deposit History",
          cryptocurrency: "Cryptocurrency",
          amount: "Amount",
          withdrawalAddress: "Withdrawal Address",
          time: "Time",
          network: "Network",
        },
        buy: "Buy",
        ruAvailability: "Purchase from RF available through partners",
        buyWithCardOrCash: "Buy with card or cash",
        or: "or",
        depositToExchange: "Deposit to exchange",
        depositingCoin: "Depositing coin",
        selectNetwork: "Select network",
        networkWarning:
          "Attention, please verify you've selected the correct network,",
        networkWarningLoss: "Otherwise funds may be lost irretrievably",
        depositAddress: "Your deposit address on our exchange",
        copied: "Copied",
        tagMemo: "Tag/MEMO (if required)",
        tagMemoWarning:
          "Attention, please verify you've entered the correct Tag/MEMO",
        tagMemoWarningLoss: "Otherwise funds may be lost irretrievably",
        zeroCommission: "0% commission",
        cashbackUp: "Cashback up to",
      },
      transfer: {
        myAccount: "My Account",
        myAccountFull: "My account for internal exchange transfers",
        recipient: {
          title: "Transfer to within exchange",
          placeholder: "Account or recipient's login",
        },
        amount: {
          title: "Amount to send",
          yourBalance: "You have {balance} {currency}",
          commission: "0% commission",
        },
        comment: {
          title: "Comment (Optional)",
        },
        buttons: {
          submit: "Transfer",
          processing: "Processing...",
        },
        errors: {
          requiredFields: "Please fill in all required fields",
          selectCurrency: "Please select a currency",
          invalidAmount: "Please enter a valid amount",
          insufficientFunds: "Insufficient funds",
          defaultError: "An error occurred during transfer",
        },
        history: {
          title: "Internal Exchange Transfer History",
          columns: {
            amount: "Amount",
            accountNumber: "Account Number",
            time: "Time",
          },
          status: {
            received: "Received",
            sent: "Sent",
          },
          loading: "Loading...",
        },
      },
      exchange: {
        from: {
          title: "You give",
          balance: "You have {amount} {currency}",
        },
        to: {
          title: "You receive",
          balance: "You have {amount} {currency}",
        },
        buttons: {
          exchange: "Exchange",
          insufficientFunds: "Insufficient funds",
        },
        history: {
          title: "Exchange History",
          columns: {
            sent: "You sent",
            received: "You received",
            time: "Time",
          },
          mobile: {
            sent: "Sent",
            received: "Received",
          },
        },
      },
      withdraw: {
        title: "Withdraw from Exchange",
        form: {
          selectCoin: "Withdrawing coin",
          selectNetwork: "Select network",
          warning: {
            attention:
              "Attention, please verify you've selected the correct network,",
            lossWarning: "Otherwise funds may be lost irretrievably",
          },
          address: {
            title: "External withdrawal address",
            placeholder: "External address to transfer to",
          },
          tagMemo: {
            title: "Tag/MEMO (if required)",
            placeholder: "Enter Tag/MEMO",
          },
          amount: {
            title: "Amount",
            placeholder: "Enter number of coins",
          },
          commission: {
            checkbox: "Deduct commission from amount",
            info: "-0.1% commission (minimum $1.9)",
          },
          total: {
            title: "Total amount to send",
            balance: "You have {balance} {currency}",
          },
        },
        buttons: {
          confirm: "Confirm",
          insufficientFunds: "Insufficient funds",
        },
        errors: {
          selectCoin: "Please select a coin",
          selectNetwork: "Please select a network",
          enterAddress: "Please enter withdrawal address",
          invalidAmount: "Please enter a valid amount",
          insufficientFunds: "Insufficient funds for withdrawal",
        },
        history: {
          title: "Withdrawal History",
          columns: {
            cryptocurrency: "Cryptocurrency",
            amount: "Amount",
            withdrawalAddress: "Withdrawal Address",
            time: "Time",
          },
        },
      },
    },
  },

  zh: {
    transactions: {
      confirmTransactions: {
        confirmTransfer: "您即将转账",
        confirmAddress: "转入地址：",
        confirmWait: "处理中...",
        confirmEnter: "确认转账",
        confirmCancel: "取消",
      },
      confirmExchange: {
        confirm: {
          willExchange: "您即将兑换",
          confirmButton: "确认兑换",
          cancelButton: "取消",
        },
      },
      confirmWithdrawal: {
        confirm: {
          willWithdraw: "您即将提现",
          network: "网络",
          toAddress: "提现地址",
          tagMemo: "Tag/MEMO",
          commission: "手续费",
          totalAmount: "总发送金额",
          confirmButton: "确认提现",
          cancelButton: "取消",
        },
      },
      wallet: {
        fullBalance: "总余额",
        fullBalanceCrypto: "加密货币总余额",
        myCrypto: "我的加密货币",
        balance: "余额",
        deposit: "充值",
        depositRuInfo: "俄罗斯支付通过合作伙伴提供",
        buyWithCard: "用卡购买加密货币",
        depositToExchange: "充值到交易所",
        withdraw: "提现",
        withdrawFromExchange: "从交易所提现",
        exchange: "兑换",
        exchangeOnPlatform: "在平台上兑换",
        history: {
          title: "充值和提现历史",
          cryptocurrency: "加密货币",
          amount: "金额",
          walletAddress: "钱包地址",
          time: "时间",
          network: "网络",
        },
      },
      actions: {
        deposit: {
          title: "充值",
          ruInfo: "俄罗斯支付通过合作伙伴提供",
          buyWithCard: "用卡购买",
          depositToExchange: "充值到交易所",
        },
        withdraw: {
          title: "提现",
          fromExchange: "从交易所提现",
        },
        exchange: {
          title: "兑换",
          onPlatform: "在平台上兑换",
        },
      },
      deposit: {
        history: {
          title: "加密货币充值历史",
          cryptocurrency: "加密货币",
          amount: "金额",
          withdrawalAddress: "提现地址",
          time: "时间",
          network: "网络",
        },
        buy: "购买",
        ruAvailability: "通过合作伙伴从俄罗斯购买",
        buyWithCardOrCash: "用卡或现金购买",
        or: "或",
        depositToExchange: "充值到交易所",
        depositingCoin: "充值币种",
        selectNetwork: "选择网络",
        networkWarning: "注意，请确认您选择了正确的网络，",
        networkWarningLoss: "否则资金可能会永久丢失",
        depositAddress: "您在我们交易所的充值地址",
        copied: "已复制",
        tagMemo: "Tag/MEMO（如需要）",
        tagMemoWarning: "注意，请确认您输入了正确的Tag/MEMO",
        tagMemoWarningLoss: "否则资金可能会永久丢失",
        zeroCommission: "0%手续费",
        cashbackUp: "最高返现",
      },
      transfer: {
        myAccount: "我的账户",
        myAccountFull: "我的交易所内部转账账户",
        recipient: {
          title: "转账至交易所内",
          placeholder: "账户或收款人登录名",
        },
        amount: {
          title: "发送金额",
          yourBalance: "您有 {balance} {currency}",
          commission: "0%手续费",
        },
        comment: {
          title: "备注（可选）",
        },
        buttons: {
          submit: "转账",
          processing: "处理中...",
        },
        errors: {
          requiredFields: "请填写所有必填字段",
          selectCurrency: "请选择货币",
          invalidAmount: "请输入有效金额",
          insufficientFunds: "余额不足",
          defaultError: "转账过程中出错",
        },
        history: {
          title: "交易所内部转账历史",
          columns: {
            amount: "金额",
            accountNumber: "账号",
            time: "时间",
          },
          status: {
            received: "已收到",
            sent: "已发送",
          },
          loading: "加载中...",
        },
      },
      exchange: {
        from: {
          title: "您付出",
          balance: "您有 {amount} {currency}",
        },
        to: {
          title: "您收到",
          balance: "您有 {amount} {currency}",
        },
        buttons: {
          exchange: "兑换",
          insufficientFunds: "余额不足",
        },
        history: {
          title: "兑换历史",
          columns: {
            sent: "您已发送",
            received: "您已收到",
            time: "时间",
          },
          mobile: {
            sent: "已发送",
            received: "已收到",
          },
        },
      },
      withdraw: {
        title: "从交易所提现",
        form: {
          selectCoin: "提现币种",
          selectNetwork: "选择网络",
          warning: {
            attention: "注意，请确认您选择了正确的网络，",
            lossWarning: "否则资金可能会永久丢失",
          },
          address: {
            title: "外部提现地址",
            placeholder: "转账至的外部地址",
          },
          tagMemo: {
            title: "Tag/MEMO（如需要）",
            placeholder: "输入Tag/MEMO",
          },
          amount: {
            title: "金额",
            placeholder: "输入币数量",
          },
          commission: {
            checkbox: "从金额中扣除手续费",
            info: "-0.1%手续费（最低$1.9）",
          },
          total: {
            title: "发送总额",
            balance: "您有 {balance} {currency}",
          },
        },
        buttons: {
          confirm: "确认",
          insufficientFunds: "余额不足",
        },
        errors: {
          selectCoin: "请选择币种",
          selectNetwork: "请选择网络",
          enterAddress: "请输入提现地址",
          invalidAmount: "请输入有效金额",
          insufficientFunds: "提现余额不足",
        },
        history: {
          title: "提现历史",
          columns: {
            cryptocurrency: "加密货币",
            amount: "金额",
            withdrawalAddress: "提现地址",
            time: "时间",
          },
        },
      },
    },
  },

  ja: {
    transactions: {
      confirmTransactions: {
        confirmTransfer: "送金しようとしています",
        confirmAddress: "送金先アドレス：",
        confirmWait: "処理中...",
        confirmEnter: "送金を確認",
        confirmCancel: "キャンセル",
      },
      confirmExchange: {
        confirm: {
          willExchange: "交換しようとしています",
          confirmButton: "交換を確認",
          cancelButton: "キャンセル",
        },
      },
      confirmWithdrawal: {
        confirm: {
          willWithdraw: "出金しようとしています",
          network: "ネットワーク",
          toAddress: "送金先アドレス",
          tagMemo: "Tag/MEMO",
          commission: "手数料",
          totalAmount: "送金総額",
          confirmButton: "出金を確認",
          cancelButton: "キャンセル",
        },
      },
      wallet: {
        fullBalance: "総残高",
        fullBalanceCrypto: "暗号資産総残高",
        myCrypto: "保有暗号資産",
        balance: "残高",
        deposit: "入金",
        depositRuInfo: "ロシアからの支払いはパートナー経由で利用可能",
        buyWithCard: "カードで暗号資産を購入",
        depositToExchange: "取引所に入金",
        withdraw: "出金",
        withdrawFromExchange: "取引所から出金",
        exchange: "両替",
        exchangeOnPlatform: "プラットフォームで両替",
        history: {
          title: "入出金履歴",
          cryptocurrency: "暗号資産",
          amount: "金額",
          walletAddress: "ウォレットアドレス",
          time: "時間",
          network: "ネットワーク",
        },
      },
      actions: {
        deposit: {
          title: "入金",
          ruInfo: "ロシアからの支払いはパートナー経由で利用可能",
          buyWithCard: "カードで購入",
          depositToExchange: "取引所に入金",
        },
        withdraw: {
          title: "出金",
          fromExchange: "取引所から出金",
        },
        exchange: {
          title: "両替",
          onPlatform: "プラットフォームで両替",
        },
      },
      deposit: {
        history: {
          title: "暗号資産入金履歴",
          cryptocurrency: "暗号資産",
          amount: "金額",
          withdrawalAddress: "出金アドレス",
          time: "時間",
          network: "ネットワーク",
        },
        buy: "購入",
        ruAvailability: "ロシアからの購入はパートナー経由で利用可能",
        buyWithCardOrCash: "カードまたは現金で購入",
        or: "または",
        depositToExchange: "取引所に入金",
        depositingCoin: "入金コイン",
        selectNetwork: "ネットワークを選択",
        networkWarning:
          "注意：正しいネットワークを選択したことを確認してください。",
        networkWarningLoss: "誤った場合、資金が永久に失われる可能性があります",
        depositAddress: "取引所の入金アドレス",
        copied: "コピー済み",
        tagMemo: "Tag/MEMO（必要な場合）",
        tagMemoWarning: "注意：正しいTag/MEMOを入力したことを確認してください",
        tagMemoWarningLoss: "誤った場合、資金が永久に失われる可能性があります",
        zeroCommission: "手数料0%",
        cashbackUp: "最大キャッシュバック",
      },
      transfer: {
        myAccount: "マイアカウント",
        myAccountFull: "取引所内送金用マイアカウント",
        recipient: {
          title: "取引所内送金先",
          placeholder: "アカウントまたは受取人のログイン",
        },
        amount: {
          title: "送金額",
          yourBalance: "残高：{balance} {currency}",
          commission: "手数料0%",
        },
        comment: {
          title: "コメント（任意）",
        },
        buttons: {
          submit: "送金",
          processing: "処理中...",
        },
        errors: {
          requiredFields: "必須項目をすべて入力してください",
          selectCurrency: "通貨を選択してください",
          invalidAmount: "有効な金額を入力してください",
          insufficientFunds: "残高不足",
          defaultError: "送金中にエラーが発生しました",
        },
        history: {
          title: "取引所内送金履歴",
          columns: {
            amount: "金額",
            accountNumber: "アカウント番号",
            time: "時間",
          },
          status: {
            received: "受取済み",
            sent: "送金済み",
          },
          loading: "読み込み中...",
        },
      },
      exchange: {
        from: {
          title: "支払い",
          balance: "残高：{amount} {currency}",
        },
        to: {
          title: "受取り",
          balance: "残高：{amount} {currency}",
        },
        buttons: {
          exchange: "両替",
          insufficientFunds: "残高不足",
        },
        history: {
          title: "両替履歴",
          columns: {
            sent: "支払額",
            received: "受取額",
            time: "時間",
          },
          mobile: {
            sent: "支払済",
            received: "受取済",
          },
        },
      },
      withdraw: {
        title: "取引所から出金",
        form: {
          selectCoin: "出金コイン",
          selectNetwork: "ネットワークを選択",
          warning: {
            attention:
              "注意：正しいネットワークを選択したことを確認してください。",
            lossWarning: "誤った場合、資金が永久に失われる可能性があります",
          },
          address: {
            title: "外部出金アドレス",
            placeholder: "送金先の外部アドレス",
          },
          tagMemo: {
            title: "Tag/MEMO（必要な場合）",
            placeholder: "Tag/MEMOを入力",
          },
          amount: {
            title: "金額",
            placeholder: "コイン数を入力",
          },
          commission: {
            checkbox: "金額から手数料を差し引く",
            info: "手数料-0.1%（最低$1.9）",
          },
          total: {
            title: "送金総額",
            balance: "残高：{balance} {currency}",
          },
        },
        buttons: {
          confirm: "確認",
          insufficientFunds: "残高不足",
        },
        errors: {
          selectCoin: "コインを選択してください",
          selectNetwork: "ネットワークを選択してください",
          enterAddress: "出金アドレスを入力してください",
          invalidAmount: "有効な金額を入力してください",
          insufficientFunds: "出金に必要な残高が不足しています",
        },
        history: {
          title: "出金履歴",
          columns: {
            cryptocurrency: "暗号資産",
            amount: "金額",
            withdrawalAddress: "出金アドレス",
            time: "時間",
          },
        },
      },
    },
  },
};

export default messages;
