const messages = {
  ru: {
    landing: {
      hero: {
        title: "Покупайте крипту с низкими комиссиями на UmbrellaX",
        emailPlaceholder: "Ваша электронная почта",
        registerButton: "Зарегистрироваться",
        oneClickRegister: {
          desktop: "Регистрация в 1 клик через",
          mobile: "Зарегистрироваться в один клик",
        },
      },
      cryptoSection: {
        availableCrypto: "Доступные криптовалюты",
      },
      mobileApp: {
        title: "Торгуйте на ходу. Где и когда угодно.",
        qrCodeText: "Отсканируйте QR-код, чтобы загрузить приложение",
      },
      p2pTrading: {
        title: "P2P трейдинг",
        description:
          "P2P трейдинг предоставляет уникальную возможность торговать криптовалютой напрямую с другими пользователями. Этот способ обмена обеспечивает:",
        benefits: {
          flexibility: "Гибкие условия сделок",
          payments: "Множество способов оплаты",
          rates: "Выгодные курсы обмена",
          security: "Безопасность сделок",
        },
        button: "P2P трейдинг",
      },
    },
  },
  en: {
    landing: {
      hero: {
        title: "Buy crypto with low fees on UmbrellaX",
        emailPlaceholder: "Your email",
        registerButton: "Sign Up",
        oneClickRegister: {
          desktop: "One-click registration via",
          mobile: "Register in one click",
        },
      },
      cryptoSection: {
        availableCrypto: "Available cryptocurrencies",
      },
      mobileApp: {
        title: "Trade on the go. Anywhere, anytime.",
        qrCodeText: "Scan QR code to download the app",
      },
      p2pTrading: {
        title: "P2P Trading",
        description:
          "P2P trading provides a unique opportunity to trade cryptocurrency directly with other users. This exchange method ensures:",
        benefits: {
          flexibility: "Flexible trading conditions",
          payments: "Multiple payment methods",
          rates: "Favorable exchange rates",
          security: "Transaction security",
        },
        button: "P2P Trading",
      },
    },
  },
  zh: {
    landing: {
      hero: {
        title: "在UmbrellaX以低手续费购买加密货币",
        emailPlaceholder: "您的电子邮件",
        registerButton: "注册",
        oneClickRegister: {
          desktop: "通过以下方式一键注册",
          mobile: "一键注册",
        },
      },
      cryptoSection: {
        availableCrypto: "可用加密货币",
      },
      mobileApp: {
        title: "随时随地交易。",
        qrCodeText: "扫描二维码下载应用",
      },
      p2pTrading: {
        title: "P2P交易",
        description:
          "P2P交易为您提供与其他用户直接交易加密货币的独特机会。这种交易方式确保：",
        benefits: {
          flexibility: "灵活的交易条件",
          payments: "多种支付方式",
          rates: "优惠的汇率",
          security: "交易安全",
        },
        button: "P2P交易",
      },
    },
  },
  ja: {
    landing: {
      hero: {
        title: "UmbrellaXで低手数料の暗号資産取引",
        emailPlaceholder: "メールアドレス",
        registerButton: "登録",
        oneClickRegister: {
          desktop: "ワンクリック登録",
          mobile: "ワンクリックで登録",
        },
      },
      cryptoSection: {
        availableCrypto: "取扱暗号資産",
      },
      mobileApp: {
        title: "いつでもどこでも取引可能。",
        qrCodeText: "QRコードをスキャンしてアプリをダウンロード",
      },
      p2pTrading: {
        title: "P2P取引",
        description:
          "P2P取引では、他のユーザーと直接暗号資産を取引できる独自の機会を提供します。この取引方法では以下が保証されます：",
        benefits: {
          flexibility: "柔軟な取引条件",
          payments: "複数の支払い方法",
          rates: "有利な為替レート",
          security: "取引の安全性",
        },
        button: "P2P取引",
      },
    },
  },
};

export default messages;
