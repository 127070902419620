<template>
  <div class="flex items-center gap-x-[5px] justify-start">
    <span class="relative"> {{ $t("articleroute.spot") }} </span>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
