<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.25 23.5C17.5221 23.4999 19.7298 22.7451 21.5264 21.3544C23.3231 19.9636 24.607 18.0155 25.1765 15.8159M15.25 23.5C12.9779 23.4999 10.7702 22.7451 8.97355 21.3544C7.17688 19.9636 5.89298 18.0155 5.32345 15.8159M15.25 23.5C18.0801 23.5 20.375 18.9103 20.375 13.25C20.375 7.58972 18.0801 3 15.25 3M15.25 23.5C12.4199 23.5 10.125 18.9103 10.125 13.25C10.125 7.58972 12.4199 3 15.25 3M25.1765 15.8159C25.3872 14.9959 25.5 14.1361 25.5 13.25C25.5028 11.4871 25.0488 9.7536 24.1823 8.21839M25.1765 15.8159C22.1393 17.4996 18.7227 18.3804 15.25 18.375C11.6488 18.375 8.2652 17.4468 5.32345 15.8159M5.32345 15.8159C5.10791 14.9777 4.99923 14.1155 5 13.25C5 11.4221 5.47834 9.70464 6.3177 8.21839M15.25 3C17.0679 2.99924 18.8533 3.48216 20.423 4.3992C21.9927 5.31624 23.2902 6.63438 24.1823 8.21839M15.25 3C13.4321 2.99924 11.6466 3.48216 10.0769 4.3992C8.50725 5.31624 7.2098 6.63438 6.3177 8.21839M24.1823 8.21839C21.7026 10.3663 18.5306 11.5464 15.25 11.5417C11.8356 11.5417 8.71278 10.2889 6.3177 8.21839"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
