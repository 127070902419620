<template>
  <div
    class="flex relative flex-col justify-center items-center pb-[12px] px-[40px] desktoponly:px-[30px] mobile:px-[20px] border-b"
  >
    <div class="flex flex-col gap-[6px] mb-[12px] w-full">
      <div class="flex items-start w-full">
        <p
          class="text-[14px] desktoponly:text-[12px] text-[#797979] font-[300]"
        >
          {{ $t("articlebalance.hello") }}
        </p>
        <span class="text-[14px] desktoponly:text-[12px] font-[500]">
          &nbsp;{{ user?.username || $t("articlebalance.guest") }}
        </span>
      </div>

      <div class="flex items-center w-full">
        <p
          class="desktoponly:hidden text-[14px] text-nowrap text-[#797979] font-[300]"
        >
          {{ $t("articlebalance.balance") }}&nbsp;
        </p>
        <p
          class="desktoponly:block hidden text-[12px] text-nowrap text-[#797979] font-[300]"
        >
          {{ $t("articlebalance.balanceShort") }}&nbsp;
        </p>
        <span
          v-if="!hideBalance"
          class="text-[#2ecc71] text-[14px] desktoponly:text-[12px] font-light"
        >
          {{ formatBalance(totalBalance) }}
        </span>
        <span
          v-else
          class="text-[#2ecc71] text-[14px] desktoponly:text-[12px] font-light"
          >****&nbsp;</span
        >
        <button @click="hideBalance = !hideBalance" class="ml-2">
          <EyeOffIcon
            v-if="hideBalance"
            class="h-[14px] w-[14px] desktoponly:w-[11px] desktoponly:h-[11px] mr-1 text-[#797979]"
          />
          <EyeIcon
            v-else
            class="h-[14px] w-[14px] desktoponly:w-[11px] desktoponly:h-[11px] mr-1 text-[#797979]"
          />
        </button>
        <ArticleCurrency />
      </div>

      <div class="flex items-center w-full">
        <p
          class="text-[14px] desktoponly:text-[12px] text-[#797979] font-[300]"
        >
          {{ $t("articlebalance.status") }}
        </p>
        <span class="text-[14px] desktoponly:text-[12px] font-[500]">
          &nbsp;{{ formatUserStatus }}&nbsp;&nbsp;
        </span>
        <BuyVipBtn @click="openModalVip" class="desktoponly:w-[100px]" />
      </div>
    </div>
    <BuyVipModal v-if="isModalOpen" @close="closeModalVip" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/userStore";
import { useRateStore } from "@/stores/rateStore";
import { useCurrencySelectionStore } from "@/stores/currencySelectionStore";
import BuyVipBtn from "./BuyVipBtn.vue";
import ArticleCurrency from "./ArticleCurrency.vue";
import EyeIcon from "../components/icons/EyeIcon.vue";
import EyeOffIcon from "../components/icons/EyeOffIcon.vue";
import BuyVipModal from "./BuyVipModal.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const userStore = useUserStore();
const rateStore = useRateStore();
const currencySelectionStore = useCurrencySelectionStore();
const { user } = storeToRefs(userStore);

const isModalOpen = ref(false);
const hideBalance = ref(false);

const totalBalance = computed(() => {
  if (!user.value?.balance) return 0;

  return Object.entries(user.value.balance).reduce(
    (total, [ticker, amount]) => {
      const rate =
        rateStore.cryptoRates?.fiat?.[ticker]?.[
          currencySelectionStore.selectedCurrency
        ] || 0;
      return total + parseFloat(amount) * rate;
    },
    0
  );
});

const formatBalance = (value) => {
  if (!value) return "0";

  return new Intl.NumberFormat("ru-RU").format(Math.floor(value));
};

const formatUserStatus = computed(() => {
  const status = user.value?.status || "newer";
  return t(`articlebalance.statuses.${status}`);
});

const openModalVip = () => {
  isModalOpen.value = true;
};

const closeModalVip = () => {
  isModalOpen.value = false;
};

onMounted(() => {
  if (user.value?.balance) {
    rateStore.setBalances(user.value.balance);
    rateStore.startAutoUpdate();
  }
  rateStore.fetchAllRates();
});

onUnmounted(() => {
  rateStore.stopAutoUpdate();
});
</script>
