<template>
  <div
    class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[9999] flex justify-center items-center overflow-y-auto"
  >
    <div
      class="flex flex-col bg-white rounded-lg w-4/5 max-w-[414px] mobile:w-[100%] mobile:h-full mobile:max-w-full mobile:rounded-none absolute shadow-in mobile:items-center mobile:top-1 tablet:top-1"
    >
      <ModalHeader @close="close" class="mobile:rounded-none w-full" />

      <div
        class="flex flex-col items-center p-2 pb-[50px] px-[57px] mobile:px-4 mobile:max-w-[360px]"
      >
        <h2
          class="text-[16px] font-[500] mb-[6px] pt-3 leading-[100%] text-black"
        >
          {{ $t("auth.signUp.title") }}
        </h2>
        <p
          class="text-[10px] font-[500] max-[767px]:text-[9px] text-[#797979] mb-2"
        >
          {{ $t("auth.signUp.haveAccount") }}
          <a
            href="#"
            @click.prevent="openLoginForm"
            class="relative bg-gradient-to-l from-[#6198fa] to-[#ff1dd9] inline-block text-transparent bg-clip-text group"
          >
            {{ $t("auth.signUp.login") }}
            <span
              class="absolute left-0 bottom-0 w-full h-[1px] bg-gradient-to-l from-[#6198fa] to-[#ff1dd9] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
            ></span>
          </a>
        </p>

        <div class="mt-4 max-[767px]:mt-2">
          <div class="flex flex-col gap-[4px] mb-4">
            <TelegramEntry
              :showSlotReg="true"
              class="!w-[260px] !text-[14px] !justify-start !gap-[20px]"
              @click="handleSocialAuth('telegram')"
            />
            <GoogleEntry
              :showSlotReg="true"
              class="mb-2 !w-[260px] !text-[14px] !justify-start !gap-[20px]"
              @click="handleSocialAuth('google')"
            />
          </div>
        </div>

        <div class="flex flex-col mb-2 max-[767px]:mb-1 w-full">
          <input
            v-model="referralCode"
            type="text"
            id="referralCode"
            class="w-full p-2 pl-4 max-[767px]:py-[4px] text-sm mobile:text-[12px] shadow-in rounded-lg text-[#797979] focus:outline-none focus:ring-1 focus:ring-[#4ADE80] mobile:placeholder:text-[12px]"
            :placeholder="$t('auth.signUp.referralCode')"
          />
        </div>
        <RegisterCheckbox v-model:formData="formData" :errors="errors" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useSocialAuthStore } from "@/stores/socialAuthStore";
import { useRouter, useRoute } from "vue-router";
import ModalHeader from "@/components/ModalDeal/ModalBlocks/ModalHeader.vue";
import GoogleEntry from "./GoogleEntry.vue";
import TelegramEntry from "./TelegramEntry.vue";
import RegisterCheckbox from "./RegisterCheckbox.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const socialAuthStore = useSocialAuthStore();
const emit = defineEmits(["close", "switch-form"]);
const error = ref(null);

const formData = ref({
  not_us_resident: false,
  agree_to_terms: false,
  agree_to_marketing: false,
  agree_to_asset_use: false,
  agree_to_price_regulation: false,
});

const errors = ref({});

const validateCheckboxes = () => {
  return new Promise((resolve, reject) => {
    const requiredFields = [
      "not_us_resident",
      "agree_to_terms",
      "agree_to_asset_use",
      "agree_to_price_regulation",
    ];

    const invalidFields = requiredFields.filter(
      (field) => !formData.value[field]
    );

    if (invalidFields.length === 0) {
      resolve();
    } else {
      errors.value = Object.fromEntries(
        invalidFields.map((field) => [
          field,
          "Это поле обязательно для заполнения",
        ])
      );
      reject(new Error("Please check all required fields"));
    }
  });
};

const handleSocialAuth = (provider) => {
  error.value = null;
  errors.value = {};

  return validateCheckboxes()
    .then(() => socialAuthStore.onSocialButtonClicked(provider))
    .then((result) => {
      if (result?.token) {
        emit("switch-form", "google-telegram", { type: provider });
      }
    })
    .catch((err) => {
      error.value = err.message || `Ошибка авторизации через ${provider}`;
    });
};

onMounted(() => {
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://telegram.org/js/telegram-widget.js";
  document.head.appendChild(script);
});

const close = () => {
  emit("close");
};

const openLoginForm = () => {
  emit("switch-form", "login");
};
</script>
