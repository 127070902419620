<template>
  <div
    class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[9999] flex justify-center items-center overflow-y-auto"
  >
    <div
      class="flex flex-col bg-white rounded-lg w-4/5 max-w-[414px] mobile:w-[100%] mobile:h-full mobile:max-w-full mobile:rounded-none absolute shadow-md mobile:items-center mobile:top-1 tablet:top-1"
    >
      <ModalHeader @close="close" class="mobile:rounded-none w-full" />

      <div
        class="p-6 mobile:px-4 flex flex-col items-center mobile:max-w-[360px]"
      >
        <h2
          class="text-[16px] font-[500] mb-[6px] leading-[100%] text-[#797979]"
        >
          {{ $t("auth.login.title") }}
        </h2>
        <p class="text-[10px] font-[500] text-[#797979] mb-2">
          {{ $t("auth.login.noAccount") }}

          <a
            href="#"
            @click.prevent="openRegisterForm"
            class="relative bg-gradient-to-l from-[#6198fa] to-[#ff1dd9] inline-block text-transparent bg-clip-text group"
          >
            {{ $t("auth.login.register") }}
            <span
              class="absolute left-0 bottom-0 w-full h-[1px] bg-gradient-to-l from-[#6198fa] to-[#ff1dd9] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
            ></span>
          </a>
        </p>
        <form
          @submit.prevent="handleSubmit"
          autocomplete="on"
          class="flex flex-col w-[300px] mobile:w-full text-[#797979] border-b border-[#797979] pb-[20px]"
        >
          <div class="mb-4">
            <p class="pl-[3px] mb-[10px] text-[13px] text-[#797979]">
              {{ $t("auth.login.emailOrLogin") }}
            </p>
            <input
              v-model="login"
              type="text"
              required
              autofocus
              autocomplete="on"
              class="w-full p-3 shadow-in rounded-lg text-sm"
              :placeholder="$t('auth.login.placeholders.emailOrLogin')"
            />
          </div>
          <div class="mb-4">
            <p class="pl-[3px] mb-[10px] text-[13px] text-[#797979]">
              {{ $t("auth.login.password") }}
            </p>
            <div class="relative">
              <input
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                required
                autocomplete="current-password"
                class="w-full p-3 shadow-in rounded-lg text-sm"
                :placeholder="$t('auth.login.placeholders.password')"
              />
              <button
                type="button"
                @click="showPassword = !showPassword"
                class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              >
                <EyeOffIcon
                  v-if="!showPassword"
                  class="h-6 w-6 text-[#797979]"
                />
                <EyeIcon v-else class="h-6 w-6 text-[#797979]" />
              </button>
            </div>
          </div>

          <a
            href="#"
            @click.prevent="openResetPassword"
            class="text-sm text-blue-500 text-end mb-4"
            >{{ $t("auth.login.resetPassword") }}</a
          >
          <button
            type="submit"
            class="bg-[#4ADE80] hover:bg-[#4ADE80]/40 text-white font-bold py-3 mobile:py-2 px-4 rounded-lg"
            :disabled="authStore.isLoading"
          >
            {{
              authStore.isLoading
                ? $t("auth.login.loading")
                : $t("auth.login.loginButton")
            }}
          </button>
          <div
            v-if="generalError"
            class="error mt-4 text-[#FF9387] text-[12px] text-center"
          >
            {{ generalError }}
          </div>
        </form>
        <div class="mt-6 w-[300px] flex flex-col items-center gap-[10px]">
          <p class="text-center text-sm text-[#797979] mb-4">
            {{ $t("auth.login.oneClickLogin") }}
          </p>
          <TelegramEntry
            :showSlot="true"
            class="!w-[194px] !text-[14px] !justify-start !gap-x-[11px]"
            @click="handleSocialAuth('telegram')"
          />
          <GoogleEntry
            :showSlot="true"
            class="!w-[194px] !text-[14px] !justify-start !gap-x-[11px]"
            @click="handleSocialAuth('google')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useSocialAuthStore } from "@/stores/socialAuthStore";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import ModalHeader from "@/components/ModalDeal/ModalBlocks/ModalHeader.vue";
import GoogleEntry from "./GoogleEntry.vue";
import TelegramEntry from "./TelegramEntry.vue";
import EyeIcon from "@/components/icons/EyeIcon.vue";
import EyeOffIcon from "@/components/icons/EyeOffIcon.vue";

const { t } = useI18n();
const router = useRouter();
const authStore = useAuthStore();
const socialAuthStore = useSocialAuthStore();
const login = ref("");
const password = ref("");
const errors = ref({});
const generalError = ref("");
const showPassword = ref(false);

const validateForm = () => {
  errors.value = {};
  if (!login.value.trim()) {
    errors.value.login = [t("auth.login.errors.loginRequired")];
  }
  if (!password.value) {
    errors.value.password = [t("auth.login.errors.passwordRequired")];
  }
  return Object.keys(errors.value).length === 0;
};

const errorTranslations = {
  "Login is incorrect.": t("auth.login.errors.incorrectLogin"),
  "Password is incorrect.": t("auth.login.errors.incorrectPassword"),
};

const handleSubmit = () => {
  if (!validateForm()) return;

  errors.value = {};
  generalError.value = "";

  return authStore
    .login(login.value, password.value)
    .then((response) => {
      if (response.message === "Verification code sent to your email.") {
        emit("verification-required", {
          email: response.email || login.value,
          password: password.value,
        });
        return;
      }

      if (response.token) {
        emit("login-success", {
          ...response.user,
          token: response.token,
        });
      }
    })
    .catch((error) => {
      console.error("Login error details:", error);
      const errorMessage =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error?.message ||
        t("auth.login.errors.defaultError");

      generalError.value = errorTranslations[errorMessage] || errorMessage;
    });
};

const handleSocialAuth = (provider) => {
  generalError.value = "";
  errors.value = {};

  socialAuthStore
    .onSocialButtonClicked(provider)
    .then((result) => {
      if (result?.token) {
        router.push("/p2p");
      }
    })
    .catch((err) => {
      generalError.value =
        err.message || t("auth.login.errors.socialAuth", { provider });
    });
};

const emit = defineEmits([
  "close",
  "switch-form",
  "login-success",
  "verification-required",
]);

const openResetPassword = () => {
  emit("switch-form", "reset-password");
};

const openRegisterForm = () => {
  emit("switch-form", "register");
};

const close = () => {
  emit("close");
};

defineExpose({ close });
</script>

<style>
.error {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}
</style>
