import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "../stores/authStore";
import { useNavigationStore } from "../stores/marketStore";

let isHandlingAuth = false;

const publicRoutes = [
  "landing",
  "main",
  "main-buy",
  "main-sell",
  "googleCallback",
  "userAgreement",
  "amlKycPolicy",
  "cookies",
  "privacyPolicy",
  "umbrellaGame",
];

const isPublicRoute = (routeName) => {
  return publicRoutes.includes(routeName);
};

const routes = [
  {
    path: "/",
    name: "landing",
    component: () => import("../pages/LandingPage.vue"),
    meta: { requiresAuth: false, isPublic: true },
  },
  {
    path: "/privatesale",
    name: "investor",
    component: () => import("../pages/Investor.vue"),
    meta: { requiresAuth: false, isPublic: true },
  },
  {
    path: "/auth/google/callback",
    name: "googleCallback",
    component: () => import("../components/auth/GoogleAuthCallback.vue"),
    meta: { requiresAuth: false, isPublic: true },
  },
  {
    path: "/p2p",
    name: "main",
    component: () => import("../blocks/MainView.vue"),
    children: [
      {
        path: "buy",
        name: "main-buy",
        component: () => import("../blocks/MainView.vue"),
        meta: { filterMode: "buy", requiresAuth: false, isPublic: true },
      },
      {
        path: "sell",
        name: "main-sell",
        component: () => import("../blocks/MainView.vue"),
        meta: { filterMode: "sell", requiresAuth: false, isPublic: true },
      },
    ],
  },
  {
    path: "/spot",
    name: "spot",
    component: () => import("../blocks/SpotTrading.vue"),
    meta: { requiresAuth: false, isPublic: true },
  },
  {
    path: "/umbrella-game",
    name: "umbrellaGame",
    component: () => import("../blocks/UmbrellaGame.vue"),
    meta: { requiresAuth: false, isPublic: true },
  },
  {
    path: "/create-ad",
    name: "createAd",
    component: () => import("../blocks/CreateAd.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/edit-ad/:id",
    name: "editAd",
    component: () => import("../blocks/CreateAd.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/daily-tasks",
    name: "dailyTasks",
    component: () => import("../blocks/DailyTasks.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/my-ads",
    name: "myAds",
    component: () => import("../blocks/MyAdsComponent.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../blocks/Settings.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/history",
    name: "history",
    component: () => import("../blocks/History.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/order",
    name: "orderFlow",
    component: () => import("../blocks/MainView.vue"),
    props: (route) => ({
      ...route.params,
      offerID: route.query.offerID,
      orderID: route.query.orderID,
    }),
    meta: { requiresAuth: true },
  },
  {
    path: "/transactions",
    component: () => import("../blocks/TransactionsLayout.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        redirect: { name: "TransactionsWallet" },
      },
      {
        path: "wallet",
        name: "TransactionsWallet",
        component: () => import("../blocks/TransactionsWallet.vue"),
      },
      {
        path: "transfer",
        name: "TransactionsTransfer",
        component: () => import("../blocks/TransactionsTransfer.vue"),
      },
      {
        path: "exchange",
        name: "TransactionsExchange",
        component: () => import("../blocks/TransactionsExchange.vue"),
      },
      {
        path: "make",
        name: "TransactionsMake",
        component: () => import("../blocks/TransactionsMake.vue"),
      },
      {
        path: "output",
        name: "TransactionsOutput",
        component: () => import("../blocks/TransactionsOutput.vue"),
      },
    ],
  },
  {
    path: "/profile",
    component: () => import("../blocks/UserProfile.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "userProfile",
        redirect: { name: "userProfilePersonal" },
      },
      {
        path: "personal",
        name: "userProfilePersonal",
        component: () => import("../blocks/UserProfile.vue"),
      },
      {
        path: "p2p",
        name: "userProfileP2P",
        component: () => import("../blocks/UserProfile.vue"),
      },
      {
        path: "security",
        name: "userProfileSecurity",
        component: () => import("../blocks/UserProfile.vue"),
      },
      {
        path: "referral",
        name: "userProfileReferral",
        component: () => import("../blocks/UserProfile.vue"),
      },
      {
        path: "presents",
        name: "userProfilePresents",
        component: () => import("../blocks/UserProfile.vue"),
      },
    ],
  },
  {
    path: "/rules",
    children: [
      {
        path: "user-agreement",
        name: "userAgreement",
        component: () => import("../blocks/UserAgreement.vue"),
        meta: { requiresAuth: false, isPublic: true },
      },
      {
        path: "aml-kyc-policy",
        name: "amlKycPolicy",
        component: () => import("../blocks/AmlKycPolicy.vue"),
        meta: { requiresAuth: false, isPublic: true },
      },
      {
        path: "cookies",
        name: "cookies",
        component: () => import("../blocks/CookiePolicy.vue"),
        meta: { requiresAuth: false, isPublic: true },
      },
      {
        path: "privacy-policy",
        name: "privacyPolicy",
        component: () => import("../blocks/PrivacyPolicy.vue"),
        meta: { requiresAuth: false, isPublic: true },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "catchAll",
    redirect: (to) => {
      if (to.query.code) {
        return {
          name: "googleCallback",
          query: to.query,
        };
      }
      return {
        name: "landing",
      };
    },
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "landing") {
    const authStore = useAuthStore();
    if (authStore.isAuthenticated) {
      next({ name: "main" });
      return;
    }
    next();
    return;
  }

  if (!isHandlingAuth && to.query.code && to.name !== "googleCallback") {
    isHandlingAuth = true;
    next({
      name: "googleCallback",
      query: to.query,
      replace: true,
    });
    return;
  }

  if (isHandlingAuth && to.name !== "googleCallback") {
    next(false);
    return;
  }

  if (isPublicRoute(to.name) || to.path.startsWith("/rules")) {
    next();
    return;
  }

  const navigationStore = useNavigationStore();
  if (to.name) {
    navigationStore.setView(to.name, to.params);
  }

  const authStore = useAuthStore();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !authStore.isAuthenticated) {
    next({
      name: "main",
      query: { form: "login", intendedRoute: to.fullPath },
    });
    return;
  }

  next();
});

export const completeAuthHandling = () => {
  isHandlingAuth = false;
};

export default router;
